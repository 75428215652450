import { useEffect } from "react";
import {
	FilterIcon,
	HahaIcon,
	HeartIcon,
	LikeIcon,
	ManIcon,
	MembersIcon,
	PlusIcon,
	ShareIcon,
	ShareIcon2,
	TradingRoomsIcon1,
	TradingRoomsIcon2,
	TradingRoomsIcon3,
	TradingRoomsIcon4,
	TradingRoomsIcon5,
	TradingRoomsIcon6,
	Womancon,
} from "../../components/TradingIcons"; 

const ReactionHolder = ({ reactions, allReactions }) => {

	function convertReactionID(reaction) {
		const foundReaction = (allReactions ?? []).find((item) => item._id === reaction.interaction);
		if (foundReaction) {
			return foundReaction.mediaURL;
		}
		return '';
	};

	if (reactions) {
		return(
			<div className="reaction-icons" style={{ marginTop: '20px'}}>
				<button className="btn btn--secondary border-0 d-flex align-items-center gap-1 text--text text-14">
					<ShareIcon />
					<span>2 Replies</span>
				</button>
				{ reactions && reactions.map((reaction, index) => {
					return(
						<button key={index} className="btn btn--secondary border-0 d-flex align-items-center gap-1 text--text text-14">
							<img style={{height: '20px'}} src={reaction ? (convertReactionID(reaction)) : ''} alt="" />
							<span>{reaction ? reaction.count : 0}</span>
						</button>
					)
				})}
			</div>
		);
	};

	return null;

    

};

export default ReactionHolder;