/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import uploadImg from "../assets/img/placeholder.png";

const thumbsContainer = {
	display: "flex",
	flexDirection: "row",
	flexWrap: "wrap",
	width: "100%",
	gap: "1rem",
};

const thumbInner = {
	display: "flex",
	justifyContent: "center",
	minWidth: '300px',
	// width: "auto",
	maxWidth: "300px",
	background: "#212128",
	zIndex: "1",
	// overflow: "hidden",
	objectFit: "contain",
	padding: '6px',
	height: 'auto',
	maxHeight: "60px",
	aspectRatio: '40/9',
};

export function ImageUpload({ children, files, setFiles, setBase64Images, selectedImage }) {

	const [_thumbnails, setThumbnails] = React.useState([]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/*": [],
		},
		onDrop: (acceptedFiles) => {
			const singleFile = acceptedFiles[0]
			getBase64(singleFile, (result) => {
				setBase64Images(result)
			})
			setFiles(acceptedFiles.map((file) => Object.assign(file)));
		},
	});

	function setupThumbnails() {
		if (selectedImage) {
			const _thumbs = [selectedImage].map((base64, index) => {
				return (
					<div key={index}>
						<div style={thumbInner}>
							<img
								src={base64}
								// onLoad={() => {
								// 	URL.revokeObjectURL(file.preview);
								// }}
							/>
						</div>
					</div>
				);
			})
			setThumbnails(_thumbs)
		}
	}

	function getBase64(file, cb) {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result)
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	useEffect(() => {
		return () => files?.forEach((file) => URL.revokeObjectURL(file?.preview));
	}, [files]);

	let theImgUrl;
	theImgUrl = selectedImage


	useEffect(() => {
		setupThumbnails()
	},[selectedImage])

	return (
		<div className="image-uploader-1">
			<div {...getRootProps({ className: "dropzone" })}>
				<input multiple={false} {...getInputProps()} />
				{!theImgUrl && <img src={uploadImg} />}
			</div>
			<aside style={thumbsContainer}>{_thumbnails}</aside>
		</div>
	);
}
