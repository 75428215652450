import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioPlayer3 = ({ url }) => {
	return (
		<div className="d-flex align-items-center gap-4">
			<AudioPlayer
				src="/audio.mp3"
				customControlsSection={["MAIN_CONTROLS"]}
			/>
		</div>
	);
};

export default AudioPlayer3;
