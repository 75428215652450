import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import bg from "../../../assets/img/customer-auth-bg.png";
import logo from "../../../assets/img/logo.png";
import { CurrentCompanyConfiguration } from "../../../util/CompanyHelper";
import { useState } from "react";

const CustomerAuthLayout = ({
	children,
	title,
	text,
	subtitle,
	nowrwapper,
	altLoginText,
}) => {

	const [companyConfiguration, setCompanyConfiguration] = useState();

	const fetchCompanyConfiguration = async () => {
		const fetchedCompanyConfig = await CurrentCompanyConfiguration();
		if (fetchedCompanyConfig) {
			setCompanyConfiguration(fetchedCompanyConfig);
		};
	};

	useEffect(() => {
		fetchCompanyConfiguration();
	},[]);

	return (
		<section
			className="customer-auth-section"
			style={{
				background: `url(${bg}) no-repeat center center / cover fixed`,
			}}
		>
			{nowrwapper ? (
				children
			) : (
				<>
					<div className="customer-auth-wrapper">
						<div className="customer-auth-wrapper-inner">
							<div className="pb-sm-5">
								<Link to="#" className="logo mb-4">
									<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logo) : logo} alt="" />
								</Link>
							</div>
							<h2 className="title mb-3 pb-1">
								{title && title}{" "}
								{subtitle && (
									<span className="subtitle">/ {subtitle}</span>
								)}
							</h2>
							<div className="text">{text}</div>
							{children}
						</div>
					</div>
				</>
			)}
		</section>
	);
};

export default CustomerAuthLayout;
