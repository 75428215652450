import React, {useEffect, useState} from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import {
	CommentsIcon,
	DownVote,
	EllipisIcon,
	EmojiIcon,
	EyeSlash,
	GalleryIcon,
	HeartFill,
	PinnedIcon,
	ShareIcon3,
	TopStreakIcon,
	UpVote,
	UserFollowIcon,
	VideoSentIcon,
} from "../../components/Icon";
import Layout from "../../components/Layout";
import NetworkingProvider from "../../providers/NetworkingProvider";
import data from "bootstrap/js/src/dom/data";

const CommunitySinglePage = ({ onTitleChange }) => {

	const params = useParams();

	const [ communityDetails, setCommunityDetails] = useState();

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	async function initializePage() {
		console.log("Initializing CommunitySinglePage:", params.id);
		try {
			const dataResponse = await NetworkingProvider.fetchData(`community/community/${params.id}`, 'GET');
			console.log(dataResponse);
			const data = dataResponse.data;
			if (data) {
				setCommunityDetails(data)
			}
		} catch (e) {
			console.log(e);
		}
	}

	useEffect(() => {
		const newTitle = "Community | Forex Trading";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	useEffect(() => {
		initializePage()
	}, []);

	return (
		<>
			{ communityDetails && (
				<>
					<br/>
					<div className="forex-trading-banner">
						<img src="/community/banner.png" alt=""/>
					</div>
					<img
						src={communityDetails ? (communityDetails.thumbnailPreview
							? communityDetails.thumbnailPreview
							: '/global/placeholderImage.jpg') : '/global/placeholderImage.jpg'}
						alt=""
						className="forex-thumbnail"
					/>
					<div className="d-flex flex-wrap gap-2 align-items-end justify-content-between mt-4 mb-4 pb-2">
						<h5 className="forex-title">{ communityDetails ? communityDetails.title : ''}</h5>
						<div>
							<button
								className="btn btn--secondary btn--danger border-0"
								type="button"
								style={{
									height: "47px",
									width: "144px",
									borderRadius: "10px",
								}}
							>
								Join
							</button>
						</div>
					</div>
					<div className="row discussion-articles-row">
						<div className="col-lg-7 col-xl-8">
							<div className="textarea-wrapper">
								<form onSubmit={handleSubmit}>
								<textarea
									className="form-control"
									placeholder="What’s on your mind..."
								></textarea>
									<hr
										className="mx-3 my-0"
										style={{borderColor: "#3B3B47"}}
									/>
									<div className="buttons-area d-flex  gap-sm-2">
										<button type="button" className="no-gutter px-2">
											<EmojiIcon/>
										</button>
										<button type="button" className="no-gutter px-2">
											<GalleryIcon/>
										</button>
										<button type="button" className="no-gutter px-2">
											<VideoSentIcon/>
										</button>
										<button
											type="submit"
											className="ms-auto cmn-btn text-16"
										>
											Post
										</button>
									</div>
								</form>
							</div>
							<div
								className="mb-20 pt-4 mt-2 d-flex flex-wrap align-items-end justify-content-end gap-1 text-text font-semibold">
								<span className="me-2">Sort By:</span>
								<span>
								<TopStreakIcon color="#88869F" id="1"/>
							</span>
								<select className="no-gutter text-text">
									<option>Trending</option>
									<option>Live</option>
									<option>Recent</option>
								</select>
							</div>
							<div className="mb-30">
								<NormalPost pinned={true}/>
							</div>
							<div className="mb-30">
								<NormalPost pinned={false}/>
							</div>
						</div>
						<div className="col-lg-5 col-xl-4">
							<div className="bg-212128 about-forex">
								<div className="title">
									About <strong>{ communityDetails ? communityDetails.title : ''}</strong>
								</div>
								<div className="txt">
									{ communityDetails ? communityDetails.description : '' }
								</div>
								<div className="count-items">
									<div className="item">
										<div className="count">20k</div>
										<div className="subtext">Members</div>
									</div>
									<div className="item">
										<div className="count">1.5k</div>
										<div className="subtext">Online</div>
									</div>
									<div className="item">
										<div className="count">Top 1%</div>
										<div className="subtext">Site Rank</div>
									</div>
								</div>
								<div
									className="d-flex flex-wrap justify-content-between align-items-center member-rank mb-30">
									<div className="text-white">Top Members</div>
									<div className="text-text">Monthly Rank</div>
								</div>
								<div className="top-streaks-list">
									{[...Array(3)].map((item, index) => (
										<Link
											className="top-streaks-item"
											to="#"
											key={index}
										>
											<h5 className="serial m-0">
												<span className="me-2">{index + 1}.</span>
											</h5>
											<img
												src={"/avatar/1.png"}
												width={33}
												height={33}
												alt=""
											/>
											<div className="top-streaks-item-content">
												<h5 className="name m-0 d-flex flex-wrap gap-2">
													<span className="me-2">Jane Copper</span>
													<span className="font-regular ms-auto">
													<TopStreakIcon/>{" "}
														<span className="ms-1">1,232</span>
												</span>
												</h5>
											</div>
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

const NormalPost = ({pinned}) => {
	return (
		<>
			<div className="post-item pinned-card bg-292933 text-start">
				{pinned && (
					<div className="pinned-post-header">
						<div>
							<span className="text-base">
								<PinnedIcon/>{" "}
							</span>
							Pinned post
						</div>
						<button className="no-gutter text-white">
							Hide <EyeSlash/>
						</button>
					</div>
				)}
				<div className="pinned-card-body bg-212128">
					<div className="d-flex flex-wrap justify-content-between align-items-center mb-3 gap-2 gap-sm-3">
						<Link to="#" className="media __media">
							<img src={avatar} alt=""/>
							<div className="media-body">
								<h6 className="text-20 font-semibold m-0 mb-1">
									Jane Cooper
								</h6>
								<small>@copper</small>
							</div>
						</Link>
						<button type="button" className="cmm-btn load-more">
							Follow <UserFollowIcon/>
						</button>
					</div>
					<p className="normal-text">
						Trade refers to the buying, selling, or exchange of goods,
						services, or commodities between individuals, businesses, or
						countries.
					</p>
					<div className="d-flex flex-wrap gap-2 post-icons">
						<button type="button" className="no-gutter text-12">
							<div className="text-base">
								<HeartFill/>
							</div>
							{" "}
							<span>24k+</span>
						</button>
						<button type="button" className="no-gutter text-12">
							<CommentsIcon/>
						</button>
						<button className="no-gutter">
							<ShareIcon3/>
						</button>
						<button className="no-gutter">|</button>
						<button className="no-gutter upvote">
							<UpVote/>
						</button>
						<button className="no-gutter">
							<DownVote/>
						</button>
						<div className="ms-auto">
							<Dropdown>
								<DropdownToggle className="no-gutter p-1 m-0">
									<EllipisIcon/>
								</DropdownToggle>
								<DropdownMenu>
									<Dropdown.Item className="dropd-link">
										Reply
									</Dropdown.Item>
									<Dropdown.Item className="dropd-link">
										Delete
									</Dropdown.Item>
								</DropdownMenu>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default CommunitySinglePage;
