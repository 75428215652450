import React from "react";
import bg1 from "../../assets/img/session-bg1.png";
import fav from "../../assets/img/trade-belle-fav.png";
import tradesession from "../../assets/img/trade-session.png";
import { MinusIcon, PlusIcon3 } from "../../components/Icon";
import ProfileLayout from "./components/ProfileLayout";

const ProfileBookSession = () => {
	const [quantity, setQuantity] = React.useState(1);

	return (
		<>
			<div
					className="session-live"
					style={{
						background: `url(${bg1}) no-repeat center center / cover`,
					}}
				>
					<div className="position-relative inner-div">
						<span className="badge bg-white">
							<span className="dot"></span> LIVE
						</span>
						<div className="w-240px flex-grow-1">
							<h5 className="title">
								<strong>1:1 sessions</strong> With Jane Cooper
							</h5>
							<p className="m-0">
								are calls with the expert during which they share their
								wisdom with you as you play.
							</p>
						</div>
					</div>
				</div>
				<br />
				<br className="d-none d-md-block" />
				<div className="d-flex align-items-center gap-3">
					<img src={fav} width={45} alt="" />
					<h6 className="m-0 session-title">Trade Belle</h6>
				</div>
				<br />
				<div className="trade-session-wrapper">
					<div className="top">
						<div className="d-flex align-items-center gap-2">
							<img width={68} src={tradesession} alt="" />
							<h6 className="title m-0">Trade sessions 1</h6>
						</div>
						<button
							type="button"
							className="btn btn--secondary border-0 rounded p-10px"
						>
							One-on-one session
						</button>
					</div>
					<div className="middle-info">
						<div className="middle-info-left">
							<h6 className="subtitle">
								Trade SESSION (Tactics, Formations and META mechanics to
								use)
							</h6>
							<p>
								This session includes: - Custom Tactics & Formations - 1
								on 1 Gameplay Analysis - Best Skill Moves to adapt to -
								Easiest way to score goals Able to discuss about
								difficulties you might be experiencing and tackling them
								together
							</p>
						</div>
						<div>
							<div className="price">$23.00/hour</div>
							<div className="cart-quantity ms-auto mt-3">
								<button
									className="btn btn--secondary"
									onClick={() => {
										setQuantity(parseInt(quantity) + 1);
									}}
								>
									<PlusIcon3 />
								</button>
								<input
									type="number"
									className="quantity-input form-control px-0"
									value={quantity}
									onChange={(e) => {
										if (e.target.value > 0) {
											setQuantity(e.target.value);
										} else {
											setQuantity(1);
										}
									}}
									min={1}
								/>
								<button
									className="btn btn--secondary"
									onClick={() => {
										if (quantity > 1) {
											setQuantity(parseInt(quantity) - 1);
										}
									}}
								>
									<MinusIcon />
								</button>
							</div>
						</div>
					</div>
					{/* <div className="middle-bottom-info">
						<div className="txt-top">
							<span>ITEM</span>
							<span>PRICE</span>
						</div>
						<div className="text-end price mt-3">$23.00/hour</div>
						<p>
							Each session is one hour in length. You can book multiple
							sessions by changing the number below.
						</p>
					</div>
					<div className="cart-quantity-wrapper">
						<h6 className="subtitle m-0">
							Number of sessions you want to book
						</h6>
					</div> */}
					<div className="ms-auto" style={{ maxWidth: "374px" }}>
						<div className="grand-total my-4 d-flex flex-wrap justify-content-between align-items-center">
							<span>Grand Total</span>
							<span className="text-white">$23.00 $</span>
						</div>
						<button type="submit" className="cmn-btn w-100 h-52">
							Proceed to buy (1 Sessions)
						</button>
					</div>
				</div>
		</>
	);
};

export default ProfileBookSession;
