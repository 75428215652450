import React from "react";
import { Link } from "react-router-dom";
import fav from "../../assets/img/trade-belle-fav.png";
import bg from "../../assets/img/trade-belle.png";
import { VideoIcon } from "../../components/Icon";
import ProfileLayout from "./components/ProfileLayout";
import RecentTrade from "./components/RecentTrade";

const ProfileSessions = () => {
	return (
		<>
			{/* Trading Videos Banner */}

			<section
					className="banner-section"
					style={{
						background: `url(${bg}) no-repeat center center / cover`,
					}}
				>
					<div className="banner-item" style={{ paddingBlock: "73px" }}>
						<div className="banner-content banner-content-2">
							<img src={fav} className="mb-4" alt="" />
							<h2 className="banner-title">Trade Belle</h2>
							<p
								className="banner-text mb-4 pb-3"
								style={{ maxWidth: "499px", fontSize: "16px" }}
							>
								Trading is one of the most effective and important ways
								of building a powerhouse squad in FIFA, and this
								Deconstructed Show from and Metafy will give you all the
								tips and tricks you need to.
							</p>
							<Link to="book-session" className="cmn-btn btn-black">
								<VideoIcon /> Book A Session
							</Link>
						</div>
					</div>
				</section>
				<br />
				<br />
				<div className="d-flex flex-wrap align-items-center justify-content-between">
					<h6 className="m-0 article-section-title">
						Or Watch Recorded Sessions
					</h6>
					<Link className="view-all" to="#">
						view all
					</Link>
				</div>
				<br className="d-none d-md-block" />
				<br />
				<RecentTrade />
		</>
	);
};

export default ProfileSessions;
