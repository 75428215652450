import React, { useEffect, useState } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import avatar from "../assets/img/avatar.png";
import notify from "../assets/img/icons/notify.png";
import logoimg from "../assets/img/logo.png";
import useScreen from "../hooks/useScreen";
import useScroll from "../hooks/useScroll";
import { BellIcon, SearchIcon, VerticalRowBar } from "./Icon";
import { CurrentCompanyConfiguration, PerformLogout } from "../util/CompanyHelper";

const Header = ({ setSidebarOpen, logo }) => {

	const scroll = useScroll();
	const width = useScreen();
	const [searchFormOpen, setSearchFormOpen] = useState(false);
	const [companyConfiguration, setCompanyConfiguration] = useState();

	const fetchCompanyConfiguration = async () => {
		const fetchedCompanyConfig = await CurrentCompanyConfiguration();
		if (fetchedCompanyConfig) {
			setCompanyConfiguration(fetchedCompanyConfig);
		};
	};

	const handleLogout = () => {
		PerformLogout()
	};

	useEffect(() => {
		fetchCompanyConfiguration()
	},[]);

	return (
		<header className={`header-section ${scroll > 0 ? "active" : ""}`}>
			<div className="container-fluid">
				<div className="header-wrapper">
					<Link to="/" className={`logo ${logo ? "" : "d-lg-none"}`}>
						<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logoimg) : logoimg} alt="logo" />
					</Link>

					{width >= 768 ? (
						<SearchForm />
					) : (
						<button
							className="no-gutter ms-auto"
							type="button"
							onClick={() => setSearchFormOpen(!searchFormOpen)}
						>
							<SearchIcon />
						</button>
					)}
					<div className="right-icons">
						<button
							type="button"
							className="no-gutter"
							onClick={() => setSidebarOpen(true)}
						>
							<VerticalRowBar />
						</button>
						<Dropdown>
							<Dropdown.Toggle className="no-gutter">
								<BellIcon />
							</Dropdown.Toggle>
							<Dropdown.Menu className="profile-notify">
								<div className="d-flex flex-wrap justify-content-between align-items-center mb-2">
									<div>
										You have <strong>1 New Message</strong>
									</div>
									<div>22 feb 2023</div>
								</div>
								<Link className="notify-item" to="#">
									<div className="notify-thumb">
										<img src={notify} alt="notify" />
									</div>
									<div className="notify-content">
										<h6 className="notify-title">Pro Trades</h6>
										<span className="notify-text">
											We just caught MAJOR pips on the latest USDJPY
											Trade!!!
										</span>
									</div>
								</Link>
							</Dropdown.Menu>
						</Dropdown>
						<img onClick={() => handleLogout()} src={avatar} alt="avatar" className="avatar" />
					</div>
					{width >= 768 ? (
						""
					) : (
						<Collapse in={searchFormOpen}>
							<div className="w-100">
								<div className="pt-2 d-flex">
									<SearchForm />
								</div>
							</div>
						</Collapse>
					)}
				</div>
			</div>
		</header>
	);
};

const SearchForm = () => {
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("searching...");
	};
	return (
		<>
			<form onSubmit={handleSubmit} className="search-form">
				<div className="group-input">
					<button type="submit" className="no-gutter search-btn">
						<SearchIcon />
					</button>
					<input
						type="text"
						placeholder="Search here"
						className="form-control"
					/>
				</div>
			</form>
		</>
	);
};

export default Header;
