import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../components/Icon";
import Input from "../../components/Input";
import {toast} from "react-toastify";
import {useCustomerRegistration} from "../../contexts/CustomerRegistrationContext";
import NetworkingProvider from "../../providers/NetworkingProvider";
import {Subdomain} from "../../util/DomainHelper";

const CustomerSignUpStep1 = ({ nextStep, onNameChange, onAcceptedTerms, onEmailChange, onPasswordChange, registrationData }) => {

	const { updateRegistrationDetails, registrationDetails, initialize, reset } = useCustomerRegistration();

	const [step, setStep] = useState(0);
	const [checked, setChecked] = useState(false);
	const [agreedToTerms, setAgreedToTerms] = useState(false);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');

	const handleTerms = (e) => {
		const value = e.target.checked;
		setChecked(value);
		onAcceptedTerms(value, 'terms');
	}

	const handleName = (e) => {
		const value = e.target.value;
		setName(value);
		onNameChange(value, 'name');
	};

	const handleEmail = (e) => {
		const value = e.target.value;
		setEmail(value);
		onEmailChange(value, 'email');
	};

	const handlePassword = (e) => {
		const value = e.target.value;
		setPassword(value);
		onPasswordChange(value, 'password');
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		validateInputs()
		//nextStep();
	};

	async function checkEmailAvailability(email_address) {
		try {
			const subdomain = Subdomain
			const payload = {
				email: email_address
			}
			const dataResponse = await NetworkingProvider.fetchData(`auth/check/email/${subdomain}`, 'POST', payload)
			console.log("dataResponse", dataResponse)
			const responseData = dataResponse.data;
			return responseData;
		} catch (error) {
			console.log(error)
			return false
		}
	}

	async function validateInputs() {
		if (!email || !password || !name) {
			return toast.error('Please fill out all required fields')
		}
		if (checked === false) {
			return toast.warning("You must agree to the terms of service");
		}
		if (!(email ?? '').includes("@") || !(email ?? '').includes(".")) {
			return toast.warning("Please enter a valid email");
		}
		if ((password ?? '').length < 8) {
			return toast.warning("Password must be at least 8 characters");
		}
		if (!(name ?? '').includes(" ")) {
			return toast.warning("First and last name is required");
		}

		try {
			const available = await checkEmailAvailability(email);
			if (available) {
				updateRegistrationDetails({email: email})
				updateRegistrationDetails({password: password})
				updateRegistrationDetails({name: name})
				updateRegistrationDetails({agreedToTerms: checked})

				nextStep();
			} else {
				toast.warning("This email address is already in use.");
			}
		} catch (e) {
			return toast.warning("Please enter a valid email");
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
					<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
						<div className="row g-3">
							<div className="col-6">
								<Input label="Name" onChange={handleName}/>
							</div>
							<div className="col-6">
								<Input label="Email" onChange={handleEmail}/>
							</div>
						</div>
					</div>
					<div className="mb-4 pb-sm-2">
						<Input label="Password" type={"password"} onChange={handlePassword} />
					</div>
					<div className="mb-4 pb-sm-1">
						<label className="form-check form--check">
							<input
								type="checkbox"
								className="form-check-input"
								checked={checked}
								onChange={(e) => handleTerms(e)}
							/>
							<span
								className="form-check-label"
								// style={{ maxWidth: "269px" }}
							>
								I’VE READ AND AGREE WITH{" "}
								<Link className="text-base base-2">
									TERMS OF SERVICE
								</Link>{" "}
								AND OUR{" "}
								<Link className="text-base base-2">PRIVACY POLICY</Link>
							</span>
						</label>
					</div>

					<div className="mb-4">
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
						>
							Sign Up
						</button>
					</div>
				</form>
				<div className="mb-4">
					<div className="or">Or sign up with</div>
				</div>
				<div className="d-flex gap-4 mb-4 pb-sm-3 social-wrapper">
					<button className="no-gutter">
						<FacebookIcon />
					</button>
					<button className="no-gutter">
						<GoogleIcon />
					</button>
					<button className="no-gutter">
						<AppleIcon />
					</button>
				</div>
		</>
	);
};

export default CustomerSignUpStep1;
