import React, {useEffect} from "react";
import {Link} from "react-router-dom";

const CourseCard = ({ course, preview }) => {

    useEffect(() => {
        //
    },[]);

    return (
        <div className={ preview ? '' : "col-sm-6 col-xl-3 col-lg-4"}>
            <Link to={`/courses/${course.course._id ?? ''}`} className="course-card">
                <div className="course-card-img">
                    <img src={course.course.previewURL ? course.course.previewURL : '/global/placeholderImage.jpg'} alt="" />
                    {course.course.price !== 0 && (
                        <span
                            className={`__badge ${
                                course.discount > 50 ? "success" : "warning"
                            }`}
                        >
						{/* {course.discount && <strong>{course.discount}%</strong>} */}
                            {course.course.price && <span>{course.course.price}</span>}
					</span>
                    )}
                    {course.course.recommended === true && (
                        <span
                            className={`__badge ${
                                course.course.price === 0 ? "success" : "warning"
                            }`}
                        >
						<strong>Recommended</strong>
					</span>
                    )}
                </div>
                <div className="course-card-body">
                    <h6>{course.course.title}</h6>
                    <p>{course.user.firstName ?? ''} {course.user.lastName ?? ''}</p>
                </div>
            </Link>
        </div>
    );
};

export default CourseCard;
