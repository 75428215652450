const environment = process.env.REACT_APP_ENVIRONMENT === 'dev' ? 'DEV' : 'PROD';

export const DemoCompanyID = "656c03be658acbf3013d56cb";
export const DemoUserID = "656c0127b25d10265b3fcfe2";

const url = window.location.href;
const prodUrl = url.split('//')[1].split('.')[0];
const devUrl = 'www';
export const Subdomain = prodUrl.includes(':') ? devUrl : prodUrl;

export const APIBaseURL = () => {
    // return process.env.REACT_APP_BASE_API_URL;
    if (environment === 'DEV') {
        return 'http://localhost:8000/api/'
    } else {
        return 'https://tradessly-a8b703aab520.herokuapp.com/api/' //'https://api.tradessly.com/api/'
    }
};

export const WebsocketBaseURL = () => {
    // return process.env.REACT_APP_BASE_API_URL;
    if (environment === 'DEV') {
        return 'http://localhost:8000'
    } else {
        return 'https://tradessly-a8b703aab520.herokuapp.com/api/' //'https://api.tradessly.com'
    }
};

export const CurrentCompany = async (shouldRefetch) => {
    const storedCompanyObject = localStorage.getItem('company');
    const storedConfigurationObject = localStorage.getItem('configuration');
    const storedCompany = JSON.parse(storedCompanyObject);

    if (!storedCompanyObject || !storedConfigurationObject || storedCompany === undefined || shouldRefetch) {
        if (Subdomain.toLowerCase() !== 'www') {
            const url = APIBaseURL() + `company/slug/${Subdomain.toLowerCase()}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'origin': '*',
                        'x-requested-with': '*',
                        'Content-Type': 'application/json'
                    }
                });

                const responseJSON = await response.json();
                const data = responseJSON.data;
                if (data) {
                    if (data.company) {
                        const fetchedCompany = data.company;
                        localStorage.setItem('company', JSON.stringify(fetchedCompany));
                    }
                    if (data.configuration) {
                        const fetchedConfiguration = data.configuration;
                        localStorage.setItem('configuration', JSON.stringify(fetchedConfiguration));
                    }
                }

                return data;
            } catch(error) {
                console.log(error);
                // console.log('Failed to fetch company with an error.');
                return undefined;
            }
        } else {
            return undefined;
        }
    } else {
        return storedCompany;
    };

};
