import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import livestreamImage from "../../assets/img/dummy-livestream-image.png";
import {
	BellIcon2,
	CareIcon4,
	CollapseIcon,
	CoolIcon4,
	EmojiIcon,
	GiftSub,
	HeartIcon,
	SlowDownIcon,
	SubscribeIcon,
	WowIcon4,
} from "../../components/Icon";
import Input from "../../components/Input";
import useScreen from "../../hooks/useScreen";
import { CurrentCompany } from "../../util/CompanyHelper";
// import ZoomComponent from "./components/ZoomComponent";
import NetworkingProvider from "../../providers/NetworkingProvider";
import UserProvider from "../../providers/UserProvider";
// import ZoomComponent from "./components/ZoomComponent";
// import ZoomComponent from "./components/ZoomComponent";


const LivestreamPage = () => {

	const params = useParams();

	const [chatbarClose, setChatbarClose] = React.useState(false);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [signature, setSignature] = useState()
	const [tradesessionURL, setTradesessionURL] = useState()

	const [livestreamData, setLivestreamData] = useState();
	const [sessionTokenData, setSessionTokenData] = useState()

	const screen = useScreen();
	const styles = `.articles {
			padding-bottom: 0 !important
		}`;

	async function generateUserToken() {
		const currentCompany = await CurrentCompany(false);
		if (!currentCompany) {
			return
		}
		try {
			const payload = {
				sessionId: '65d7c7f2be68e242d4cc3603',
				userId: UserProvider.getCurrentUser()._id,
				companyId: currentCompany._id,
			}
			const dataResponse = await NetworkingProvider.fetchData('trading-sessions/session-connect/join', 'POST', payload);
			// console.log(dataResponse);
			const data = dataResponse.data;
			if (data ) {
				// console.log(data)
				const url = process.env.REACT_APP_ENVIRONMENT === 'dev' ? `http://localhost:4200/?token=${data.token}` : `https://tradesslyconnect.com/?token=${data.token}`
				setTradesessionURL(url)
				setSessionTokenData(data)
			} else {
				console.log('Failed to fetch data');
			}
		} catch(error) {
			console.log(error);
		}
	}

	async function fetchLiveStream() {
		const currentCompany = await CurrentCompany(false);
		if (!currentCompany) {
			return
		}
		try {
			const payload = {
				livestreamId: params.id,
			}
			const dataResponse = await NetworkingProvider.fetchData(`trading-sessions/session/details/${currentCompany._id}`, 'POST', payload);
			console.log(dataResponse);
			const data = dataResponse.data;
			if (data ) {
				if ((data.is_live ?? false) === true) {
					await generateUserToken()
				}
				setLivestreamData(data)
			} else {
				console.log('Failed to fetch data');
			}
		} catch(error) {
			console.log(error);
		}
	}

	useEffect(() => {
		console.log(params)
		fetchLiveStream()
		// generateUserToken()
		// fetchLiveStream()
	},[]);

	return (
		<>
			{/* <Layout
				livestream
				chatbarClose={chatbarClose}
				setChatbarClose={setChatbarClose}
			>

			</Layout> */}
			{chatbarClose && <style>{styles}</style>}
				<br />
				<div className="livestream-wrapper">
					<div className="livestream-area">
						<div
							className={`livestream-body ${
								chatbarClose ? "fullscreen" : ""
							}`}
							onDoubleClick={() => setChatbarClose(!chatbarClose)}
						>
							{/*<Conference />*/}
							<div style={{ maxHeight: '60vh', height: '60vh', width: '100%', backgroundColor: 'black'}}>
								{ tradesessionURL && (
									<iframe style={{height: "99%", width: "100%"}} src={tradesessionURL}></iframe>
								)}
							</div>

							{/*<img*/}
							{/*	src={livestreamImage}*/}
							{/*	className="w-100 h-100 object-cover"*/}
							{/*	alt=""*/}
							{/*/>*/}
							{!chatbarClose && (
								<div className="streamer-informations">
									<div className="author">
										{/*<div className="img">*/}
										{/*	<img src={avatar} alt="" />*/}
										{/*	<span className="badge-live">Live</span>*/}
										{/*</div>*/}
										{/*{ livestreamData && (*/}
										{/*	<>*/}
										{/*		{ livestreamData.user && (*/}
										{/*			<div className="cont">*/}
										{/*				<h6 className="name">{ livestreamData.title}</h6>*/}
										{/*				<div>{livestreamData.user.name}</div>*/}
										{/*				<Link to="#" className="text-base">*/}
										{/*					Live*/}
										{/*				</Link>*/}
										{/*			</div>*/}
										{/*		)}*/}
										{/*	</>*/}
										{/*)}*/}
									</div>
									<strong>
										<span className="text-base">90,343</span> Viewers
									</strong>
								</div>
							)}
							{(sidebarOpen || screen < 768) && (
								<button
									className="no-gutter collapse-icon"
									onClick={() => setSidebarOpen(!sidebarOpen)}
								>
									<CollapseIcon />
								</button>
							)}
						</div>
						{!chatbarClose && (
							<div className="px-2 px-xxl-4">
								<div className="streamer-informations">
									<div className="author">
										{ livestreamData && (
											<>
												{ livestreamData.user && (
													<>
														<div className="img">
															<img src={livestreamData.user.photo ? livestreamData.user.photo : '/avatar/blankUser.jpg'} alt=""/>
															<span className="badge-live">Live</span>
														</div>
														<div className="cont">
															<h6 className="name">{livestreamData.title}</h6>
															<div>{ livestreamData.user.name}</div>
															<Link to="#" className="text-base">
																Educator
															</Link>
														</div>
													</>
												)}
											</>
										)}
									</div>
									<div className="text-end ms-auto">
										<div
											className="d-flex flex-wrap column-gap-2 column-gap-xxl-3 row-gap-2 mb-3 align-items-center">
											<Link to="#" className="text-base">
												<BellIcon2/>
											</Link>
											<Link
												to="#"
												className="text-282834 text-hover-base"
											>
												<HeartIcon />
											</Link>
											<Link
												to="#"
												className="text-282834 text-hover-base"
											>
												<GiftSub />
											</Link>
											<Link
												to="#"
												className="text-282834 text-hover-base"
											>
												<SubscribeIcon />
											</Link>
											<strong className="d-xl-none">
												<span className="text-base">90,343</span>{" "}
												Viewers
											</strong>
										</div>
										<strong className="d-xl-block d-none">
											<span className="text-base">90,343</span>{" "}
											Viewers
										</strong>
									</div>
								</div>
								<div className="description">
									<h6 className="subtitle font-semibold">
										Session Description
									</h6>
									<label style={{ marginTop: '15px'}}>
										{livestreamData ? livestreamData.description : ''}
									</label>
								</div>
							</div>
						)}
					</div>
					<div
						className={`livestream-right-sidebar ${
							sidebarOpen ? "active" : ""
						}`}
					>
						<h5 className="live-stream-title">
							<button
								className="no-gutter collapse-icon"
								onClick={() => setSidebarOpen(!sidebarOpen)}
							>
								<CollapseIcon />
							</button>
							Chat
						</h5>
						<div className="live-stream-body">
							<div className="streaming-comments">
								<ul>
									<li>
										<span className="name text-base">
											poppinbuckets:
										</span>{" "}
										<span>
											buys you 150 more spins... there may be gold in
											those spins
										</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#5279FF" }}
										>
											Ferrer21:{" "}
										</span>
										<img src="/emoji/1.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											toketokee:{" "}
										</span>
										<img src="/emoji/2.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											toketokee:{" "}
										</span>
										<img src="/emoji/2.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											toketokee:{" "}
										</span>
										<img src="/emoji/2.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#37FF7B" }}
										>
											filthyjamadian:
										</span>
										<span>Honestly not too bad</span>
									</li>
									<li>
										<span className="name text-base">
											poppinbuckets:
										</span>{" "}
										<span>
											buys you 150 more spins... there may be gold in
											those spins
										</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFC100" }}
										>
											Tacojoe:
										</span>{" "}
										<span>it was 3 "dead" spins</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#37FF7B" }}
										>
											Man2332:
										</span>{" "}
										<span>I wanna thank him for being helpful</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											Erol777:
										</span>
										<img src="/emoji/3.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											Erol777:
										</span>
										<img src="/emoji/3.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											Erol777:
										</span>
										<img src="/emoji/3.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#5279FF" }}
										>
											Ferrer21:{" "}
										</span>
										<img src="/emoji/1.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#37FF7B" }}
										>
											filthyjamadian:
										</span>
										<span>Honestly not too bad</span>
									</li>
									<li>
										<span className="name text-base">
											poppinbuckets:
										</span>{" "}
										<span>
											buys you 150 more spins... there may be gold in
											those spins
										</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFC100" }}
										>
											Tacojoe:
										</span>{" "}
										<span>it was 3 "dead" spins</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#37FF7B" }}
										>
											Man2332:
										</span>{" "}
										<span>I wanna thank him for being helpful</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											Erol777:
										</span>
										<img src="/emoji/3.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFBF9A" }}
										>
											Erol777:
										</span>
										<img src="/emoji/3.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#5279FF" }}
										>
											Ferrer21:{" "}
										</span>
										<img src="/emoji/1.png" alt="" />
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#37FF7B" }}
										>
											filthyjamadian:
										</span>
										<span>Honestly not too bad</span>
									</li>
									<li>
										<span className="name text-base">
											poppinbuckets:
										</span>{" "}
										<span>
											buys you 150 more spins... there may be gold in
											those spins
										</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#FFC100" }}
										>
											Tacojoe:
										</span>{" "}
										<span>it was 3 "dead" spins</span>
									</li>
									<li>
										<span
											className="name"
											style={{ color: "#37FF7B" }}
										>
											Man2332:
										</span>{" "}
										<span>I wanna thank him for being helpful</span>
									</li>
								</ul>
							</div>
						</div>
						<div className="live-stream-footer">
							<div className="d-flex flex-wrap gap-9px pb-1 mb-2">
								<button
									type="button"
									className="btn btn--secondary flex-grow-1"
								>
									<CareIcon4 />
									<span>Love</span>
								</button>
								<button
									type="button"
									className="btn btn--secondary flex-grow-1"
								>
									<CoolIcon4 />
									<span>Like a Boss</span>
								</button>
								<button
									type="button"
									className="btn btn--secondary flex-grow-1"
								>
									<WowIcon4 />
									<span>Shocked</span>
								</button>
							</div>
							<LiveStreamChatForm />
							<div className="mt-3 d-flex justify-content-between align-items-center">
								<button className="no-gutter slow-mode">
									<SlowDownIcon /> Slow mode activated
								</button>
								<button type="button" className="chat-btn">
									Chat
								</button>
							</div>
						</div>
					</div>
				</div>
		</>
	);
};

const LiveStreamChatForm = () => {
	const [open, setOpen] = useState(false);
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<>
			<form onSubmit={handleSubmit} className="comments-form">
				<input
					type="text"
					className="form-control form-control-2 ps-4"
					placeholder="Send message..."
				/>
				<button
					className="emoji-icon no-gutter"
					onFocus={() => setOpen(true)}
					onBlur={() => setOpen(false)}
					type="button"
				>
					<EmojiIcon color="#78768A" />
					<div
						className={`emoji--picker-12 ${
							open ? "emoji-picker--open" : ""
						}`}
						style={{ position: "absolute", right: "0", bottom: "0" }}
					>
						<EmojiPicker />
					</div>
				</button>
			</form>
		</>
	);
};

export default LivestreamPage;
