import React from "react";
import img2 from "../../assets/img/plan/plan-bg-2.png";
import { QuestionMark } from "../../components/Icon";
import NetworkingProvider from "../../providers/NetworkingProvider";
import {CurrentCompany} from "../../util/CompanyHelper";
import {toast} from "react-toastify";

const Stripe = () => {

	async function handleConnectStripe() {
		try {
			const currentCompany = await CurrentCompany(false)
			const payload = {
				company: currentCompany._id,
				url: window.location.href
			}
			const dataResponse = await NetworkingProvider.fetchData('company/stripe/link', 'POST', payload)
			console.log(dataResponse)
			const responseData = dataResponse.data;
			if (responseData) {
				return window.location.href = responseData;
			}
			if (dataResponse.message) {
				return toast.error(dataResponse.message ?? 'An unexpected error has occurred');
			}
		} catch (e) {
			console.log(e);

		}
	}

	return (
		<>
				<div className="d-flex gap-3 align-items-center justify-content-between">
					<h5 className="setting-title m-0">Stripe Merchant Account</h5>
				</div>
				<br />
				<p>
					These are your current subscriptions. They will be billed on the
					same cycle. You can update any subscription at any time.
				</p>
				<br />
				<br />
				{/*<br />*/}
				{/*<p className="pb-2">*/}
				{/*	When you have a gift while you have an active subscription, or*/}
				{/*	accept a gift that differs from your current subscription, it*/}
				{/*	will appear here.*/}
				{/*</p>*/}
				<div className="notify-group">
					<div className="notify-group-inner d-flex justify-content-between gap-3">
						<div className={'d-flex align-items-center gap-3'}>
							<QuestionMark/>
							<div>You have not linked your Stripe account</div>
						</div>
						<div>
							<button
								onClick={() => handleConnectStripe()}
								type="button"
								className="btn btn--secondary rounded h-45 font-bold"
							>
								Connect Stripe
							</button>
						</div>
					</div>
				</div>
		</>
	);
};

export default Stripe;
