import React, {useEffect} from "react";
import { Form, Modal } from "react-bootstrap";
import Input from "./Input";
import SignalCard from "./SignalCard";
import {CurrentCompany} from "../util/CompanyHelper";
import NetworkingProvider from "../providers/NetworkingProvider";
import StandardSignalCard from "./StandardSignalCard";
import StandardSignalCard2 from "./StandardSignalCard2";
import StandardSignalCard3 from "./StandardSignalCard3";
import StandardSignalCard4 from "./StandardSignalCard4";

const TestTradeIdea = {
	trade_idea: {
		symbol: 'USDJPY',
		execution_category: "Sell",
		execution_type: 'Swing',
		timestamp: "15 April 2013 8:32 AM",
		entries: [
			{ value:'1.03943' },
			{ value: '1.04342'}
		],
		values_1: [
			{ value:'1.03943' },
			{ value: '1.04342'}
		],
		values_2: [
			{ value:'1.03943' },
			{ value: '1.04342'}
		],
	}
}

const ConfigureSignal = (props) => {

	const [layoutOptions, setLayoutOptions] = React.useState([]);
	const [selectedSignalConfig, setSelectedSignalConfig] = React.useState(0);

	async function initializePage() {
		const currentCompany = await CurrentCompany(false);
		if (!currentCompany) {
			return
		}
		try {
			const dataResponse = await NetworkingProvider.fetchData('', 'POST');
			console.log(dataResponse);
			const data = dataResponse.data;
			const message = dataResponse.message;
			if (data) {
				return
			}
			if(message) {
				return console.log(message);
			}
		} catch (e) {
			return console.log(e)
		}
	}

	useEffect(() => {
		initializePage()
	},[]);

	function handleSignalStyleSelection(e) {
		setSelectedSignalConfig(parseInt(e))
	}

	return (
		<>
			<Modal
				{...props}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="signalModal"
			>
				<div className="row row-gap-4">
					<div className="col-xl-12">
						<h4 className="h4">Layout Configurator</h4>
					</div>
					<div className="col-lg-6">
						<div className="text-18">Style</div>
						<Input
							onChange={(e) => handleSignalStyleSelection(e)}
							options={[
								{
									name: "Standard",
									value: 0,
								},
								{
									name: "Style 1",
									value: 1,
								},
								{
									name: "Style 2",
									value: 2,
								},
								{
									name: "Style 3",
									value: 3,
								},
								{
									name: "Style 4",
									value: 4,
								},
							]}
							height={"47px"}
						/>
						<br/>
						<div className="text-18 pb-3">Select fields</div>
						<div className="select-fields-modal">
							<Form.Check
								type="checkbox"
								name="alert-fields"
								id="alert"
								label="Alert ID"
							/>
							<Form.Check
								type="checkbox"
								name="alert-fields"
								id="Signal"
								label="Signal ID"
							/>
							<Form.Check
								type="checkbox"
								name="alert-fields"
								id="TP"
								label="TP"
							/>
							<Form.Check
								type="checkbox"
								name="alert-fields"
								id="SL"
								label="SL"
							/>
							<Form.Check
								type="checkbox"
								name="alert-fields"
								id="Profit"
								label="Profit"
							/>
						</div>
					</div>
					<div className="col-lg-6">
						{ selectedSignalConfig === 0 && (
							<SignalCard props={TestTradeIdea}/>
						)}
						{ selectedSignalConfig === 1 && (
							<StandardSignalCard props={TestTradeIdea}/>
						)}
						{ selectedSignalConfig === 2 && (
							<StandardSignalCard2 props={TestTradeIdea}/>
						)}
						{ selectedSignalConfig === 3 && (
							<StandardSignalCard3 props={TestTradeIdea}/>
						)}
						{ selectedSignalConfig === 4 && (
							<StandardSignalCard4 props={TestTradeIdea}/>
						)}
					</div>
					<div className="col-lg-12">
						<hr className="border-footer-modal"/>
						<div className="d-flex align-items-center">
							<button
								onClick={props.onHide}
								type="submit"
								className="ms-auto cmn-btn text-16"
							>
								Save Changes
							</button>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ConfigureSignal;
