import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import CustomerAuthLayout from "./components/CustomerAuthLayout";

import img1 from "../../assets/img/plan/plan-bg-1.png";
import img2 from "../../assets/img/plan/plan-bg-2.png";
import img3 from "../../assets/img/plan/plan-bg-3.png";
import {useCustomerRegistration} from "../../contexts/CustomerRegistrationContext";
import {toast} from "react-toastify";
import NetworkingProvider from "../../providers/NetworkingProvider";
import {Subdomain} from "../../util/DomainHelper";

const CustomerSignUpStep3 = ({ nextStep, onPackageSelected, registrationData }) => {

	const { updateRegistrationDetails, registrationDetails, initialize, reset } = useCustomerRegistration();

	const [selected, setSelected] = useState("")
	const [packages, setPackages] = useState([])

	const handleSubmit = async (e) => {
		e.preventDefault();
		await handlePackagePurchase()
	};

	const handlePackageSelected = (e) => {
		setSelected(e)
		updateRegistrationDetails({package: e})
		updateRegistrationDetails({slug: Subdomain})
		updateRegistrationDetails({url: window.location.href})
		onPackageSelected(e, 'package')
	};

	async function handlePackagePurchase() {
		if (!selected) {
			toast.warning("Please select a package")
		}
		try {
			// console.log(registrationDetails())
			const dataResponse = await NetworkingProvider.fetchData('auth/register/user/store', 'POST', registrationDetails());
			// console.log(dataResponse)
			const data = dataResponse.data;
			if (data) {
				const url = data.url;
				return window.location.href = url
			}
			const message = dataResponse.message
			if (message) {
				return toast.error(message ?? 'Failed to create account. Try again later')
			}
		} catch (e) {
			return console.log(e)
		}
		//nextStep();
	}

	// title="Sign Up"
	// subtitle="Subscription"
	// text="Join an online community of educators and start educating."
	// 60

	async function initializePage() {
		try {
			const subdomain = Subdomain
			const dataResponse = await NetworkingProvider.fetchData(`company/packages/${subdomain}`, 'GET', undefined, undefined)
			const responseData = dataResponse.data;
			if (responseData) {
				setPackages(responseData)
			}
		} catch (e) {
			return console.log(e) //
		}
	}

	useEffect(() => {
		initializePage()
	}, []);

	return (
		<>
			<form onSubmit={handleSubmit}>
					<label className="form-label d-block mb-5 form--label">
						Subscriptions
					</label>
					<div className="row g-4">
						{packages?.map((item, index) => (
							<div className="col-12" key={index}>
								<div
									className={`card card-2 ${
										selected === item._id ? "active" : ""
									}`}
									style={{
										background: `url(${img1}) no-repeat center center / cover`,
									}}
								>
									<div className="card-body">
										<div>
											<div className="card-title">
												{item?.title}{" "}
												<span className="badge">{item?.label}</span>
											</div>
											<div className="card-text">{item?.description}</div>
										</div>
										<div>
											<button
												type="button"
												className={`plan-btn ${
													selected === item._id ? "active" : ""
												}`}
												onClick={() => handlePackageSelected(item._id)}
											>
												{selected === item._id
													? "Selected"
													: "Select"}
											</button>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className="mt-4 mt-sm-5">
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
						>
							Sign Up
						</button>
					</div>
				</form>
		</>
	);
};

const data = [
	{
		type: "Trial",
		subtext: "First Week Free",
		bg: img1,
	},
	{
		type: "PRO",
		subtext: "$99.99 / Year",
		bg: img2,
	},
	{
		type: "Ultimate",
		subtext: "$999.99 One-Time",
		bg: img3,
	},
];

export default CustomerSignUpStep3;
