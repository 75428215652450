/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { AngleDown, CalendarIcon3, InfoIcon } from "../../components/Icon";
import InputSwitch from "../../components/InputSwitch";
import Layout from "../../components/Layout";
import { country } from "../admin-auth/AdminSignUpStep3";
import PayoutLayout from "./components/PayoutLayout";

const Payout = () => {
	const [selected, setSelected] = React.useState(0);
	const [startDate, setStartDate] = React.useState(new Date());
	return (
		<PayoutLayout>
			<h5 className="setting-title mb-20">Auto Payout Transaction</h5>
			<div
				className="row g-4 payout-wrapper"
				style={{ "--body": "#1C1C24" }}
			>
				<div className="col-xl-6 col-lg-12 col-md-6">
					<div className="card card-2 rounded-22 h-100">
						<div className="card-body">
							<div className="d-flex flex-wrap w-100">
								<div className="w-0 flex-grow-1">
									<div className="notify-group">
										<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
											<div>
												<h6 className="subtitle m-0">
													Editor Support Settings
												</h6>
												<div className="mt-3 text-88869F">
													Earning will be released upon your
													request <InfoIcon />
												</div>
											</div>
											<InputSwitch value={true} />
										</div>
										{radiodata?.map((item, index) => (
											<div
												className="payout-trx-radio"
												key={index}
											>
												<label className="form-check form--radio">
													<input
														type="radio"
														className="form-check-input"
														name="voice"
													/>
													<span className="form-check-label">
															{item.label}
														</span>
												</label>
											</div>
										))}
										<div className="text-14 text-88869F mb-3">
											Only when minimum balance is
										</div>
										<div className="country-select">
											<InputGroup>
												{/* Country Select */}
												<Dropdown>
													<Dropdown.Toggle className="no-gutter active-btn">
														<img
															src={country[selected].img}
															alt=""
														/>
														<AngleDown />
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{country?.map((item, index) => (
															<Dropdown.Item
																key={index}
																className="d-flex flex-wrap justify-content-between align-items-center "
																onClick={() =>
																	setSelected(index)
																}
															>
																	<span className="form-check-label">
																		{item.name}
																	</span>
																<img
																	src={item.img}
																	alt=""
																	className="ms-auto"
																/>
															</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
												<input
													type="number"
													className="form-control"
													placeholder="$100"
												/>
											</InputGroup>
										</div>
									</div>
								</div>
								<div className=""></div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-lg-12 col-md-6">
					<div className="card card-2 rounded-22 h-100">
						<div className="card-body">
							<div className="d-flex flex-wrap w-100 mb-auto">
								<div className="w-0 flex-grow-1">
									<div className="notify-group">
										<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
											<div>
												<h6 className="subtitle m-0">
													Payout to
												</h6>
												<div className="mt-3 text-88869F">
													Preferred Payment Method
												</div>
											</div>
										</div>

										<div className="country-select mb-20">
											<InputGroup className="position-relative bg-292933 rounded-10">
												{/*  */}
												<div className="visa-img">
													<img src="/visa.png" alt="" />
												</div>
												<span className="angle-down">
														<AngleDown />
													</span>
												<select
													type="number"
													className="form-control bg-transparent"
													placeholder="$100"
												>
													<option value="">Visa ***8880</option>
													<option value="">Visa ***8880</option>
													<option value="">Visa ***8880</option>
													<option value="">Visa ***8880</option>
												</select>
											</InputGroup>
										</div>
										<div className="mt-3 text-88869F mb-5">
											Withdrawal fee: 1.25 USD per payment
											<InfoIcon />
										</div>

										<div>
											<h6 className="subtitle mb-10px">
												Next payment
											</h6>
											<div className="text-88869F mb-20">
												Based on your schedule
											</div>
										</div>
										<div className="text-white">
											December 25, 2023 for 1.000 USD
										</div>
									</div>
								</div>
								<div className=""></div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="card card-2 rounded-22 h-100">
						<div className="card-body">
							<div className="d-flex flex-wrap gap-3 w-100 justify-content-between align-items-center">
								<div className="notify-group">
									<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
										<div>
											<h6 className="subtitle m-0">
												Auto Payout Transaction History
											</h6>
											<div className="mt-3 text-88869F">
												Total transitions amount :{" "}
												<span className="text-20 font-bold text-white">
														$10.09
													</span>
											</div>
										</div>
									</div>
								</div>
								<div className="d-flex flex-wrap gap-2 gap-sm-3">
									<select className="form-select form-control w-auto bg-transparent border rounded-12 h-48 text-88869F">
										<option>Last 7 days</option>
										<option>Last 7 days</option>
										<option>Last 7 days</option>
										<option>Last 7 days</option>
									</select>
									<div className="date-item">
											<span className="date-icon">
												<CalendarIcon3 />
											</span>
										<ReactDatePicker
											selected={startDate}
											onChange={(date) => setStartDate(date)}
											minDate={new Date()}
										/>
									</div>
									<button
										className="btn btn--secondary h-48 border-0 text-88869F px-4"
										type="button"
									>
										Download
									</button>
								</div>
							</div>

							<div className="table-responsive w-100 mt-5">
								<table className="trx-table table table-striped">
									<thead className="custom-thead">
									<tr>
										<th
											className="px-3"
											style={{ width: "120px" }}
										>
											AMOUNT
										</th>
										<th
											className="text-center"
											style={{ width: "200px" }}
										>
											STATUS
										</th>
										<th
											className="text-center"
											style={{ width: "200px" }}
										>
											PAYOUT TO
										</th>
										<th
											className="px-3"
											style={{ width: "180px" }}
										>
											Date
										</th>
									</tr>
									</thead>
									<tbody>
									{data?.map((item, index) => (
										<tr key={index}>
											<td className="py-2 px-3">
														<span className="text-white text-16">
															1.000 USD
														</span>
											</td>
											<td className="py-2 px-3 text-center">
												<button
													className={`btn text-14 font-regular --badge btn--secondary btn--${
														item?.status === "Canceled"
															? "danger"
															: item?.status === "Success"
																? "success"
																: ""
													}`}
													style={{ minWidth: "100px" }}
												>
													{item?.status}
												</button>
											</td>
											<td className="py-2 px-3 text-center">
												<div className="text-white text-16 mb-1">
													Visa Card
												</div>
												<span className="text-12 text-88869F">
															Visa ***8880
														</span>
											</td>
											<td className="py-2 px-3">
												<div className="text-white text-16 font-body word-nobreak">
													Sep 25, 2023 4:40 PM
												</div>
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
							<div className="d-flex flex-wrap justify-content-between gap-3 w-100 mt-3">
								<div className="d-flex align-items-center gap-2">
									<span className="text-12 text-88869F">Show </span>
									<input
										type="text"
										className="form-control form-control-2 h-28 w-66 border px-0 text-center text-12 bg-transparent rounded"
										placeholder="10"
									/>
								</div>
								<div className="d-flex align-items-center gap-2">
										<span className="text-12 text-88869F">
											Showing 1 to 10 of 100 entries{" "}
										</span>
									<div className="d-flex flex-wrap gap-2">
										<nav aria-label="Page navigation example">
											<ul className="pagination __pagination">
												<li className="page-item">
													<a className="page-link" href="#">
														Previous
													</a>
												</li>
												<li className="page-item">
													<a
														className="page-link active"
														href="#"
													>
														1
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														2
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														3
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														Next
													</a>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PayoutLayout>
	);
};

const radiodata = [
	{
		label: "Quarterly (Mar 29, 2021)",
		value: "quarterly",
	},
	{
		label: "Monthly (last Wednesday of each month)",
		value: "quarterly",
	},
	{
		label: "Twice per month (1st and 3rd Wednesday of each month)",
		value: "quarterly",
	},
	{
		label: "Weekly (every Wednesday)",
		value: "quarterly",
	},
];

const data = [
	{
		name: "Course: Trading like a Pro",
		price: "Price: $50",
		date: "October 14th, 2023",
		status: "Success",
	},
	{
		name: "Mentorship: Jack Mack",
		price: "Price: $50 recurring monthly",
		date: "October 14th, 2023",
		status: "Canceled",
	},
	{
		name: "Mentorship: Mack Jack",
		price: "Price: $50 recurring monthly",
		date: "October 14th, 2023",
		status: "incomplete",
	},
	{
		name: "Course: Trading like a Pro",
		price: "Price: $50",
		date: "October 14th, 2023",
		status: "Success",
	},
	{
		name: "Mentorship: Jack Mack",
		price: "Price: $50 recurring monthly",
		date: "October 14th, 2023",
		status: "Success",
	},
	{
		name: "Mentorship: Mack Jack",
		price: "Price: $50 recurring monthly",
		date: "October 14th, 2023",
		status: "Success",
	},
	{
		name: "Mentorship: Mack Jack",
		price: "Price: $50 recurring monthly",
		date: "October 14th, 2023",
		status: "Success",
	},
];

export default Payout;
