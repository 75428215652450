import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import {
	ConfuseIcon,
	HahaIcon,
	HeartFill,
	LoveIcon,
	WowDollarIcon,
	WowIcon,
} from "./Icon";

const ReactionIcon = ({ reactions }) => {
	const [reaction, setReaction] = React.useState(null);

	const [active, setActive] = React.useState(false);

	useEffect(() => {
		if (reaction) {
			setActive(data?.find((item) => item?.id === reaction));
		}
	}, [reaction]);

	return (
		<>
			<Dropdown className="reaction">
				<DropdownToggle
					type="button"
					className="no-gutter no--gutter text-16"
				>
					<span className={reaction ? "text-base" : ""}>
						{active ? active.icon : <HeartFill />}
					</span>{" "}
					<span>{reactions ? reactions : '0'} Likes</span>
				</DropdownToggle>
				<DropdownMenu>
					<div className="reaction-popup">
						{data?.map(({ icon, id }) => {
							return (
								<button
									type="button"
									className="no-gutter text-16"
									key={id}
									onClick={() => {
										if (reaction === id) {
											setReaction(null);
										} else {
											setReaction(id);
										}
									}}
								>
									<span className="text-base">{icon}</span>
								</button>
							);
						})}
					</div>
				</DropdownMenu>
			</Dropdown>
		</>
	);
};

const data = [
	{
		icon: <LoveIcon />,
		id: "1",
	},
	{
		icon: <WowDollarIcon />,
		id: "2",
	},
	{
		icon: <ConfuseIcon />,
		id: "3",
	},
	{
		icon: <WowIcon />,
		id: "4",
	},
	{
		icon: <HahaIcon />,
		id: "5",
	},
];

export default ReactionIcon;
