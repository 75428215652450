import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Modal, ModalBody } from "react-bootstrap";
import ReactPlayer from "react-player/lazy";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
	ArrowLeft2,
	ArrowRight2,
	Ellipsis,
	Heart,
	PauseIcon,
	PlayIcon,
	ShareIcon2,
} from "../../../components/Icon";

const ReelsModal = ({ open, setOpen }) => {
	const ref = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const prevSlide = () => {
		setActiveIndex(ref.current.swiper.activeIndex);
		ref.current.swiper.slidePrev();
	};
	const nextSlide = () => {
		setActiveIndex(ref.current.swiper.activeIndex);
		ref.current.swiper.slideNext();
	};
	return (
		<>
			<Modal show={open} onHide={setOpen} centered className="reels-modal">
				<ModalBody>
					<div className="overflow-hidden">
						<div className="reels-slider-wrapper">
							{activeIndex !== 0 ? (
								<button
									type="button"
									className="prev-btn no-gutter"
									onClick={prevSlide}
								>
									<ArrowLeft2 />
								</button>
							) : (
								""
							)}
							<Swiper
								slidesPerView={1}
								ref={ref}
								onSwiper={(swiper) =>
									setActiveIndex(swiper.activeIndex)
								}
								onSlideChange={(swiper) =>
									setActiveIndex(swiper.activeIndex)
								}
							>
								{data?.map((item, index) => (
									<SwiperSlide key={index}>
										<SlideItem
											playing={index == activeIndex}
											index={index}
											activeIndex={activeIndex}
											nextSlide={nextSlide}
											prevSlide={prevSlide}
										/>
									</SwiperSlide>
								))}
							</Swiper>
							{activeIndex !== data.length - 1 ? (
								<button
									type="button"
									className="next-btn no-gutter"
									onClick={nextSlide}
								>
									<ArrowRight2 />
								</button>
							) : (
								""
							)}
						</div>
						<div className="d-none d-xxl-flex justify-content-center mt-4">
							<button
								className="no-gutter"
								type="button"
								onClick={() => setOpen(false)}
							>
								<img src="/tap-back.svg" alt="" />
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

const SlideItem = ({ playing, index, activeIndex, prevSlide, nextSlide }) => {
	const [play, setPlay] = React.useState(playing);

	useEffect(() => {
		if (playing) {
			setPlay(true);
		} else {
			setPlay(false);
		}
	}, [playing]);
	if (playing)
		return (
			<>
				<div className="reels-video-item">
					<div className="video">
						<div className="reel-customer-info">
							<Link to="#" className="customer">
								<img src="/customer.png" alt="" />
								<div>
									<h6 className="c-name">Jane Cooper</h6>
									<div className="c-info">42 m ago</div>
								</div>
							</Link>
							<Dropdown>
								<Dropdown.Toggle className="no-gutter">
									<Ellipsis />
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item>View</Dropdown.Item>
									<Dropdown.Item>Block</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<ReactPlayer
							url="/reel.mp4"
							playing={play}
							onReady={() => setPlay(true)}
						/>
						<button
							className="no-gutter pos-center bg-transparent"
							type="button"
							onClick={() => setPlay(!play)}
						>
							{play ? <PauseIcon /> : <PlayIcon />}
						</button>
					</div>
					<div className="video-bottom-content">
						<div className="name">
							Thousands in line to get brain chip implant by Elon Musk's
						</div>
						<form>
							<div className="position-relative reply-form">
								<input
									type="text"
									className="form-control form-control-2"
								/>
								<div className="btn-group">
									<button type="button" className="no-gutter">
										<ShareIcon2 />
									</button>
									<button type="button" className="no-gutter">
										<Heart />
									</button>
								</div>
							</div>
						</form>
						<span>Replay to jane coper</span>
					</div>
				</div>
			</>
		);
	return (
		<>
			<div className="reels-video-item cursor-pointer">
				<div
					className="video"
					style={{
						transform: `${
							activeIndex > index
								? "translateX(15%)"
								: "translateX(-15%)"
						} scale(0.4)`,
					}}
					onClick={activeIndex < index ? nextSlide : prevSlide}
				>
					<ReactPlayer url="/reel.mp4" playing={false} />
					<div className="customer-center-content d-flex align-items-center justify-content-center text-center">
						<img src="/customer.png" alt="" />
						<h6 className="customer-name">Jane Cooper</h6>
					</div>
				</div>
			</div>
		</>
	);
};

const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

export default ReelsModal;
