import React from "react";

const InputColor = ({ label, value, ...rest }) => {
	return (
		<div>
			<label className="form-label form--label">{label}</label>
			<label className="form-control form-control-2 d-flex align-items-center gap-3 justify-content-between cursor-pointer">
				<span>{value}</span> <input value={value} type="color" {...rest} />
			</label>
		</div>
	);
};

export default InputColor;
