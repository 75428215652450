import React from "react";
import { Link } from "react-router-dom";
import Input from "../../components/Input";
import InputCheckbox from "../../components/InputCheckbox";
import InputSwitch from "../../components/InputSwitch";
import SettingsLayout from "./components/SettingsLayout";

const LessonsSettings = () => {
	return (
		<>
				<div className="d-flex gap-3 align-items-center justify-content-between">
					<h5 className="setting-title m-0">Lessons Settings</h5>
					<Link to="#" className="btn cmn-btn btn-outline border">
						New Lessons
					</Link>
				</div>
				<br />
				<p style={{ maxWidth: "591px" }}>
					Find Lession stock images in HD and millions of other
					royalty-free stock photos, 3D objects, illustrations and vectors
					in the Shutterstock collection
				</p>
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Lesions Display setings</h6>
					<div className="notify-group-inner">
						<div className="mb-3 pb-1">
							<Input
								label="Sort by"
								options={[
									{ value: "0", name: "Date (default)" },
									{ value: "1", name: "Newest" },
									{ value: "2", name: "Oldest" },
								]}
							/>
						</div>
						<div>
							<Input
								label="Sort Directions"
								options={[
									{ value: "0", name: "Descending (default)" },
									{ value: "1", name: "Ascending" },
								]}
							/>
						</div>
					</div>
				</div>
				<br />
				<div className="notify-group">
					<div className="mb-3 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Lessons search</h6>
						<InputSwitch value={false} />
					</div>
					<div className="notify-group-inner">
						<div className="d-flex align-items-center justify-content-between mb-4">
							<strong>Logged -in-user only</strong>
							<InputSwitch value={false} />
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<strong>Enroll only</strong>
							<InputSwitch value={true} />
						</div>
					</div>
				</div>
				<br />
				<div className="notify-group">
					<div className="mb-3 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Editor Support Settings</h6>
						<InputSwitch value={true} />
					</div>
					<div className="notify-group-inner">
						<InputCheckbox
							value={true}
							title={"Make this a free preview lesson"}
						/>
						<InputCheckbox
							value={false}
							title={"Make this a prerequisite"}
						/>
						<InputCheckbox
							value={false}
							title={
								"Enable discussions for this lesson Apply to all lessons in this course"
							}
						/>
						<InputCheckbox
							value={false}
							title={
								"Enable discussions for this lesson Apply to all lessons in this course"
							}
						/>
					</div>
				</div>
		</>
	);
};

export default LessonsSettings;
