import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "../../../assets/img/avatar.png";
import { PlusIcon2, SliderArrowRight } from "../../../components/Icon";
import { APIBaseURL, CurrentCompany } from "../../../util/DomainHelper";
import UserProvider from "../../../providers/UserProvider";

const StorySection = ({ handleReelSelected }) => {

	const navigate = useNavigate();

	const ref = useRef();
	const prev = useRef();
	const next = useRef();

	const [userStories, setUserStories] = useState([]);

	const handleNextSlide = () => {
		ref.current.swiper.slideNext();
	};

	const handlePrevSlide = () => {
		ref.current.swiper.slidePrev();
	};

	const handleCheckSlide = () => {
		if (ref.current.swiper.activeIndex === 0) {
			// disabled prev button
			prev.current.classList.add("disabled");
		}
		if (ref.current.swiper.activeIndex > 0) {
			// enabled prev button
			prev.current.classList.remove("disabled");
		}
		if (ref.current.swiper.activeIndex === data.length - 1) {
			// disabled next button
			next.current.classList.add("disabled");
		}
		if (ref.current.swiper.activeIndex < data.length - 1) {
			// enabled next button
			next.current.classList.remove("disabled");
		}
	};

	const userStorySelected = (e, userStory) => {
		e.preventDefault()
		console.log(userStory.user);
		handleReelSelected(userStory);
	};

	const fetchStories = async () => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `dashboard/stories/${company._id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					if (data) {
						setUserStories(data);
					} else {
						console.log('Failed to fetch data');
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchStories();
	},[]);

	return (
		<>
			<div className="px-2 px-xxl-5 pb-3 pt-4">
				<div className="d-flex gap-3 gap-xl-4 border-bottom-2 pb-4 mb-2">
					<button type="button" className="add-story">
						<div className="position-relative">
							<img src={ UserProvider.getCurrentUser() ? (UserProvider.getCurrentUser().photo ? UserProvider.getCurrentUser().photo : '/avatar/blankUser.jpg' ) : '/avatar/blankUser.jpg'} alt="" />
							<span className="plus-icon">
								<PlusIcon2 />
							</span>
						</div>
						<div className="name">{ UserProvider.getCurrentUser() ? (`${UserProvider.getCurrentUser().firstName} ${UserProvider.getCurrentUser().lastName}`) : ''}</div>
					</button>
					<div className="story-divider"></div>
					<div className="w-0 flex-grow-1 story-slider">
						<button
							className="no-gutter prev-icon disabled"
							type="button"
							onClick={() => handlePrevSlide()}
							style={{ transform: "rotate(180deg)" }}
							ref={prev}
						>
							<SliderArrowRight />
						</button>
						<Swiper
							ref={ref}
							slidesPerView={"auto"}
							spaceBetween={20}
							modules={[Navigation]}
							onSlideChange={() => handleCheckSlide()}
						>
							{userStories && userStories?.map((item, index) => (
								<SwiperSlide key={index}>
									<Link to={"/"}
										  onClick={(e) => userStorySelected(e, item)}
										className="add-story"
									>
										<div className="position-relative">
											<img src={item.user.photo ? item.user.photo : '/avatar/blankUser.jpg'} alt="" />
										</div>
										<div className="name">{item.user.firstName} {item.user.lastName}</div>
									</Link>
								</SwiperSlide>
							))}
						</Swiper>
						<button
							className="no-gutter next-icon"
							type="button"
							onClick={() => handleNextSlide()}
							ref={next}
						>
							<SliderArrowRight />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};


const data = [
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
	{
		id: "1",
	},
];

export default StorySection;
