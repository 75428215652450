import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

import logoimg from "../../assets/img/logo.png";
import Input from "../../components/Input";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import {CurrentCompanyConfiguration} from "../../util/CompanyHelper";
const CreatePlan = () => {

	const [title, setTitle] = useState("");

	const { updateCourseDetails,
		curriculums,
		insertCurriculum,
		removeCurriculum,
		fetchCourse } = useCreateCourse();


	useEffect(() => {
		const fetchedCurriculums = curriculums()
		console.log('fetchedCurriculums', fetchedCurriculums)
	},[])

	return (
		<section className="setting-notification-main">
			<NotificationHeader />
			<div className="container">
				<div className="setting-notifications-content text-center">
					<h3 className="title">What's the name of your new course?</h3>
					<p className="text">
						Don't worry, It's ok if you can't think of a good title now. You can change
						it later.
					</p>
					<div style={{ maxWidth: "647px" }} className="mx-auto">
						<Input
							onChange={(e) => updateCourseDetails({ course_title: e.target.value })}
							placeholder="e.g. Learn Trading from Scratch"
							endText={60}
						/>
					</div>
				</div>
			</div>
			<NofificationBottombar showPrevious={false} />
		</section>
	);
};

export const NotificationHeader = () => {

	const [companyConfiguration, setCompanyConfiguration] = useState();

	const fetchCompanyConfiguration = async () => {
		const fetchedCompanyConfig = await CurrentCompanyConfiguration();
		if (fetchedCompanyConfig) {
			setCompanyConfiguration(fetchedCompanyConfig);
		}
	};

	useEffect(() => {
		fetchCompanyConfiguration()
	},[]);

	return (
		<header className="setting-notification-header">
			<Link to="/" className={`d-inline-block`}>
				<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logoimg) : logoimg} style={{ width: "140px" }} alt="logo" />
			</Link>
			<button
				type="button"
				className="text-white text-24 no-gutter font-semibold"
			>
				Exit
			</button>
		</header>
	);
};
const NofificationBottombar = ({ showPrevious }) => {

	const navigate = useNavigate();

	return (
		<footer className="setting-notification-footer mt-auto">
			<button
				style={{ opacity: showPrevious ? "1" : 0 }}
				className="btn --badge btn--secondary btn-- text-20 font-semibold bg-transparent"
				type="button"
			>
				Previous
			</button>
			<button
				className="btn --badge btn--secondary btn-- text-20 font-semibold"
				type="button"
				onClick={() => navigate("/course-plan/settings")}
			>
				Continue
			</button>
		</footer>
	);
};

export default CreatePlan;
