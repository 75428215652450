import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import InputColor from "../../components/InputColor";
import { ImageUpload } from "../../components/imageUpload";
import AdminAuthLayout from "./components/AdminAuthLayout";
import { APIBaseURL } from "../../util/DomainHelper";
import {useAdminRegistration} from "../../contexts/AdminRegistrationContext";
import {toast} from "react-toastify";

const AdminSignUpStep2 = () => {

	const { updateRegistrationDetails, registrationDetails, initialize } = useAdminRegistration()

	const [companyName, setCompanyName] = useState();

	const [companyWebsite, setCompanyWebsite] = useState();

	const [companySizeOptions, setCompanySizeOptions] = useState([]);
	const [companySize, setCompanySize] = useState();

	const [communicationPlatformOptions, setCommunicationPlatformOptions] = useState([]);
	const [communicationPlatform, setCommunicationPlatform] = useState();

	const [numberOfPackagesOptions, setNumberOfPackagesOptions] = useState([]);
	const [numberOfPackages, setNumberOfPackages] = useState();

	const [color, setColor] = useState("#ff0000");
	const [color2, setColor2] = useState("#ffdf00");
	const [color3, setColor3] = useState("#ffffff");
	const [files, setFiles] = useState([]);
	const [companyImage, setCompanyImage] = useState();

	const [initialized, setInitialized] = useState(false);

	const navigate = useNavigate();

	const configurePageDefaults = () => {
		startLoading();
		fetchCompanyPageConfiguration();
	};

	// Starts the loading indicator
	const startLoading = () => {
		// console.log('Loading...');
	};

	const configurePreselectedValues = () => {
		const currentRegistrationDetails = registrationDetails()
		if (currentRegistrationDetails) {
			setCompanyName(currentRegistrationDetails.companyName)
			setCommunicationPlatform(currentRegistrationDetails.communicationPlatform)
			setNumberOfPackages(currentRegistrationDetails.numberOfPackages)
			setCompanySize(currentRegistrationDetails.companySize)
			setColor(currentRegistrationDetails.mainColor)
			setColor2(currentRegistrationDetails.secondaryColor)
			setColor3(currentRegistrationDetails.backgroundColor)
			setCompanyWebsite(currentRegistrationDetails.companyWebsite)
			setCompanyImage(currentRegistrationDetails.companyLogoFile)
		}

		setInitialized(true)
	};

	const fetchCompanyPageConfiguration = () => {

		const url = APIBaseURL() + `auth-configuration/company`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					if (data) {
						const values1 = data.numberOfPackages;
						const values2 = data.communicationPlatform;
						const values3 = data.companySize;

						setNumberOfPackagesOptions([{name: '1 Package', value: 1}, {name: '2 Packages', value: 2}, {name: '3 Packages', value: 3}, {name: '4 Packages', value: 4}]);
						setCommunicationPlatformOptions(values2);
						setCompanySizeOptions(values3);

						configurePreselectedValues()
					} else {
						console.log('Failed to fetch data');
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	function handleCompanyLogoSelection(e) {
		setCompanyImage(e)
	}

	const proceedUserSignUp = (e) => {
		e.preventDefault();

		if (!companyName || !companyWebsite || !companySize || !numberOfPackages) {
			return toast.warning('Please fill out all required fields')
		}

		updateRegistrationDetails({companyName: companyName ?? ''})
		updateRegistrationDetails({companySize: companySize ?? ''})
		updateRegistrationDetails({companyWebsite: companyWebsite ?? ''})
		updateRegistrationDetails({communicationPlatform: communicationPlatform ?? ''})
		updateRegistrationDetails({numberOfPackages: numberOfPackages ?? '',})
		updateRegistrationDetails({mainColor: color})
		updateRegistrationDetails({secondaryColor: color2})
		updateRegistrationDetails({backgroundColor: color3})
		updateRegistrationDetails({companyLogoFile: companyImage})

		navigate("/auth/register/trading");
	};

	useEffect(() => {
		initialize()
		configurePageDefaults();
	},[]);

	return (
		<>
			<AdminAuthLayout
				title="Sign Up"
				subtitle="Company Info"
				text="Tell us a little about your company."
				fullwidth
				altLoginText
			>
				{ initialized && (
					<>
						<div className="mb-4">
							<div className="progress __progress base mt-4">
								<div
									className="progress-bar"
									role="progressbar"
									style={{width: "25%"}}
								></div>
							</div>
						</div>
						<form onSubmit={proceedUserSignUp}>
							<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
								<div className="row g-4">
									<div className="col-md-6 pe-xl-3">
										<div className="row g-4">
											<div className="col-12">
												<Input
													defaultValue={companyName}
													required={true}
													onChange={(e) => setCompanyName(e.target.value)}
													label="Company Name"/>
											</div>
											<div className="col-6">
												<Input
													defaultValue={companySize}
													onChange={(e) => setCompanySize(e)}
													label="Company Size"
													required={true}
													options={companySizeOptions}
												/>
											</div>
											<div className="col-6">
												<Input
													defaultValue={companyWebsite}
													endText={'.trdsly.com'}
													required={true}
													onChange={(e) => setCompanyWebsite(e.target.value)}
													label="Choose Domain"/>
											</div>
											<div className="col-6">
												<Input
													defaultValue={communicationPlatform}
													onChange={(e) => setCommunicationPlatform(e)}
													label="Communication Platform"
													options={[...[{
														name: 'Select One',
														value: ''
													}], ...communicationPlatformOptions]}
												/>
											</div>
											<div className="col-6">
												<Input
													defaultValue={numberOfPackages}
													onChange={(e) => setNumberOfPackages(e)}
													label="Number of Packages"
													options={[...[{
														name: 'Select One',
														value: ''
													}], ...(numberOfPackagesOptions ?? [])]}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-6 ps-xl-5">
										<div className="row g-4">
											<div className="col-12 mb-2">
												<ImageUpload files={files} setFiles={setFiles} setBase64Images={handleCompanyLogoSelection} selectedImage={companyImage}/>
											</div>
											<div className="col-12">
												<div className="row g-4">
													<div className="col-xl-4 col-sm-6">
														<InputColor
															label="Main Color"
															value={color}
															onChange={(e) =>
																setColor(e.target.value)
															}
														/>
													</div>
													<div className="col-xl-4 col-sm-6">
														<InputColor
															label="Secondary Color"
															value={color2}
															onChange={(e) =>
																setColor2(e.target.value)
															}
														/>
													</div>
													<div className="col-xl-4 col-sm-6">
														<InputColor
															label="Background Color"
															value={color3}
															onChange={(e) =>
																setColor3(e.target.value)
															}
														/>
													</div>
													<div className="col-md-12 mt-5">
														<br className="d-none d-md-block"/>
														<br className="d-none d-md-block"/>
														<br className="d-none d-md-block"/>
														<div className="pt-sm-5">
															<button
																type="submit"
																className="btn w-100 cmn-btn h-69"
															>
																Proceed
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</>
				)}
			</AdminAuthLayout>
		</>
	);
};

const companySize_ = [
	{
		name: "1-10",
		value: "1-10",
	},
	{
		name: "11-50",
		value: "11-50",
	},
	{
		name: "51-200",
		value: "51-200",
	},
	{
		name: "201-500",
		value: "201-500",
	},
	{
		name: "501-1000",
		value: "501-1000",
	},
	{
		name: "1001-5000",
		value: "1001-5000",
	},
	{
		name: "5001-10,000",
		value: "5001-10,000",
	},
	{
		name: "10,001+",
		value: "10,001+",
	},
];

const communicationPlatform_ = [
	{
		name: "Slack",
		value: "Slack",
	},
	{
		name: "Microsoft Teams",
		value: "Microsoft Teams",
	},
	{
		name: "Zoom",
		value: "Zoom",
	},
	{
		name: "Google Meet",
		value: "Google Meet",
	},
	{
		name: "Skype",
		value: "Skype",
	},
	{
		name: "Other",
		value: "Other",
	},
];

const numberOfPackages_ = [
	{
		name: "1-10",
		value: "1-10",
	},
	{
		name: "11-50",
		value: "11-50",
	},
	{
		name: "51-200",
		value: "51-200",
	},
	{
		name: "201-500",
		value: "201-500",
	},
	{
		name: "501-1000",
		value: "501-1000",
	},
	{
		name: "1001-5000",
		value: "1001-5000",
	},
	{
		name: "5001-10,000",
		value: "5001-10,000",
	},
	{
		name: "10,001+",
		value: "10,001+",
	},
];

export default AdminSignUpStep2;
