import React, { useEffect, useRef, useState } from "react";
import {Link, useLocation, useNavigate, useOutletContext, useParams, useSearchParams} from "react-router-dom";
import Header from "../../components/Header";
import {EllipisIcon, PinnedIcon, SearchIcon} from "../../components/Icon";
import {
	FilterIcon,
	HahaIcon,
	HeartIcon,
	LikeIcon,
	HahaIcon3,
	MembersIcon,
	PlusIcon,
	ShareIcon,
	ShareIcon2,
	TradingRoomsIcon1,
	TradingRoomsIcon2,
	TradingRoomsIcon3,
	TradingRoomsIcon4,
	TradingRoomsIcon5,
	TradingRoomsIcon6,
	Womancon,
} from "./components/TradingIcons";
import team from "./components/img/team.png";
import tradeIdeaImg from "./components/img/trade-idea-author2.png";
import { Dropdown, DropdownMenu, DropdownToggle } from "react-bootstrap";
import AudioPlayer3 from "../../components/AudioPlayer3";
import SignalCard from "../../components/SignalCard";
import useScreen from "../../hooks/useScreen";
import CommentForm from "./components/CommentForm";
import TradeRoomsRightSidebar from "./components/TradeRoomsRightSidebar";
import TradeRoomsSidebar from "./components/TradeRoomsSidebar";
import chartExper from "./components/img/chartExper.png";
import stream from "./components/img/stream.png";
import author1 from "./components/img/traders/1.png";
import author2 from "./components/img/traders/2.png";
import author3 from "./components/img/traders/3.png";
import author4 from "./components/img/traders/4.png";
import author5 from "./components/img/traders/5.png";
import author6 from "./components/img/traders/6.png";
import ReactionHolder from "./components/messages/ReactionHolder";
import { socket } from "../../util/WebsocketHelper";
import { CurrentCompany } from "../../util/CompanyHelper";
import { APIBaseURL } from "../../util/DomainHelper";
import ReactionIcon from "../../components/ReactionIcon";
import CreateCommunityModal from "../community/components/CreateCommunityModal";
import CreateTradingRoomModal from "./components/CreateTradingRoomModal";

const TradingRooms = ({ onTitleChange }) => {

	const setSidebarOpen = useOutletContext()

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	const navigate = useNavigate();
	const params = useParams()
	const location = useLocation();
	const newSearchParams = useSearchParams();

	// CHAT
	const [message, setMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [typing, setTyping] = useState([]);
    const [seenMessages, setSeenMessages] = useState([]);
    const [reactions, setReactions] = useState([]);
    const scrollRef = useRef(null);
    const [activeUsers, setActiveUsers] = useState([]);
    const [inviteMembersModal, setInviteMembersModal] = useState(false);
    const [inviteMembers, setInviteMembers] = useState(null);

	const [tradeRooms, setTradeRooms] = useState([]);
	const [interactions, setInteractions] = useState();

	const containerRef = useRef(null);

	const [hoverIndex, setHoverIndex] = useState([]);
	const [count, setCount] = useState(0);
	const [selectedReplyMessage, setSelectedReplyMessage] = useState();

	const [rightSidebarOpen, setRightSidebarOpen] = React.useState(false);
	const [leftSidebarOpen, setLeftSidebarOpen] = React.useState(false);
	const [currentTradingRoom, setCurrentTradingRoom] = useState();
	const [preselectedTradingRoom, setPreselectedTradingRoom] = useState();
	const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

	const [showCreateTradingRoomModal, setShowCreateTradingRoomModal] = React.useState(false);

	const selectedRoom = (tradingRoom) => {
		// console.log(tradingRoom);
		setPreselectedTradingRoom();
		setCurrentTradingRoom(tradingRoom);
		initializeWebsocketForRoom();
		// onTitleChange(`Trading Rooms | ${tradingRoom.title}`);
	};

	const fetchTradeRooms = async () => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `trading-rooms/rooms/${company._id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.trading_rooms;
					if (data) {
						fetchAllInteractions(data)
					} else {
						console.log('Failed to fetch data');
					};
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	const fetchAllInteractions = async (fetchedTradeRooms) => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `trading-rooms/interactions/${company._id}`;
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			});

			if (response.ok) {
				const responseJSON = await response.json();
				const data = responseJSON.data;
				if (data) {
					await initializeWebsocketForRoom(data, fetchedTradeRooms);
				} else {
					console.log('Failed to fetch data');
				}
			}
		} catch(error) {
			console.log(error);
		}
	};

	const initializeWebsocketForRoom = async (fetchedInteractions, fetchedTradeRooms) => {

		// axios.post(`/notifications/room/${currentTradingRoom._id}`, {}, {withCredentials: true});
		const queryParam = getQueryParam('room');

		let parsedTradeRoom;

		if (((fetchedTradeRooms ?? []).length > 0) && !(params.id) && queryParam === null) {
			// Preselect TradeRoom
			const firstTradeRoom = fetchedTradeRooms[0];
			const tradeRoomItems = firstTradeRoom.rooms;

			parsedTradeRoom = tradeRoomItems[0];

			if (parsedTradeRoom) {
				const category_index = fetchedTradeRooms.findIndex((item) => item.category._id === params.id);
				setSelectedCategoryIndex(category_index)
				setCurrentTradingRoom(parsedTradeRoom);
				setPreselectedTradingRoom(parsedTradeRoom);
				const path = `/trading-rooms/${firstTradeRoom.category._id }/?room=${parsedTradeRoom._id}`;
				navigate(path);
			}
		} else {
			if (fetchedTradeRooms) {
				const selectedCategory = await fetchedTradeRooms.find((room) => room.category._id === params.id);
				if (selectedCategory) {
					const selectedTradeRoom = await selectedCategory.rooms.find(room => room._id === getQueryParam('room'));
					parsedTradeRoom = selectedTradeRoom
					if (parsedTradeRoom) {
						const category_index = fetchedTradeRooms.findIndex((item) => item.category._id === params.id);
						setSelectedCategoryIndex(category_index)
						setPreselectedTradingRoom(parsedTradeRoom);
						setCurrentTradingRoom(parsedTradeRoom);
					}
				}
			} else {
				if (tradeRooms) {
					const selectedCategory = await tradeRooms.find((room) => room.category._id === params.id);
					if (selectedCategory) {
						const selectedTradeRoom = await selectedCategory.rooms.find(room => room._id === getQueryParam('room'));
						parsedTradeRoom = selectedTradeRoom
						if (parsedTradeRoom) {
							const category_index = tradeRooms.findIndex((item) => item.category._id === params.id);
							setSelectedCategoryIndex(category_index)
							setPreselectedTradingRoom(parsedTradeRoom);
							setCurrentTradingRoom(parsedTradeRoom);
						}
					}
				}
			}
		}

		if (fetchedTradeRooms) {
			setTradeRooms(fetchedTradeRooms);
		}

        setMessages([]);

        if (currentTradingRoom || parsedTradeRoom) {
            socket.emit('joinRoom', {
                room: parsedTradeRoom._id,
                user: JSON.parse(localStorage.getItem('user'))
            });
        } else {
			console.log('No trade room')
		}

		initializingFunctions(fetchedInteractions)

        return () => {
            setActiveUsers([]);
            socket.emit('leaveRoom', {
                room: parsedTradeRoom._id,
                user: JSON.parse(localStorage.getItem('user'))
            });
        };
	};

	useEffect(() => {
		setSidebarOpen(true)
		fetchTradeRooms();
	},[]);

	const getQueryParam = (paramName) => {
		const queryString = window.location.search;
		const paramIndex = queryString.indexOf(`${paramName}=`);

		if (paramIndex !== -1) {
		  const startIndex = paramIndex + paramName.length + 1; // Add 1 to skip '='
		  const endIndex = queryString.indexOf('&', startIndex) !== -1 ? queryString.indexOf('&', startIndex) : undefined;
		  const paramValue = endIndex !== undefined ? queryString.substring(startIndex, endIndex) : queryString.substring(startIndex);
		  return paramValue;
		} else {
		  return null;
		}
	};

	useEffect(() => {
		const newTitle = "Trading Rooms";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	function initializingFunctions(fetchedInteractions) {
		//receiving recent previous messages

		if (interactions) {
			setInteractions(fetchedInteractions);
		}

        socket.on('previousMessages', data => {
			console.log(data);
            setMessages(data);
        })

        //receiving incoming messages
        socket.on('messageBroadcast', data => {
			// console.log(data);
            setMessages(data);
            socket.emit('seenMessage', data[data.length - 1]);
        });

        //receiving seen messages
        socket.on('seenMessageBroadcast', data => {
            setSeenMessages(seenMessages => seenMessages.concat(data));
        })

        //receiving typing events
        socket.on('typingBroadcast', data => {
			console.log(data);
            // if (data.is_typing) {
            //     if (data.user.username === user.username) {
            //         return;
            //     }
            //     const index = typing.findIndex((element) => element.user.username === user.username);
            //     if (index === -1) {
            //         setTyping(typing => typing.concat(data));
            //     }
            // } else {
            //     const updatedTyping = typing.filter(a => a.user.username !== data.user.username);
            //     console.log(updatedTyping)
            //     setTyping(updatedTyping);
            // }
        });

        //receiving active users
        socket.on('activeUserBroadcast', data => {
            setActiveUsers(data);
        });

        //receiving reaction events
        socket.on('messageReactionBroadcast', data => {
			console.log(data);
            setReactions(reactions => reactions.concat(data));
        })

	}

	function handleCreateNewTradingRoom() {
		setShowCreateTradingRoomModal(true)
	}

	function handleMessageSend(e) {
		sendMessage(e);
	}

    const sendMessage = (msg) => {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user) {
			socket.emit('message', {
				room: currentTradingRoom._id,
				room_details: currentTradingRoom,
				user: user,
				msg: msg
			});
			socket.emit('typing', {
				room: currentTradingRoom._id,
				user: user,
				is_typing: false
			});
		}

        // setMessage('');
    }

	function navgateToLiveStream(livestream, e) {
		e.preventDefault();
		const path = '/livestream/'+ livestream._id
		navigate(path)
	}

	function handleToggleIndex(index) {
		if (hoverIndex.includes(index) === false) {
			handleHoverEnterOnIndex(index)
		} else {
			handleHoverLeaveOnIndex(index)
		}
		setCount(count + 1)
	}

	function handleHoverEnterOnIndex(index) {
		let updatedHoverIndicies = hoverIndex
		updatedHoverIndicies.push(index)
		setHoverIndex([index])
	}

	function handleHoverLeaveOnIndex(index) {
		let filtered = hoverIndex.filter((item) => item !== index)
		setHoverIndex(filtered)
	}

	function handleSelectedReplyMessage(message) {
		setSelectedReplyMessage(message.msg._id)
		const index = messages.findIndex((item) => item._id === message.msg._id)
		if (index > -1) {
			scrollToIndex(index)
		}
	}

	const scrollToIndex = (index) => {
		const itemRef = containerRef.current.children[index];
		if (itemRef) {
			itemRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	};

	function handleTradingRoomCreateSuccess() {
		setShowCreateTradingRoomModal(false);
		setCount(count + 1)
	}
	useEffect(() => {

	}, [count])

	return (
		<>
			{ count !== undefined && (
				<div className="trading-rooms-section">
					<div className="trading-rooms-wrapper">
						<TradeRoomsSidebar
							open={leftSidebarOpen}
							setOpen={setLeftSidebarOpen}
							selectedRoom={selectedRoom}
							tradeRooms={tradeRooms}
							preselectedRoom={preselectedTradingRoom}
							selectedIndex={selectedCategoryIndex}
							handleCreateNewTradingRoom={() => handleCreateNewTradingRoom()}
						/>
						<div className="trade-rooms-area">
							<div className="trade-rooms-area-header">
								<div className="authors">
									<img
										style={{height: '55px', width: '55px', borderRadius: '6px', objectFit: 'cover'}}
										src={currentTradingRoom ? (currentTradingRoom.thumbnailURL !== '' ? currentTradingRoom.thumbnailURL : '/global/placeholderImage.jpg') : '/global/placeholderImage.jpg'} alt=""/>
									<div className="name-area">
										<Link to="#">
											<h6 className="text-24 font-semibold mb-0">
												{currentTradingRoom ? currentTradingRoom.title : ''} <PinnedIcon/>{" "}
											</h6>
										</Link>
										<span className="text-text d-flex align-items-center gap-1">
										<MembersIcon/> 0 Members
									</span>
									</div>
								</div>
								<form onSubmit={handleSubmit} className="search-form">
									<div className="group-input">
										<button
											type="submit"
											className="search-btn no-gutter"
										>
											<SearchIcon/>
										</button>
										<input
											type="text"
											placeholder="Search"
											className="form-control"
										/>
									</div>
								</form>
								<div className="d-flex gap-3">
									<button className="no-gutter">
										<PlusIcon/>
									</button>
									<img src={team} alt=""/>
									<button
										className="no-gutter"
										onClick={() =>
											setRightSidebarOpen(!rightSidebarOpen)
										}
									>
										<FilterIcon/>
									</button>
								</div>
							</div>
							<div ref={containerRef} style={{maxHeight: '75vh', overflow: 'scroll'}}>

								{messages ? (
									(messages).map((message, index) => {
										return (
											<>
												<div>
													<div className="or mb-4 pb-2 text-34343E text-12">
														<span>Monday, June 16, 2023</span>
													</div>
													<div
														onMouseEnter={() => handleHoverEnterOnIndex(index)}
														onMouseLeave={() => handleHoverLeaveOnIndex(index)}
														// onClick={() => handleToggleIndex(index)}
														className={`post--item ${hoverIndex.includes(index) === true ? 'bg--section-2' : ''} ${(selectedReplyMessage ?? '') === message._id ? 'border-left bg--warning-light' : ''}`}
														key={index}>
														<div className="img">
															<img src={message.user ? (message.user.photo !== '' ? message.user.photo : '/avatar/blankUser.jpg') : author1}
																 alt=""/>
														</div>
														<div className="cont">
															<h6 className="name">
																{message.user ? (message.user.firstName ? message.user.firstName : '') : ''} {message.user ? message.user.lastName ?? '' : ''}{"	"}
																<span
																	className="badge badge-base mx-2">Expert Trader</span>
																<span className="time">{message.updatedAt ?? ''}</span>
															</h6>
															<p>{message.msg ?? ''}</p>

															{/* Content Block */}
															{message.content && message.content.length > 0 && (
																<>
																	{message.content[0].type === 'image' ? (
																		<div
																			className="d-flex align-items-center flex-wrap gap-4"
																			style={{marginBottom: '20px'}}>
																			<img
																				src={message.content[0].url ?? ''}
																				style={{
																					borderRadius: "14px",
																					maxWidth: '284px'
																				}}
																				alt=""
																			/>
																			<button className="no-gutter">
																				<ShareIcon2/>
																			</button>
																		</div>
																	) : null}
																</>
															)}
															{message.content.length > 0 && (
																<>
																	{message.content[0].type === 'trade-idea' ? (
																		<div className={'gap-3'}
																			 style={{maxWidth: "284px"}}>
																			<h6 className="name">
																				{/*Trade Idea: 493438{" "}*/}
																				<span
																					className="badge badge--warning bot mx-1"> Traderswing.io </span>{" "}
																				<span
																					className="time">Trade Idea: 493438{" "}</span>
																			</h6>
																			<SignalCard
																				props={message.content[0].value ?? {}}/>
																			{/*<Link to="" className="text-base font-semibold">*/}
																			{/*	Go to Trade Ideas*/}
																			{/*</Link>*/}
																		</div>
																	) : null}
																</>
															)}
															{message.content.length > 0 && (
																<>
																	{message.content[0].type === 'live-stream' ? (
																		<Link
																			onClick={(e) => navgateToLiveStream(message.content[0].value, e)}
																			className="news-stream">
																			{message.content[0].value.thumbnail ? (
																				<img style={{height: '250px'}}
																					 src={message.content[0].value.thumbnail ?? '/'}
																					 alt=""/>
																			) : (<> </>)}

																			<div className="py-3 px-4">
																				<span
																					className="tag">New Livestream</span>
																				<h6>{message.content[0].value.roomName ?? ''}</h6>
																			</div>
																		</Link>
																	) : null}
																</>
															)}
															{message.content.length > 0 && (
																<>
																	{message.content[0].type === 'audio' ? (
																		<div style={{maxWidth: "560px"}}>
																			{/* <AudioPlayer2 url={"/audio.mp3"} /> */}
																			<AudioPlayer3/>
																		</div>
																	) : null}
																</>
															)}

															{/* Replying Block */}
															{message.replying && (
																<>
																	{message.replying.msg ? (
																		<blockquote
																			onClick={() => handleSelectedReplyMessage(message.replying)}
																			className="reply">
																			<span>in reply to {message.replying.user.firstName}</span>
																			<div>{message.replying.msg.msg}</div>
																		</blockquote>
																	) : null}
																</>
															)}
															{/*{message.replying.msg ? (*/}
															{/*	<blockquote className="reply" >*/}
															{/*		<span>in reply to {message.replying.user.firstName}</span>*/}
															{/*		<div>{message.replying.msg.msg}</div>*/}
															{/*	</blockquote>*/}
															{/*) : (<> </>)}*/}

															{/* Reactions Block */}
															{(message.reactions_parsed ?? []).length > 0 ? (
																<ReactionHolder reactions={message.reactions_parsed}
																				allReactions={interactions}/>) : (<></>)}
														</div>
														{hoverIndex.includes(index) && (
															<div className="post-reacts-tooltip">
																<button type="button" className="no-gutter">
																	<ShareIcon/>
																</button>
																<button type="button" className="no-gutter">
																	<ReactionIcon iconOnly={<HahaIcon3/>}/>
																</button>
																<Dropdown>
																	<DropdownToggle className="no-gutter p-0 m-0">
																		<EllipisIcon/>
																	</DropdownToggle>
																	<DropdownMenu>
																		<Dropdown.Item className="dropd-link">
																			Reply
																		</Dropdown.Item>
																		<Dropdown.Item className="dropd-link">
																			Delete
																		</Dropdown.Item>
																	</DropdownMenu>
																</Dropdown>
															</div>
														)}
													</div>
												</div>

											</>
										)
									})
								) : (
									<> </>
								)}
							</div>

							<br/>
							<CommentForm handleSend={handleMessageSend}/>
						</div>
					</div>
					<TradeRoomsRightSidebar
						rightSidebarOpen={rightSidebarOpen}
						setRightSidebarOpen={setRightSidebarOpen}
					/>
				</div>
			)}
			<CreateTradingRoomModal show={showCreateTradingRoomModal} onHide={() => setShowCreateTradingRoomModal(false)} onSuccess={() => handleTradingRoomCreateSuccess()} />
		</>
	);
};

export default TradingRooms;
