import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../assets/img/tradessly-community/1.png";
import img2 from "../../assets/img/tradessly-community/2.png";
import img3 from "../../assets/img/tradessly-community/3.png";
import img4 from "../../assets/img/tradessly-community/4.png";
import img5 from "../../assets/img/tradessly-community/5.png";
import { SearchIcon, TopStreakIcon } from "../../components/Icon";
import { APIBaseURL, CurrentCompany, Subdomain } from "../../util/DomainHelper";
import CreateCommunityModal from "./components/CreateCommunityModal";

const Community = ({ onTitleChange }) => {

	const navigate = useNavigate();

	const [discussions, setDiscussions] = useState();
	const [communities, setCommunities] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [showMore, setShowMore] = useState(false);

	const [showCreateCommunityModal, setShowCreateCommunityModal] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("searching...");
	};

	const selectedDiscussion = (discussion) => {
		navigate(`/community/${discussion._id}`);
	};

	const fetchCommunities = async () => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `community/communities/${company._id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.communities;
					if (data !== undefined && data != null) {
						setCommunities(data);
					} else {
						console.log('Failed to fetch data');
						setCommunities([]);
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	const fetchCommunityDiscussions = async () => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `community/community-discussions/${company._id}`;
		try {
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
				}
			});

			const responseJSON = await response.json();
			const data = responseJSON.discussions;
			setDiscussions(data);
			setInitialized(true)
		} catch(error) {
			console.log(error);
			setInitialized(true)
		}
	};

	async function handleCommunityUploadSuccess() {
		setShowCreateCommunityModal(false)
		await fetchCommunities()
	}

	async function initializePage() {
		await fetchCommunities()
		await fetchCommunityDiscussions();
	}

	useEffect(() => {
		initializePage()
	},[]);

	useEffect(() => {
		const newTitle = "Community";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	return (
		<>
			{ initialized && (
				<>
					<br/>
					<div>
						<div className="community-item-wrapper">
							{(showMore ? (communities) : communities.slice(0, 5)).map((item, index) => (
								<Link
									to={`/community/${item._id}`}
									className="community-item"
									key={index}
								>
									<div className="community-item-img">
										<img
											src={item ? (item.thumbnailPreview ? item.thumbnailPreview : '/global/placeholderImage.jpg') : '/global/placeholderImage.jpg'}
											alt=""/>
									</div>
									<div className="community-item-title">{item.title}</div>
								</Link>
							))}
						</div>
						{ communities.length > 5 && (showMore === false) && (
							<div className="text-center mt-4 pt-3">
								<button onClick={() => setShowMore(true)} className="cmn-btn load-more" type="button">
									Show More
								</button>
							</div>
						)}
						{ communities.length > 5 && (showMore === true) && (
							<div className="text-center mt-4 pt-3">
								<button onClick={() => setShowMore(false)} className="cmn-btn load-more" type="button">
									Show Less
								</button>
							</div>
						)}
					</div>

					<div className="mb-40"></div>
					<div className="row g-4 discussion-articles-row">
						<div className="col-lg-7 col-xl-8">
							<div className="discussions-articles-streaks">
								<div className={'justify-content-between d-flex'}>
									<div className="">
										<h5 className="title">Discussions & Articles</h5>
										<p className="subtext">View all 556 discussions</p>
									</div>
									<button
										className="cmn-btn m-3 px-4"
										onClick={() => setShowCreateCommunityModal(true)}
									>
										Create New Community
									</button>
								</div>

								{/* Search a topic */}

								<form onSubmit={handleSubmit} className="search-form mb-4">
									<div className="group-input">
										<button
											type="submit"
											className="no-gutter search-btn"
											style={{left: "10px"}}
										>
											<SearchIcon/>
										</button>
										<input
											type="text"
											placeholder="Search a topic"
											className="form-control"
										/>
									</div>
								</form>
								<div className="discussions-articles-streaks-list">
									{discussions && discussions.map((item, index) => (
										<Link
											to={`/community/${item._id}`}
											className="discussions-articles-streaks-item"
											key={index}
										>
											<div className="discussions-articles-streaks-item-img">
												<img
													src={item ? (item.thumbnailURL ? item.thumbnailURL : '/global/placeholderImage.jpg') : '/global/placeholderImage.jpg'}
													alt=""/>
											</div>
											<div className="discussions-articles-streaks-item-content">
												<h5 className="title">{item.title}</h5>
												<p className="subtext">View 200 posts in discussion</p>
												<div className="discussions-articles-streaks-item-avatar">
													{item.avatar && item.avatar
														.map((avatar, index) => (
															<img
																src={avatar}
																alt=""
																key={index}
															/>
														))
														.slice(0, 3)}
												</div>
											</div>
										</Link>
									))}
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-xl-4">
							<div className="top-streaks">
								<div className="pb-3">
									<h5 className="title">Top Streaks</h5>
									<p className="subtext">
										Post and Publish on the community on consecutive days
										to earn your streak.
									</p>
								</div>
								<div className="top-streaks-list">
									{[...Array(10)].map((item, index) => (
										<Link
											className="top-streaks-item"
											to="#"
											key={index}
										>
											<img
												src={"/avatar/1.png"}
												width={33}
												height={33}
												alt=""
											/>
											<div className="top-streaks-item-content">
												<h5 className="name m-0">
													<span className="me-2">Jane Copper</span>
													<span className="font-regular">
													<TopStreakIcon/>{" "}
														<span className="ms-1">1,232</span>
												</span>
												</h5>
											</div>
										</Link>
									))}
								</div>
							</div>
						</div>
					</div>
				</>
			)}
			<CreateCommunityModal show={showCreateCommunityModal} onHide={() => setShowCreateCommunityModal(false)} onSuccess={() => handleCommunityUploadSuccess()}/>
		</>
	);
};

const _discussions = [
	{
		img: "/streaks/1.png",
		title: "Trading trends in 2023",
		subtext: "View 200 posts in discussion",
		avatar: ["/avatar/2.png", "/avatar/3.png"],
	},
	{
		img: "/streaks/2.png",
		title: "Trading Strategies Unleashed: Share Your Best Tips",
		subtext: "View 200 posts in discussion",
		avatar: ["/avatar/1.png", "/avatar/3.png", "/avatar/2.png"],
	},
	{
		img: "/streaks/3.png",
		title: "Risk Management Roundtable: Protecting Your Investments",
		subtext: "View 200 posts in discussion",
		avatar: ["/avatar/2.png"],
	},
	{
		img: "/streaks/4.png",
		title: "Trading Strategies Unleashed: Share Your Best Tips",
		subtext: "View 200 posts in discussion",
		avatar: ["/avatar/3.png", "/avatar/2.png"],
	},
	{
		img: "/streaks/5.png",
		title: "Risk Management Roundtable: Protecting Your Investments",
		subtext: "View 200 posts in discussion",
		avatar: ["/avatar/3.png", "/avatar/2.png"],
	},
];

const data = [
	{
		img: img1,
		title: "Forex Trading",
		id: "1",
	},
	{
		img: img2,
		title: "Crypto Trading",
		id: "2",
	},
	{
		img: img3,
		title: "Currency Trading",
		id: "3",
	},
	{
		img: img4,
		title: "Trading News",
		id: "4",
	},
	{
		img: img5,
		title: "Hot Stocks",
		id: "5",
	},
];

export default Community;
