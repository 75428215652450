import { APIBaseURL, Subdomain } from "./DomainHelper";
import { Helmet } from 'react-helmet-async';
import '../App.scss'; // Import your SCSS file

export const CurrentCompany = async (shouldRefetch) => {
    const storedCompanyObject = localStorage.getItem('company');
    const storedConfigurationObject = localStorage.getItem('configuration');

    if (!storedCompanyObject || !storedConfigurationObject || shouldRefetch) {
        if (Subdomain.toLowerCase() !== 'www') {
            const url = APIBaseURL() + `company/slug/${Subdomain.toLowerCase()}`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'origin': '*',
                        'x-requested-with': '*',
                        'Content-Type': 'application/json'
                    }
                });

                const responseJSON = await response.json();
                const data = responseJSON.data;
                if (data) {
                    if (data.company) {
                        const fetchedCompany = data.company;
                        localStorage.setItem('company', JSON.stringify(fetchedCompany));
                    }
                    if (data.configuration) {
                        const fetchedConfiguration = data.configuration;
                        localStorage.setItem('configuration', JSON.stringify(fetchedConfiguration));
                    }
                    if (data.signalConfiguration) {
                        const fetchedSignalConfiguration = data.signalConfiguration ?? {};
                        localStorage.setItem('signal_configuration', JSON.stringify(fetchedSignalConfiguration));
                    }
                    return data;
                } else {
                    return undefined;
                }
            } catch(error) {
                // console.log(error);
                // console.log('Failed to fetch company with an error.');
                return undefined;
            }
        } else {
            return undefined;
        }
    } else {
        const storedCompany = JSON.parse(storedCompanyObject);
        return storedCompany;
    }
};

export const CurrentCompanyConfiguration = async (shouldRefetch) => {
    const storedConfigurationObject = localStorage.getItem('configuration');

    if (!storedConfigurationObject || shouldRefetch) {
        const url = APIBaseURL() + `company/slug/${Subdomain.toLowerCase()}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                }
            });

            const responseJSON = await response.json();
            const data = responseJSON.data;
            const fetchedConfiguration = data.configuration ?? {};
            const fetchedSignalConfiguration = data.signalConfiguration ?? {};
            localStorage.setItem('configuration', JSON.stringify(fetchedConfiguration));
            localStorage.setItem('signal_configuration', JSON.stringify(fetchedSignalConfiguration));
            return fetchedConfiguration;
        } catch(error) {
            console.log(error);
            console.log('Failed to fetch company with an error.');
            return undefined;
        }
    } else {
        const storedCompanyConfiguration = JSON.parse(storedConfigurationObject);
        return storedCompanyConfiguration;
    }
};

export const getCompanySignalConfiguration = () => {
    const storedConfigurationObject = localStorage.getItem('signal_configuration');
    if (storedConfigurationObject) {
        const storedCompanySignalConfiguration = JSON.parse(storedConfigurationObject);
        return storedCompanySignalConfiguration;
    } else {
        return undefined
    }
};

export const PerformLogout = () => {
    localStorage.removeItem('user');
    window.location.reload();
};

export const ConfigureApplication = async () => {
    // localStorage.clear();

    const company = await CurrentCompany(true);
    if (!company) {
        return false;
    }
    const configuration = await CurrentCompanyConfiguration();
    if (!configuration) {
        return false;
    }
    const signalConfiguration = await getCompanySignalConfiguration();
    if (!signalConfiguration) {
        return false;
    }

    const currentPath = window.location.pathname;
    const origin = window.location.origin;

    if (!configuration || !company) {
        if (currentPath != '/error') {
            window.location.href = `${origin}/error`;
        }
        return;
    }
    if (currentPath === '/error') {
        window.location.href = `${origin}/`;
    }
    if (configuration.primaryColor) {
        document.documentElement.style.setProperty('--base', configuration.primaryColor);
    }
    if (configuration.secondaryColor) {
        document.documentElement.style.setProperty('--base-2', configuration.secondaryColor);
    }
    if (configuration.favicon) {
        const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
        document.head.removeChild(favicon);
        favicon.rel = 'icon';
        favicon.href = configuration.favicon;
        document.head.appendChild(favicon);
    }
    return true;
};
