import React, {useEffect} from "react";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import Input from "../../components/Input";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import {useNavigate} from "react-router-dom";

const IntendLearners = () => {

	const navigate = useNavigate();
	const [intendedLearnersContent, setIntendedLearnersContent] = React.useState([{content: '', timestamp: new Date().getTime()}]);

	const { updateCourseDetails, fetchCourse } = useCreateCourse();

	function handleIntendedLearnersContentChange(e, index) {
		let allContent = intendedLearnersContent
		let parsedContent = allContent[index];
		parsedContent.content = e.target.value;
		allContent[index] = parsedContent
		updateCourseDetails({ intended_learners_content: allContent })
	}

    useEffect(() => {
		const currentCourseDetails = fetchCourse()
	})

	return (
		<CoursePlanLayout>
			<div style={{ maxWidth: "841px" }}>
				<h5 className="title">Intended learners</h5>
				<p className="txt pb-md-3">
					The following descriptions will be publicly visible on your
					Course Landing Page and will have a direct impact on your course
					performance. These descriptions will help learners decide if your
					course is right for them.
				</p>
			</div>
			<hr
				className="pb-md-3"
				style={{ borderWidth: "2px", opacity: "1", borderColor: "#88869F" }}
			/>
			<div style={{maxWidth: "841px"}}>
				<h6 className="subtitle">
					What will students learn in your course?
				</h6>
				<p className="txt pb-lg-3">
					You must enter at least 4 learning objectives or outcomes that
					learners can expect to achieve after completing your course.
				</p>
				<div className="example-inputs">
					{intendedLearnersContent.length === 0 && (
						<Input
							onChange={(e) => handleIntendedLearnersContentChange(e, 0)}
							placeholder="Example: Grasp key principles in the subject, building a strong foundation"
							endText={160}
						/>
					)}
					{intendedLearnersContent.length > 0 && intendedLearnersContent.map((content, index) => (
						<Input
							defaultValue={content.content ? content.content : ''}
							onChange={(e) => handleIntendedLearnersContentChange(e, index)}
							placeholder="Example: Grasp key principles in the subject, building a strong foundation"
							endText={160}
						/>
					))}
					<div>
						<button
							className="btn --badge btn--secondary btn-- font-semibold btn-2"
							type="button"
							onClick={() => {
								setIntendedLearnersContent([...intendedLearnersContent, {
									content: '',
									timestamp: new Date().getTime()
								}]);
							}}
						>
							+ Content item
						</button>
					</div>
				</div>
				<h6 className="subtitle pt-4 pt-md-5">
					What are the requirements or prerequisites for taking your
					course?
				</h6>
				<p className="txt pb-lg-3">
					List the required skills, experience, tools or equipment learners
					should have prior to taking your course.If there are no
					requirements, use this space as an opportunity to lower the
					barrier for beginners.
				</p>
				<div className="example-inputs">
					<Input onChange={(e) => updateCourseDetails({intended_learners_prerequisites: e.target.value})}
						   placeholder="Example: You will learn everything you need. No Prior experience required"/>
				</div>
				<h6 className="subtitle pt-4 pt-md-5">Who is this course for?</h6>
				<p className="txt pb-lg-3">
					Write a clear description of the intended learners for your
					course who will find your course content valuable. This will help
					you attract the right learners to your course.
				</p>
				<div className="example-inputs">
					<Input onChange={(e) => updateCourseDetails({intended_learners_target: e.target.value})}
						   placeholder="Example: Beginner trader"/>
				</div>
				<div className="d-flex w-100 mt-md-3 gap-4">
					<button
						className="cmn-btn btn-outline px-4 mt-3"
						onClick={() => navigate('/course-plan/settings')}
					>
						Back
					</button>
					<button
						className="cmn-btn px-4 mt-3"
						onClick={() => navigate('/course-plan/curriculum')}
					>
						Save & Continue
					</button>

				</div>
			</div>
		</CoursePlanLayout>
	);
};

export default IntendLearners;
