import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import img1 from "../../assets/img/plan/plan-bg-1.png";
import img2 from "../../assets/img/plan/plan-bg-2.png";
import img3 from "../../assets/img/plan/plan-bg-3.png";

import Input from "../../components/Input";
import AdminAuthLayout from "./components/AdminAuthLayout";
import {useAdminRegistration} from "../../contexts/AdminRegistrationContext";
import {toast} from "react-toastify";
import NetworkingProvider from "../../providers/NetworkingProvider";
import AdminSignUpSuccessCard from "./components/AdminSignUpSuccessCard";

const AdminSignUpStep4 = () => {

	const { updateRegistrationDetails, registrationDetails, initialize, reset } = useAdminRegistration()

	const [selected, setSelected] = useState()
	const [selectedPackage, setSelectedPackage] = useState()
	const [packages, setPackages] = useState();

	const [initialized, setInitialized] = useState(false);
	const [registrationCompleted, setRegistrationCompleted] = useState(false);
	const [companySlug, setCompanySlug] = useState("")
	const [companyName, setCompanyName] = useState("")

	const [cardNumber, setCardNumber] = useState("");
	const [amount, setAmount] = useState("");
	const [exp, setExp] = useState("");

	const [tradingTypes, setTradingTypes] = useState();
	const [tradingFlags, setTradingFlags] = useState();
	const [selectedFlags, setSelectedFlags] = useState([]);

	const navigate = useNavigate();

	async function initializePage() {
		try {
			const dataResponse = await NetworkingProvider.fetchData('auth/packages', 'GET')
			const data = dataResponse.data
			if (data) {
				setPackages(data)
				return data;
				// setSelected(0)
			}
		} catch (e) {
			return undefined
		}
	}

	async function fetchPackages() {
		const hasInitialized = await initializePage()
		if (hasInitialized) {
			handlePackageSelected(0)
			setInitialized(true)
		}
	}

	function handlePackageSelected(e) {
		if ((packages ?? []).length > 0) {
			setSelected(e)
			// const selectedIndex = (packages ?? []).findIndex((item) => item._id === selected)
			// console.log(selectedIndex)
			const targetPackage = packages[e]
			setSelectedPackage(targetPackage)
		}
	}

	const handleCardNumber = (e) => {
		let inputText = e.target.value;
		inputText = inputText.replace(/\D/g, "");
		const formattedCardNumber = inputText.replace(/(\d{4})/g, "$1 ");
		const value = formattedCardNumber.trim();
		setCardNumber(value);
		// onCardNumberChange(value, 'billing_cardNumber');
	};

	const handleCVC = (event) => {
		const value = event.target.value.replace(/\D/g, "");
		setAmount(value);
		// onCvcChange(value, 'billing_cvc');
	};

	const handleExpChange = (event) => {
		const value = event.target.value.replace(/\D/g, "");
		setExp(value);
		// onExpChange(value, 'billing_exp');
	};

	async function handleSubmit(e) {
		e.preventDefault();
		if (!selectedPackage) {
			return toast.warning('Please make a package selection');
		}

		updateRegistrationDetails({ package: selectedPackage._id ?? '' });
		updateRegistrationDetails({ url: window.location.href });

		try {
			const dataResponse = await NetworkingProvider.fetchData('auth/register/store', 'POST', registrationDetails());
			// console.log(dataResponse);
			const data = dataResponse.data;
			const message = dataResponse.message
			if (data) {
				if (data.registrant) {
					const registrant = data.registrant
					setCompanySlug(registrant.slug)
					setCompanyName(registrant.name)
					return setRegistrationCompleted(true)
				}
				if (data.url) {
					return window.location.href = data.url;
				}
				return
			}
			if (message) {
				return toast.error(message ?? 'Unable to register');
			}
		} catch (e) {
			return console.error(e);
		}
		// navigate("/auth/register/success");
	}

	async function checkForSession() {
		const urlParams = new URLSearchParams(window.location.search);
		const sessionParam = urlParams.get('session');
		if (sessionParam) {
			try {
				const dataResponse = await NetworkingProvider.fetchData(`auth/register/check/${sessionParam}`, 'GET');
				const data = dataResponse.data;
				const message = dataResponse.message
				if (data) {
					if (data.status === 'complete' && data.slug) {
						setCompanySlug(data.slug)
						setCompanyName(data.name)
						return setRegistrationCompleted(true)
					}
				}
				if (message) {
					toast.error(message ?? 'Unable to register');
				}
			} catch (e) {
				console.error(e);
			}
		}

		if ((packages ?? []).length === 0) {
			await fetchPackages()
		}
	}

	function navigateToCompanyDashboard(e) {
		console.log("navigateToCompanyDashboard");
		if (companySlug) {
			reset()
			const path = `https://${companySlug}.trdsly.com`
			window.location.href = path;
		}
	}

	useEffect(() => {
		initialize()
		checkForSession()
	},[packages]);

	return (
		<>
			<AdminAuthLayout
				title={!registrationCompleted ? "Sign Up" : ''}
				subtitle={!registrationCompleted ? "Choose A Package" : ''}
				text={!registrationCompleted ? "Let's choose a package that best suites your needs." : ''}
				fullwidth
				altLoginText
			>
				{ !registrationCompleted && (
					<>
						<div className="mb-4">
							<div className="progress __progress base mt-4">
								<div
									className="progress-bar"
									role="progressbar"
									style={{ width: "75%" }}
								></div>
							</div>
						</div>
						{ initialized ? (<form onSubmit={handleSubmit}>
							<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
								<div className="row g-4">
									{/*SECTION 1*/}
									<div className="col-md-6 pe-xl-3">
										<div className="row g-4">
											<label className="form-label form--label mb-0">
												Select a package
											</label>
											{packages && packages.map((item, index) => (
												<div className="col-12" key={index}>
													<div
														className={`card card-2 ${
															selected === item.type ? "active" : ""
														}`}
														style={{
															background: `url(${item.bg}) no-repeat center center / cover`,
														}}
													>
														<div className="card-body">
															<div>
																<div className="card-title">
																	Tradessly{" "}
																	<span className="badge">{item.label}</span>
																</div>
																<div className="card-text">{item.priceLabel}</div>
															</div>
															<div>
																<button
																	type="button"
																	className={`plan-btn ${
																		selected === index ? "active" : ""
																	}`}
																	onClick={() => handlePackageSelected(index)}
																>
																	{selected === index
																		? "Selected"
																		: "Select"}
																</button>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
										{/*<br/>*/}
										{/*<br/>*/}
										{/*<label className="form-label form--label--disclaimer mb-0">*/}
										{/*	{disclaimer}*/}
										{/*</label>*/}
									</div>
									{/*SECTION 2*/}
									<div className="col-md-6 ps-xl-5">
										<div className="row g-4">
											{/*<label className="form-label form--label mb-0">*/}
											{/*	Here's what's included:*/}
											{/*</label>*/}
											<label className="form-label form--label--description mb-0">
												{selected !== undefined ? (selectedPackage ? selectedPackage.description : '') : ""}
											</label>
											<br/>
											<br/>
											< div className={'plan-summary-content'}>
												<ul className="includes">
													{ selected !== undefined && selectedPackage && (
														<>
															{selectedPackage.contents && (selectedPackage.contents ?? []).map((contents, index) => (
																<li key={index}>
																	<div className="col-12 d-flex">
																		<div className="payment-txt">
																			<h5 className="subtitle">{contents.title}</h5>
																			<span>{contents.value}</span>
																		</div>
																	</div>
																</li>
															))}
														</>
													)}
												</ul>
											</div>

											{/*{selectedPackage && selectedPackage.map((contents, index) => (*/}
											{/*	<div className="col-12 d-flex">*/}
											{/*		<div className="payment-txt">*/}
											{/*			<h5 className="subtitle">{contents.title}</h5>*/}
											{/*			<span>{contents.description}</span>*/}
											{/*		</div>*/}
											{/*	</div>*/}
											{/*))}*/}
										</div>
										{/*<br/>*/}
										{/*<br/>*/}
										{/*<label className="form-label form--label--description mb-0">*/}
										{/*	{ disclaimer}*/}
										{/*</label>*/}
										{/*<div className="text-white">*/}
										{/*	Powered By <strong>Stripe</strong>*/}
										{/*</div>*/}
										{/*<br/>*/}
										{ selectedPackage && selectedPackage.price !== 0 && (
											<>
												<br/>
												<br/>
												<div className="text-white">
													Powered By <strong>Stripe</strong>
												</div>
											</>
										)}
										<div className="mt-4 mt-sm-5">
											<button
												type="submit"
												className="btn w-100 cmn-btn base h-69"
											>
												Sign Up
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>) : null}
					</>
				)}
				{ registrationCompleted && (
					<AdminSignUpSuccessCard slug={companySlug} name={companyName} handleNavigateToCompanySite={navigateToCompanyDashboard} />
				)}
			</AdminAuthLayout>
		</>
	);
};

const data = [
	{
		type: "Free",
		subtext: "Free Forever",
		bg: img1,
	},
	{
		type: "PRO",
		subtext: "$49.99 / Month",
		bg: img2,
	},
	{
		type: "Ultimate",
		subtext: "$99.99 / Month",
		bg: img3,
	},
];

const packageDetails = "Empower your institution with our comprehensive education platform package designed to streamline teaching, learning, and administrative processes. Our platform offers a robust suite of tools and features tailored to meet the diverse needs of educators, students, and administrators."
const disclaimer = "By subscribing to our service, you agree to the following terms:\n" +
	"\n" +
	"Cancellation: You can cancel your subscription at any time. Cancellation requests must be made before the next billing cycle to avoid being charged for the next period.\n" +
	"Refunds: Subscription fees are non-refundable, except as required by law or as stated in our refund policy.\n" +
	"Termination: We may terminate or suspend your subscription without prior notice for violation of these terms or for any other reason at our discretion.\n" +
	"Please read our full terms of service and privacy policy for more information."

const _selectedPackage = [
	{
		title: "Unlimited Student Enrollments",
		description: "Sign up as many students as you want. No LIMITS!",
	},
	{
		title: "Integrated Live Zoom Streams",
		description: "Offer live trading? Connect your zoom meetings within the platform",
	},
	{
		title: "Smart Daily Market News",
		description: "Get the latest market conditions tailored to your target pairs and currencies",
	},
	{
		title: "Embedded Indicators",
		description: "Embed your pinescript indicators in your platforms TradingView chart",
	},
	{
		title: "Communities",
		description: "Open forum-styled rooms for your various topics.",
	},
	{
		title: "Trade Idea Notifications",
		description: "Open forum-styled rooms for your various topics.",
	},
	{
		title: "AI Assisted Trade Ideas",
		description: "Take advantage of out Traderswing.io AI Assisted Trade Ideas",
	},
];


export default AdminSignUpStep4;
