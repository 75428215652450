import React from "react";

const InputSwitch = ({ value }) => {
	const [checked, setChecked] = React.useState(value);
	return (
		<>
			<div className="form-check form-switch">
				<input
					className="form-check-input"
					type="checkbox"
					checked={checked}
					onChange={(e) => setChecked(e.target.checked)}
				/>
			</div>
		</>
	);
};

export default InputSwitch;
