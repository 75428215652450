import React, {useEffect, useState} from "react";
import { Collapse } from "react-bootstrap";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import {AngleDown, SuccessIcon} from "../../components/Icon";
import {useNavigate, useParams} from "react-router-dom";
import CourseCard from "../courses/components/CourseCard";
import {Subdomain} from "../../util/DomainHelper";

const CourseCreated = () => {

	const navigate = useNavigate();
	const params = useParams();

	function handleCopyToClipboard() {

	}

	async function initializePage() {

	}

	useEffect(() => {

	})

	return (
		<CoursePlanLayout>
			<div className="row g-4"></div>
			<br/>
			<br className="d-none d-md-block"/>
			<br className="d-none d-md-block"/>
			<div className=" text-center align-items-center">
				<div className="mb-4 mb-md-5">
					<SuccessIcon/>
				</div>
				<h2 className="title mb-3 pb-1">
					Your Course Is Now Published
				</h2>
				<div className="text mb-3">
					Head on over to the Courses section to see your newly created course. <br/>You can also share the
					link below with your communities.
				</div>
				<br/>
				<div className="w-100">
					<label
						onClick={() => handleCopyToClipboard()}
						className="file-input-label w-100">
						{`https://${Subdomain}.trdsly.com/courses/${params.courseId}`}
					</label>
				</div>
				{/*{course && (*/}
				{/*	<div className="w-100">*/}
				{/*		<CourseCard key={0} course={course} preview={true}/>*/}
				{/*	</div>*/}
				{/*)}*/}
				<br/>
				<div className="mt-5 mt-sm-5">
					<button
						type="submit"
						className="btn w-100 cmn-btn base h-69"
						onClick={() => navigate("/courses")}
					>
						Go to Courses
					</button>
					{/*<button*/}
					{/*	type="submit"*/}
					{/*	className="btn w-100 cmn-btn btn-outline h-69 mt-3"*/}
					{/*	onClick={() => navigate("/courses")}*/}
					{/*>*/}
					{/*	Create New Course*/}
					{/*</button>*/}
				</div>
			</div>
		</CoursePlanLayout>
	);
};
export default CourseCreated;
