import React, {useEffect} from "react";
import {Dropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import img1 from "../../assets/img/flag/image 14.png";
import img2 from "../../assets/img/flag/image 15.png";
import img3 from "../../assets/img/flag/image 16.png";
import img4 from "../../assets/img/flag/image 17.png";
import img5 from "../../assets/img/flag/image 18.png";
import img6 from "../../assets/img/flag/image 19.png";
import img7 from "../../assets/img/flag/image 20.png";
import img8 from "../../assets/img/flag/image 21.png";
import img9 from "../../assets/img/flag/image 22.png";
import img10 from "../../assets/img/flag/image 23.png";
import img11 from "../../assets/img/flag/image 24.png";
import img12 from "../../assets/img/flag/image 25.png";
import img13 from "../../assets/img/flag/image 26.png";
import img14 from "../../assets/img/flag/image 27.png";
import img15 from "../../assets/img/flag/image 28.png";
import img16 from "../../assets/img/flag/image 29.png";
import img17 from "../../assets/img/flag/image 30.png";
import img18 from "../../assets/img/flag/image 31.png";
import img19 from "../../assets/img/flag/image 32.png";
import img20 from "../../assets/img/flag/image 33.png";
import img21 from "../../assets/img/flag/image 34.png";
import img22 from "../../assets/img/flag/image 35.png";
import img23 from "../../assets/img/flag/image 36.png";
import img24 from "../../assets/img/flag/image 37.png";
import img25 from "../../assets/img/flag/image 38.png";
import img26 from "../../assets/img/flag/image 39.png";
import img27 from "../../assets/img/flag/image 40.png";
import img28 from "../../assets/img/flag/image 41.png";
import img29 from "../../assets/img/flag/image 42.png";
import img30 from "../../assets/img/flag/image 43.png";
import {AddIcon, ClearIcon} from "../../components/Icon";
import Input from "../../components/Input";
import AdminAuthLayout from "./components/AdminAuthLayout";
import {useAdminRegistration} from "../../contexts/AdminRegistrationContext";
import {toast} from "react-toastify";

const AdminSignUpStep3 = () => {

	const { updateRegistrationDetails, registrationDetails, initialize } = useAdminRegistration()

	const [newImage, setNewImage] = React.useState({
		id: "",
		base: '',
		conversion: '',
		img1: "",
		img2: "",
	});

	const [checked, setChecked] = React.useState(false);
	const [initialized, setInitialized] = React.useState(false);
	const [selectedOptions, setSelectedOptions] = React.useState(options);
	const [selectedPoints, setSelectedPoints] = React.useState(points);
	const [selectedTradingType, setSelectedTradingType] = React.useState('');
	const [selectedTradingPairs, setSelectedTradingPairs] = React.useState();

	const navigate = useNavigate();

	const handleAddPair = () => {
		if (newImage.img1 === "" || newImage.img2 === "" || newImage.base === "" || newImage.conversion === "") {
			setChecked(false);
			window.alert("Please select a pair");
			return;
		}
		setChecked(true);
		setSelectedOptions((prev) => [...prev, newImage]);
		setTimeout(() => {
			setChecked(false);
			setNewImage({
				id: new Date().getTime(),
				base: '',
				conversion: '',
				img1: "",
				img2: "",
			});
		}, 200);
	};

	function handleSelectedPair(selectedIndex) {
		let parsedSelectedTradingPairs = selectedTradingPairs ?? [];
		let targetPair = selectedOptions[selectedIndex]
		if (parsedSelectedTradingPairs.some((pair) => pair.id === targetPair.id)) {
			parsedSelectedTradingPairs = parsedSelectedTradingPairs.filter((pair) => pair.id !== targetPair.id)
		} else {
			parsedSelectedTradingPairs.push(targetPair)
		}
		setSelectedTradingPairs(parsedSelectedTradingPairs)
	}

	const configurePageDefaults = () => {
		const currentRegistrationDetails = registrationDetails()
		if (currentRegistrationDetails) {
			setSelectedTradingType(currentRegistrationDetails.tradingType)
			setSelectedPoints(currentRegistrationDetails.tradingExperience)
			setSelectedTradingPairs(currentRegistrationDetails.selectedPairs)
		}

		setInitialized(true)
	};

	function handleSubmit(e) {
		e.preventDefault();
		if (!selectedTradingType) {
			return toast.warning('Please select a trading type');
		}
		updateRegistrationDetails({ tradingType: selectedTradingType });
		updateRegistrationDetails({ tradingExperience: selectedPoints });
		updateRegistrationDetails({ selectedPairs: selectedTradingPairs })

		navigate("/auth/register/package");
	}

	useEffect(() => {
		initialize()
		configurePageDefaults();
	},[initialized]);

	return (
		<>
			<AdminAuthLayout
				title="Sign Up"
				subtitle="Trading Info"
				text="Tell us a little about your trading background."
				fullwidth
				altLoginText
			>
				{ initialized && (
					<>
						<div className="mb-4">
							<div className="progress __progress base mt-4">
								<div
									className="progress-bar"
									role="progressbar"
									style={{width: "50%"}}
								></div>
							</div>
						</div>
						<form onSubmit={handleSubmit}>
							<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
								<div className="row g-4">
									<div className="col-md-6 pe-xl-3">
										<div className="row g-4">
											<div className="col-12">
												<Input
													defaultValue={selectedTradingType}
													onChange={(e) => setSelectedTradingType(e)}
													label="Type of Trading"
													options={tradingType}
												/>
											</div>
											<div className="col-12">
												<label className="form-label form--label">
													Brief Trading History{" "}
													<small>
														This will show on your personal profile. (Up
														to 4 Points)
													</small>
												</label>
												<div className="row g-4">
													{(selectedPoints ?? []).map((point, index) => (
														<div className="col-12" key={point.id}>
															<div className="d-flex align-items-center gap-3">
																<span>{index + 1}.</span>
																<div className="flex-grow-1">
																	<Input
																		defaultValue={point.value}
																		value={point.value}
																		placeholder="e.g. 5 years of trading experience"
																		onChange={(e) => {
																			const value = e.target.value;
																			setSelectedPoints((prev) =>
																				prev.map((item, i) =>
																					i === index
																						? {...item, value}
																						: item
																				)
																			);
																		}}
																	/>
																</div>
																{selectedPoints.length - 1 ===
																index ? (
																	<button
																		type="button"
																		className="btn btn--outline p-0 border-0 outline-0 shadow-none"
																		// on click add a new point to the array max 4
																		onClick={() => {
																			if (
																				selectedPoints.length < 4
																			) {
																				setSelectedPoints(
																					(prev) => [
																						...prev,
																						{
																							id: index + 1,
																							value: "",
																						},
																					]
																				);
																			}
																		}}
																	>
																		<AddIcon/>
																	</button>
																) : (
																	<button
																		type="button"
																		className="btn btn--outline p-0 border-0 outline-0 shadow-none text-danger"
																		onClick={() =>
																			setSelectedPoints((prev) =>
																				prev.filter(
																					(item, i) =>
																						i !== index
																				)
																			)
																		}
																	>
																		<ClearIcon/>
																	</button>
																)}
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 ps-xl-5">
										<div className="row g-4">
											<div className="col-12">
												<label className="form-label form--label mb-3">
													Choose Your Favorite Pairs
												</label>
												<div className="form--check-img-group mb-4">
													{selectedOptions.map((option, index) => (
														<label
															className="form-check form--check"
															key={option.id}
														>
															<input
																defaultChecked={(selectedTradingPairs ?? []).some((selectedPair) => selectedPair.id === option.id)}
																type="checkbox"
																className="form-check-input"
																onChange={(e) => handleSelectedPair(index)}
															/>
															<span className="form-check-label">
														<img src={option?.img1} alt=""/>
														<img src={option?.img2} alt=""/>
													</span>
														</label>
													))}
												</div>
												<br/>
												<label
													className="form-check form--check d-flex align-items-center gap-3"
													style={{maxWidth: "400px"}}
												>
													<input
														type="checkbox"
														className="form-check-input m-0"
														checked={checked}
														onChange={handleAddPair}
													/>
													<span className="form-check-label d-flex gap-3 align-items-center">
												<span className="add-pair-txt">
													Add Pair
												</span>
												<Dropdown className="flex-grow-1 __dropdown-country-menu">
													<Dropdown.Toggle
														className="form-control form-control-2 h-45 w-100 ">
														<img src={newImage?.img1} alt=""/>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{country.map((option, index) => (
															<Dropdown.Item
																key={index}
																onClick={() =>
																	setNewImage((prev) => ({
																		...prev,
																		img1: option.img,
																	}))
																}
															>
																<img src={option?.img} alt=""/>
															</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
												<Dropdown className="flex-grow-1 __dropdown-country-menu">
													<Dropdown.Toggle
														className="form-control form-control-2 h-45 w-1000">
														<img src={newImage?.img2} alt=""/>
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{country.map((option, index) => (
															<Dropdown.Item
																key={index}
																onClick={() =>
																	setNewImage((prev) => ({
																		...prev,
																		img2: option.img,
																	}))
																}
															>
																<img src={option?.img} alt=""/>
															</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
											</span>
												</label>
											</div>
											<div className="col-12">
												<div className="row g-4">
													<div className="col-md-12 mt-5">
														<br className="d-none d-md-block"/>
														<br className="d-none d-md-block"/>
														<br className="d-none d-md-block"/>
														<div className="pt-sm-5">
															<button
																type="submit"
																className="btn w-100 cmn-btn h-69"
															>
																Proceed
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</>
				)}
			</AdminAuthLayout>
		</>
	);
};
const options = [
	{
		id: 1,
		base: '',
		conversion: '',
		img1: img1,
		img2: img2,
	},
	{
		id: 2,
		base: '',
		conversion: '',
		img1: img2,
		img2: img1,
	},
	{
		id: 3,
		base: '',
		conversion: '',
		img1: img3,
		img2: img4,
	},
	{
		id: 4,
		base: '',
		conversion: '',
		img1: img4,
		img2: img3,
	},
	{
		id: 5,
		base: '',
		conversion: '',
		img1: img5,
		img2: img6,
	},
	{
		id: 6,
		base: '',
		conversion: '',
		img1: img6,
		img2: img5,
	},
	{
		id: 7,
		base: '',
		conversion: '',
		img1: img7,
		img2: img8,
	},
	{
		id: 8,
		base: '',
		conversion: '',
		img1: img8,
		img2: img7,
	},
];
export const country = [
	{
		img: img1,
		name: "Dansk",
	},
	{
		img: img2,
		name: "Deutch",
	},
	{
		img: img3,
		name: "English, UK",
	},
	{
		img: img4,
		name: "USD",
	},
	{
		img: img5,
		name: "MXN",
	},
	{
		img: img6,
		name: "Francais",
	},
	{
		img: img7,
		name: "Hrvatski",
	},
	{
		img: img8,
		name: "Italiano",
	},
	{
		img: img9,
		name: "Lietuviskai",
	},
	{
		img: img10,
		name: "Magyar",
	},
	{
		img: img11,
		name: "Nederlands",
	},
	{
		img: img12,
		name: "Norsk",
	},
	{
		img: img13,
		name: "Polski",
	},
	{
		img: img14,
		name: "Portugues do Brasil",
	},
	{
		img: img15,
		name: "Romana",
	},
	{
		img: img16,
		name: "Suomi",
	},
	{
		img: img17,
		name: "Svenska",
	},
	{
		img: img18,
		name: "Turkce",
	},
	{
		img: img19,
		name: "Polish",
	},
	{
		img: img20,
		name: "Swahili",
	},
	{
		img: img21,
		name: "Swedish",
	},
	{
		img: img22,
		name: "Palestinian",
	},
	{
		img: img23,
		name: "Isreal",
	},
	{
		img: img24,
		name: "Ukrainian",
	},
	{
		img: img25,
		name: "Hindi",
	},
	{
		img: img26,
		name: "Shqip",
	},
	{
		img: img27,
		name: "Morocco",
	},
	{
		img: img28,
		name: "Korean",
	},
	{
		img: img29,
		name: "Philippines",
	},
	{
		img: img30,
		name: "JPY",
	},
];
const points = [
	{
		id: "",
		value: "",
	},
];

const tradingType = [
	{
		name: "Select One",
		value: "",
	},
	{
		name: "Foreign Exchange",
		value: "forex",
	},
	{
		name: "Crypto Currency ",
		value: "crypto",
	},
	{
		name: "Stock Options",
		value: "stock-options",
	},
	{
		name: "Futures",
		value: "futures",
	},
	{
		name: "Other",
		value: "other",
	},
];

export default AdminSignUpStep3;
