import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import image from "../../assets/img/courses/1.png";
import banner from "../../assets/img/courses/banner.png";
import Layout from "../../components/Layout";
import useScreen from "../../hooks/useScreen";
import { APIBaseURL } from "../../util/DomainHelper";
import {CurrentCompany} from "../../util/CompanyHelper";

const CoursesSingle = ({ onTitleChange }) => {

	const params = useParams();
	const navigate = useNavigate();

	const [courseData, setCourseData] = useState();
	const [modules, setModules] = useState([]);

	const screen = useScreen();

	 const fetchCourse = async () => {
		const currentCompany = await CurrentCompany(false)
		const url = APIBaseURL() + `courses/${params.id}/${currentCompany._id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					if (data) {
						fetchCourseModules(data);
						onTitleChange(data.title);
					} else {
						console.log('Failed to fetch data');
						fetchCourseModules([]);
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	const fetchCourseModules = (fetchedCourseData) => {
		const url = APIBaseURL() + `courses/modules/${params.id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					if (data) {
						setModules(data);
						setCourseData(fetchedCourseData);
					} else {
						console.log('Failed to fetch data');
						setModules([]);
						setCourseData(fetchedCourseData);
					};
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	function handleStartCourse(selectedCourse) {
		console.log('Starting Course');
        if (modules) {
			const module = modules[0];
			console.log(module)
			const moduleLessons = module.videos;
			const firstLesson = moduleLessons[0];
			console.log(firstLesson);
			navigate(`/courses/watch/${firstLesson?._id}`)

		}
	}

	useEffect(() => {
		fetchCourse();
	},[]);



	return (
		<>
				<br />
				<div
					className="course-banner"
					style={{
						background: `url(${banner}) #212128 no-repeat center center /  cover`,
					}}
				>
					<div className="row g-4">
						<div className="col-md-7 col-xl-8">
							<div className="course-banner-inner">
								<ul className="breadcrumb">
									<li>
										<Link to="#">Courses</Link>
									</li>
									<li>
										<Link to="#">Influencers</Link>
									</li>
									{/* <li>
										<Link to="#">Graham Stephan</Link>
									</li> */}
								</ul>
								<h3 className="title">{courseData ? courseData.title : ''}</h3>
								<p>
									{courseData ? courseData.description : ''}
								</p>
								{courseData && courseData.recommended === true && (
									<span className="badge">Recommended course</span>
								)}
								<div className="meta">
									<span>Created By</span>
									<Link to="#">{courseData ? courseData.creator : ''}</Link>
								</div>
							</div>
						</div>
						{screen >= 768 ? (
							<div className="col-md-5 col-xl-4">
								<div className="position-relative">
									<CourseSummary courseData={courseData} handleStartCourse={handleStartCourse}/>
								</div>
							</div>
						) : (
							""
						)}
					</div>
				</div>
				<br />
				<br />
				<div className="row g-4">
					{screen < 768 ? (
						<div className="col-12">
							<div style={{ marginTop: "-200px", position: "relative" }}>
								<CourseSummary courseData={courseData} handleStartCourse={handleStartCourse}/>
							</div>
						</div>
					) : (
						""
					)}
					<div className="col-md-7 col-xl-8">
						{/* Create an accordion using react bootstrap accordion */}
						<div className="course-preview-accordion">
							<Accordion defaultActiveKey={0}>
								{modules && modules.map((item, index) => (
									<Accordion.Item eventKey={index} key={index}>
										<Accordion.Header>{item.module.title}</Accordion.Header>
										<Accordion.Body>
											<ul className="sub-data">
												{item.videos.map((subItem, subIndex) => (
													<li key={subIndex}>
														<Link
															to={`/courses/watch/${subItem?._id}`}
														>
															<span className="me-auto">
																{subItem.title}
															</span>
															{subItem?.previewURL ? (
																<span className="text-base">
																	Preview
																</span>
															) : (
																""
															)}
															<span className="text-white">
																{subItem.duration ?? '0:00'}
															</span>
														</Link>
													</li>
												))}
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								))}
							</Accordion>
						</div>
					</div>
				</div>
		</>
	);
};

const CourseSummary = ({courseData, handleStartCourse}) => {
	return (
		<>
			<div className="course-summary">
				<img src={courseData ? (courseData.thumbnail ? courseData.thumbnail : '/global/placeholderImage.jpg') : '/global/placeholderImage.jpg'} alt="" />
				{/* line thorough price */}
				{courseData && (
					<>
					{courseData.price !== 0 && (
						<div className="price">
						<div className="previous text-decoration-line-through">
							{" "}
						</div>
						<div className="current">$199.9</div>
					</div>
					)}
					{/*{courseData.price === 0 && (*/}
					{/*	<div className="price">*/}
					{/*	<div className="previous text-decoration-line-through">*/}
					{/*		{" "}*/}
					{/*	</div>*/}
					{/*	<div className="current">FREE</div>*/}
					{/*</div>*/}
					{/*)}*/}
					</>
				)}
				<div className={`order-summary-content ${courseData ? (courseData.price === 0 ? 'mt-4' : '') : ''}`}>
					{courseData && (
						<>
						{courseData.price === 0 && (
							<button type="button" className="cmn-btn mb-20" onClick={() => handleStartCourse(courseData)}>
								Start Course
							</button>
						)}

						{(courseData.price ?? 0) !== 0 && (
							<>
							<button type="button" className="cmn-btn mb-20">
								Buy now
							</button>
							{/* <button type="button" className="cmn-btn btn-outline mb-20">
								Add to cart
							</button> */}
							<div className="fz-15 text-center mb-20">
								30-Day Money-Back Guarantee
							</div>
							</>
						)}
						</>
					)}
					{courseData && (
						<>
						<div className="subtitle">This Course Includes:</div>
						<ul className="includes">
							{courseData.contents.map((item, index) => (
								<li key={index}>
									<span>{item}</span>
								</li>
							))}
						</ul>
						</>
					)}
				</div>
			</div>
		</>
	);
};

const courseIncludes = [
	"54 hours on-demand video",
	"Assignments",
	"222 articles",
	"153 downloadable resources",
	"Access on mobile and TV",
	"Full lifetime access",
	"Certificate of completion",
];
const coursePreviewData = [
	{
		title: "Module 1 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
				preview: true,
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
				preview: true,
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
				preview: true,
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 2 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 3 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 4 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 5 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
];
export default CoursesSingle;
