import React from "react";
import InputCheckbox from "../../components/InputCheckbox";
import InputSwitch from "../../components/InputSwitch";
import SettingsLayout from "./components/SettingsLayout";

const Notifications = () => {
	return (
		<>
				<h5 className="setting-title">Notification preferences</h5>
				<p style={{ maxWidth: "591px" }}>
					Get emails to found out what is going on when you are not online.
					You can turn them off anytime.
				</p>
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Notifications from us</h6>
					<div className="notify-group-inner">
						<InputCheckbox value={false} title={"News and updates"} />
						<InputCheckbox value={false} title={"Tips and tutorials"} />
						<InputCheckbox value={false} title={"User research"} />
					</div>
				</div>
				<br />
				<div className="notify-group">
					<div className="mb-3 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Comments</h6>
						<InputSwitch value={false} />
					</div>
					<div className="notify-group-inner">
						<InputCheckbox value={false} title={"Do not notify me"} />
						<InputCheckbox value={false} title={"Mentions only"} />
						<InputCheckbox value={false} title={"All comments"} />
					</div>
				</div>
		</>
	);
};

export default Notifications;
