import {
    CommentsIcon, CommunityIcon,
    CommunityIcon2, CommunityIcon3,
    HeartFill,
    NextSlide,
    PrevSlide, RankBadge,
    ShareIcon,
    SignalIcon, VerifiedBadge
} from "../../../components/Icon";
import SignalCard from "../../../components/SignalCard";
import {Swiper, SwiperSlide} from "swiper/react";
import {Link} from "react-router-dom";
import avatar from "../../../assets/img/avatar.png";
import React, {useEffect, useState} from "react";
import trader1 from "../../../assets/img/trader1.png";
import {APIBaseURL, CurrentCompany} from "../../../util/DomainHelper";
import UserProvider from "../../../providers/UserProvider";
import NetworkingProvider from "../../../providers/NetworkingProvider";

const DashboardSidebar = () => {

    const [initialized, setInitialized] = React.useState(false);
    const [following, setFollowing] = React.useState(false);
    const [communities, setCommunities] = useState([]);
    const [traders, setTraders] = useState([]);
    const [tradeIdeas, setTradeIdeas] = useState([]);

    const [pinnedFeedPostItems, setPinnedFeedPostItems] = useState();
    const [feedPostItems, setFeedPostItems] = useState([]);
    const [paginationIndex, setPaginationIndex] = useState(0);

    const ref = React.useRef(null);
    const trendingRef = React.useRef(null);

    const goNext = () => {
        ref.current.swiper.slideNext();
    };

    const goPrev = () => {
        ref.current.swiper.slidePrev();
    };

    const goNextTrending = () => {
        trendingRef.current.swiper.slideNext();
    };

    const goPrevTrending = () => {
        trendingRef.current.swiper.slidePrev();
    };

    const fetchCommunities = async () => {
        const company = await CurrentCompany();
        const url = APIBaseURL() + `community/communities/${company._id}`;
        try {
            fetch(url, {
                method: 'GET',
                headers: {
                    'origin': '*',
                    'x-requested-with': '*',
                    'Content-Type': 'application/json'
                }
            }).then(async (response) => {
                if (response.ok) {
                    const responseJSON = await response.json();
                    const data = responseJSON.communities;
                    if (data !== undefined && data != null) {
                        setCommunities(data);
                    } else {
                        console.log('Failed to fetch data');
                        setCommunities([]);
                    }
                }
            })
        } catch(error) {
            console.log(error);
        }
    };

    async function fetchTraders() {
        const currentUser = await UserProvider.getCurrentUser()
        const currentCompany = await CurrentCompany(false)

        if (!currentUser || !currentCompany) {
            return
        }
        try {
            const payload = {
                userId: currentUser._id,
                companyId: currentCompany._id
            }
            const dataResponse = await NetworkingProvider.fetchData('traders/traders/0', 'POST', payload)
            const data = dataResponse.data;
            const message = dataResponse.message;
            if (data) {
                setTraders(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function fetchLatestSignals() {
        const currentUser = await UserProvider.getCurrentUser()
        const currentCompany = await CurrentCompany(false)

        if (!currentUser || !currentCompany) {
            return
        }

        try {
            const payload = {
                userId: currentUser._id
            }
            const dataResponse = await NetworkingProvider.fetchData(`trade-ideas/items/${currentCompany._id}`, 'POST', payload)
            // console.log(dataResponse)
            const data = dataResponse.data;
            const message = dataResponse.message;
            if (data) {
                setTradeIdeas(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function initializePage() {
        await fetchCommunities();
        await fetchLatestSignals()
        await fetchTraders();

        setInitialized(true)
    }

    useEffect(() => {
        initializePage()
    },[])

    return (
        <div className="sidebar-area">
            {/* Signal Widget */}
            { initialized && (
                <>
                    <div className="sidebar-widget">
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <h6 className="sidebar-widget-title mb-0">
                                <SignalIcon/> Trade Ideas
                            </h6>
                            <div className="d-flex gap-3">
                                <button className="no-gutter" onClick={goPrev}>
                                    <PrevSlide/>
                                </button>
                                <button className="no-gutter" onClick={goNext}>
                                    <NextSlide/>
                                </button>
                            </div>
                        </div>
                        {tradeIdeas && (
                            <Swiper
                                ref={ref}
                                slidesPerView={1}
                                spaceBetween={24}
                                loop={true}
                            >
                                {tradeIdeas.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <SignalCard props={item}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                        { tradeIdeas.length === 0 && (
                            <div className="row text-center justify-content-center align-content-center mt-3" style={{
                                height: '130px',
                                borderRadius: '12px',
                                borderColor: "gray",
                                backgroundColor: '#1e1e27',
                                margin: '2px',
                            }}>
                                <label>
                                    No Trade Ideas
                                </label>
                            </div>
                        )}
                    </div>
                    {/* Trending Widget */}
                    <div className="sidebar-widget">
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <h6 className="sidebar-widget-title mb-0">
                                <SignalIcon/> Trending
                            </h6>
                            <div className="d-flex gap-3">
                                <button className="no-gutter" onClick={goPrevTrending}>
                                    <PrevSlide/>
                                </button>
                                <button className="no-gutter" onClick={goNextTrending}>
                                <NextSlide/>
                                </button>
                            </div>
                        </div>
                        <Swiper
                            ref={trendingRef}
                            slidesPerView={1}
                            spaceBetween={24}
                            loop={true}
                        >
                            {["", "", ""].map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className="sidebar-widget-body x-post">
                                        <Link to="#" className="d-block">
                                            <div className="media __media mb-2">
                                                <img src={avatar} alt=""/>
                                                <div className="media-body">
                                                    <h6 className="text-16 font-semibold m-0 mb-1">
                                                        Jane Cooper
                                                    </h6>
                                                    <small>@copper</small>
                                                </div>
                                            </div>
                                            <div className="txt2 mb-3">
                                                National Trade: This refers to trade that
                                                occurs within the borders of a single country.
                                                It involves the exchange of goods and services
                                                between regions or states within a nation.
                                                International Trade: This is the exchange of
                                                goods and services between different countries.
                                                International trade can take the form of
                                                imports (goods and services coming into a
                                                country) and exports (goods and services
                                                leaving a country). It plays a crucial role in
                                                the global economy.
                                            </div>
                                        </Link>
                                        <div className="d-flex flex-wrap gap-2 post-icons">
                                            <button type="button" className="no-gutter">
                                                <HeartFill/> <span>24k+</span>
                                            </button>
                                            <button type="button" className="no-gutter">
                                                <CommentsIcon/> <span>13</span>
                                            </button>
                                            <button
                                                type="button"
                                                className="no-gutter ms-auto"
                                            >
                                                <ShareIcon/>
                                            </button>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    {/* Signal Widget */}
                    <div className="sidebar-widget">
                        <h6 className="sidebar-widget-title">
                            <CommunityIcon2/> Community
                        </h6>
                        <div className="d-flex flex-wrap gap-2">
                            {communities && communities?.map((item, index) => (
                                <Link
                                    to="#"
                                    className="community-sidebar-item"
                                    key={index}
                                >
                                    <div className="community-thumb">
                                        <img src={item.thumbnailPreview} alt=""/>
                                    </div>
                                    <div className="community-content">
                                        <h6 className="community-title">{item.title}</h6>
                                        <small className="community-view">
                                            <CommunityIcon/> Views
                                        </small>
                                    </div>
                                </Link>
                            ))}
                            { communities.length === 0 && (
                                <div className="row text-center justify-content-center align-content-center mt-3"
                                     style={{
                                         width: "100%",
                                         height: '130px',
                                         borderRadius: '12px',
                                         borderColor: "gray",
                                         backgroundColor: '#1e1e27',
                                         margin: '2px',
                                     }}>
                                    <label>
                                        No Communities
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Signal Widget */}
                    <div className="sidebar-widget">
                        <h6 className="sidebar-widget-title">
                            <CommunityIcon3/> Meet Our Traders
                        </h6>
                        <div className="d-flex flex-column gap-2">
                            {traders.map((item, index) => (
                                <Link
                                    to={`/profile/${item._id}/about`}
                                    className="community-sidebar-item community-sidebar-item-2"
                                    key={index}
                                >
                                    <div className="community-thumb">
                                        <img src={item.photo} alt=""/>
                                    </div>
                                    <div className="community-content">
                                        <h6 className="community-title mb-1">
                                            {item.firstName} {item.lastName}
                                        </h6>
                                        <small className="community-view">
                                            Signal Room Pro Trader
                                        </small>
                                        <div className="d-flex gap-1">
                                            <VerifiedBadge/>
                                            <RankBadge/>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                            { traders.length === 0 && (
                                <div className="row text-center justify-content-center align-content-center mt-3"
                                     style={{
                                         width: "100%",
                                         height: '100px',
                                         borderRadius: '12px',
                                         borderColor: "gray",
                                         backgroundColor: '#1e1e27',
                                         margin: '2px',
                                     }}>
                                    <label>
                                        No Traders
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    )
};

const _traders = [
    {
        title: "HowardTradez",
        img: trader1,
    },
    {
        title: "HowardTradez",
        img: trader1,
    },
];
export default DashboardSidebar;
