import React, {useEffect, useState} from "react";
import {Link, NavLink, useNavigate, useOutletContext} from "react-router-dom";
import logoimg from "../assets/img/logo.png";
import ConfigureSignal from "./ConfigureSignal";
import {CurrentCompanyConfiguration} from "../util/CompanyHelper";
const CoursePlanLayout = ({ children }) => {

	const currentCurriculums = useOutletContext();
	const navigate = useNavigate();

	const [companyConfiguration, setCompanyConfiguration] = useState();

	const fetchCompanyConfiguration = async () => {
		const fetchedCompanyConfig = await CurrentCompanyConfiguration();
		if (fetchedCompanyConfig) {
			setCompanyConfiguration(fetchedCompanyConfig);
		}
	};

	useEffect(() => {
		fetchCompanyConfiguration()
	},[]);

	// console.log('currentCurriculums:', currentCurriculums);

	return (
		<>
			<header className="setting-notification-header course-plan-layout-header">
				<Link to="/" className={`d-inline-block`}>
					<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logoimg) : logoimg} style={{ width: "140px" }} alt="logo" />
				</Link>
				<button
					type="button"
					className="btn btn--secondary text-20 font-semibold"
				>
					Draft
				</button>
				<div className="text-20 d-none d-sm-block font-semibold">
					New Course Creator
				</div>
				<button
					onClick={() => navigate('/courses')}
					type="button"
					className="btn btn--secondary min-w-185 text-20 font-semibold ms-auto"
				>
					Exit
				</button>
			</header>
			<div className="container container-xxl-fluid">
				<main className="course-plan-layout">
					<aside>
						<div className="aside-menu">
							<h5 className="subtitle">Plan your course</h5>
							<NavLink to="/course-plan/intended-learners">
								Intended learners
							</NavLink>
							<h5 className="subtitle">Create your content</h5>
							<NavLink to="/course-plan/curriculum">Curriculum</NavLink>
							{ currentCurriculums.length > 0 ? (
								<>
									<h5 className="subtitle">Your Modules</h5>
									{(currentCurriculums ?? []).length > 0 && (currentCurriculums ?? []).map((curriculum) => (
										<NavLink
											to={`/course-plan/modules/${curriculum.index}`}>{curriculum ? curriculum.title : ''}</NavLink>
									))}
								</>
							) : null}
							<h5 className="subtitle">Publish your course</h5>
							<NavLink to="/course-plan/landing">
								Course overview
							</NavLink>
							<NavLink to="/course-plan/pricing">Pricing</NavLink>
							<NavLink to="/course-plan/course-message">
								Course messages
							</NavLink>

						</div>
					</aside>
					<article>
						{/*<ConfigureSignal show={open} onHide={() => setOpen(false)} />*/}
						{children}
					</article>
				</main>
			</div>
		</>
	);
};

export default CoursePlanLayout;
