import React, {useEffect, useRef, useState} from "react";
import { Collapse } from "react-bootstrap";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import { AngleDown } from "../../components/Icon";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import Input from "../../components/Input";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

const Curriculum2 = ({ onTitleChange }) => {

	const { updateCourseDetails,
		curriculums,
		insertCurriculum,
		removeCurriculum,
		fetchCourse, courseDetails, updateLessonData } = useCreateCourse();

	const params = useParams();
	const curriculumIndex = params.curriculumIndex;
	const navigate = useNavigate();

	const fileSelectorRef = useRef(null);
	const videoSelectorRef = useRef(null);

	const [currentCourseDetails, setCurrentCourseDetails] = useState();
	const [count, setCount] = useState(0);
	const [lessons, setLessons] = React.useState(data);

	const [currentFileSelectionData, setCurrentFileSelectionData] = React.useState({ parentIndex: undefined, childIndex: undefined });
	const [currentVideoSelectionData, setCurrentVideoSelectionData] = React.useState({ parentIndex: undefined });

	const [pageData, setPageData] = React.useState({ lessons: []});

	function handleLessonDataChange(parentIndex, childIndex, value, target) {

		let parsedPageData = pageData;
		parsedPageData.lessons = lessons;

		switch(target) {
			case 'title': {
				parsedPageData.lessons[parentIndex].title = value
				setPageData(parsedPageData)
				break
			}
			case 'video': {
				parsedPageData.lessons[parentIndex].video = value
				setPageData(parsedPageData)
				break
			}
			case 'resource_file': {
				parsedPageData.lessons[parentIndex].resources[childIndex].file = value
				setPageData(parsedPageData)
				break
			}
			case 'resource_title': {
				parsedPageData.lessons[parentIndex].resources[childIndex].title = value
				setPageData(parsedPageData)
				break
			}
		}

		updateLessonData(parsedPageData)

	}

	function handleOpenFileSelectorForResource(parentIndex, childIndex) {
		const currentSelectionData = { parentIndex: parentIndex, childIndex: childIndex };
		setCurrentFileSelectionData(currentSelectionData)
		if (fileSelectorRef.current) {
			fileSelectorRef.current.value = null
			fileSelectorRef.current.click()
		}
	}

	function handleOpenFileSelectorForVideo(parentIndex) {
		const currentSelectionData = { parentIndex: parentIndex };
		setCurrentVideoSelectionData(currentSelectionData)
		if (videoSelectorRef.current) {
			videoSelectorRef.current.value = null
			videoSelectorRef.current.click()
		}
	}

	function handleFileSelection(e) {
        const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (currentFileSelectionData.parentIndex !== undefined && currentFileSelectionData.childIndex !== undefined) {
				handleLessonDataChange(currentFileSelectionData.parentIndex, currentFileSelectionData.childIndex, file, 'resource_file');
			}
		}
	}

	function handleVideoFileSelection(e) {
		const files = e.target.files;
		if (files && files.length > 0) {
			const file = files[0];
			if (!file.type.includes("video")) {
				toast.error('You can only upload a video')
				return console.log("not a video and don't submit, just return")
			}else{
				toast.success('Video Added Successfully')
				if (currentVideoSelectionData.parentIndex !== undefined) {
					handleLessonDataChange(currentVideoSelectionData.parentIndex, undefined, file, 'video');
				}
			}
		}
	}

	function handleNewResource(resources, parentIndex) {
		let parsedPageData = pageData;
		parsedPageData.lessons = lessons;
		parsedPageData.lessons[parentIndex].resources = resources;
		setLessons(parsedPageData.lessons)
		setPageData(parsedPageData)

	}

	function handleNextTriggered() {
		const currentModuleIndex = parseInt(params.curriculumIndex)
		const curriculumItems = curriculums()
		if ((curriculumItems.length - 1) >= currentModuleIndex + 1) {
			navigate(`/course-plan/modules/${curriculumItems[currentModuleIndex + 1].index}`)
		} else {
			navigate(`/course-plan/landing`)
		}
	}

	function handleBackTriggered() {
		const currentModuleIndex = parseInt(params.curriculumIndex)
		const curriculumItems = curriculums()
		if (currentModuleIndex - 1 !== -1) {
			navigate(`/course-plan/modules/${curriculumItems[currentModuleIndex - 1].index}`)
		} else {
			navigate(`/course-plan/curriculum`)
		}
	}

	useEffect(() => {
		// setLessons([])
		// setCurrentCourseDetails({})
		// console.log('Refetching')

		const fetchedCourseDetails = courseDetails()
		const currentIndex = parseInt(params.curriculumIndex);
		const currentLessons = fetchedCourseDetails.modules[currentIndex].lessons
		setLessons(currentLessons)
		setCurrentCourseDetails(fetchedCourseDetails)
		setCount(count + 1)
	},[curriculumIndex])

	return (
		<CoursePlanLayout>
			{ currentCourseDetails && count && (
				<>
					<input type='file' multiple={false} ref={fileSelectorRef} id='file-selector'
						   onChange={(e) => handleFileSelection(e)}
						   style={{display: 'none'}}/>
					<input type='file' multiple={false} accept="video/*" ref={videoSelectorRef} id='video-file-selector'
						   onChange={(e) => handleVideoFileSelection(e)}
						   style={{display: 'none'}}/>
					<div style={{maxWidth: "841px"}}>
						<h5 className="title">Course Lesson Setup</h5>
						<p className="txt pb-md-3">
							Start putting together your course by creating sections, lectures
							and practice (quizzes, coding exercises and assignments).
						</p>
					</div>
					<hr
						className="pb-md-3"
						style={{borderWidth: "2px", opacity: "1", borderColor: "#88869F"}}
					/>
					{lessons && (lessons)?.map((item, index) => {
						return <CurriculamItem key={index} data={item} resourceData={item?.resources} index={index}
											   handleLessonDataChange={handleLessonDataChange}
											   handleOpenFileSelectorForResource={handleOpenFileSelectorForResource}
											   handleOpenFileSelectorForVideo={handleOpenFileSelectorForVideo}
											   handleNewResource={handleNewResource}
						/>;
					})}
					<div>
						<button
							className="btn --badge btn--secondary btn-- font-semibold btn-2"
							type="button"
							onClick={() => {
								setLessons([
									...(lessons ?? []),
									{
										index: (lessons ?? []).length,
										title: '',
										resources: [],
										timestamp: new Date().getTime(),
										video: ''
									},
								]);
							}}
						>
							+ New Lesson item
						</button>
					</div>
					<div className="d-flex w-100 mt-md-3 gap-4">
						<button
							className="cmn-btn btn-outline px-4 mt-3"
							onClick={() => handleBackTriggered()}
						>
							Back
						</button>
						<button
							className="cmn-btn px-4 mt-3"
							onClick={() => handleNextTriggered()}
						>
							Save & Continue
						</button>
					</div>
				</>
			)}
		</CoursePlanLayout>
	);
};

const CurriculamItem = ({
							resourceData,
							data,
							index,
							handleLessonDataChange,
							handleOpenFileSelectorForResource,
							handleOpenFileSelectorForVideo,
							handleNewResource
						}) => {

	const [title, setTitle] = React.useState(data ? data.title : '');
	const [count, setCount] = React.useState(0);
	const [subCurriculam, setSubCurriculam] = React.useState(resourceData ?? []);

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
		handleLessonDataChange(index, undefined, e.target.value, 'title');
	}

	function triggerVideoSelector() {
		handleOpenFileSelectorForVideo(index)
	}

	function handleNewResourceInput() {
		const newValue = [...subCurriculam, {index: subCurriculam.length, title: '', file: null}]
		setSubCurriculam(newValue);
		handleNewResource(newValue, index)
	}

	useEffect(() => {
		setCount(count + 1)
		console.log('Updating...')
		setTitle(data.title)
		setSubCurriculam(resourceData ?? [])
	},[data, resourceData])

	return (
		<>
			<div className="curriculam-new-section mb-4">
				<h6 className="subtitle font-semibold pt-3">
					Lesson {index + 1} :{" "}
					<small className="ms-2 font-regular">{title ? title : 'Enter a title'}</small>
				</h6>
				<div className="example-inputs">
					<Input
						defaultValue={title ? title : ''}
						value={title ? title : ''}
						onChange={(e) => handleTitleChange(e, index, 'title')}
						placeholder="Enter a Title"
						endText={80}
					/>
				</div>
				<div className="upload-border-item">
					<h6 className="subtitle text-15 m-0">
						Video :
						<small className="ms-2 font-regular">Click to choose video</small>
					</h6>
					<div className="d-flex align-items-center gap-3">
						<label
							onClick={() => triggerVideoSelector()}
							className="btn --badge btn--secondary btn-- font-semibold btn-2 border-0 py-1 m-0 bg-whiter"
							style={{
								borderRadius: "5px",
							}}
						>
							+ Upload Video
						</label>
					</div>
				</div>

				<div>
					{subCurriculam.map((item, childIndex) => {
						return (
							<CurriculamSubItem handleLessonDataChange={handleLessonDataChange}
											   handleOpenFileSelectorForResource={handleOpenFileSelectorForResource}
											   parentIndex={index} childIndex={childIndex} data={item}/>
						);
					})}

				</div>
				<button
					className="btn --badge btn--secondary btn-- font-semibold btn-2"
					type="button"
					onClick={() => handleNewResourceInput()}
				>
					+ New Resource item
				</button>
			</div>
		</>
	);
};

const CurriculamSubItem = ({
							   handleLessonDataChange,
							   handleOpenFileSelectorForResource,
							   parentIndex,
							   childIndex,
							   data
						   }) => {

	const [open, setOpen] = React.useState(false);

	const [title, setTitle] = React.useState(data.title);

	const handleTitleChange = (e) => {
		setTitle(e.target.value)
		handleLessonDataChange(parentIndex, childIndex, e.target.value, 'resource_title');
	}

	const handleMultimediaSelected = (e) => {
		// setTitle(e.target.value)
		handleLessonDataChange(parentIndex, childIndex, e.target.value, 'title');
	}

	function handleSelectFile() {
		handleOpenFileSelectorForResource(parentIndex, childIndex);
	}

	useEffect(() => {
		setTitle(data.title)
	}, [data])

	return (
		<>
			<div className="upload-border-item" onClick={() => setOpen(!open)}
				 style={{maxHeight: '47px', height: '47px'}}>
				<h6 className="subtitle text-15 m-0">
					Resource {childIndex + 1} : {" "}
					<small className="ms-2 font-regular">{title ? title : 'Click to expand'}</small>
				</h6>
				<div className="d-flex align-items-center gap-3">
					<AngleDown/>
				</div>
			</div>
			<Collapse in={open}>
				<div style={{paddingLeft: '47px'}}>
					<div className="example-inputs">
						<Input
							defaultValue={title ? title : 'Enter a title'}
							onChange={(e) => handleTitleChange(e)}
							placeholder="Resource title"
							endText={80}
						/>
					</div>
					<div className="upload-border-item py-2">
						<div className="d-flex flex-wrap justify-content-end gap-2 w-100">
							<button
								onClick={() => handleSelectFile()}
								type="button"
								className="btn --badge btn--secondary btn-- font-semibold btn-2 border-0 p-3 m-0 bg-whiter"
							>
								Upload File
							</button>
						</div>
					</div>
				</div>
			</Collapse>
		</>
	);
};

const data = [
	{
		index: 0,
		title: '',
		video: null,
		timestamp: new Date().getTime(),
		resources: [

		],
	},
];

export default Curriculum2;
