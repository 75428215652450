export const CustomerRegistrationHelper = {
    // Function to save registration data to local storage
    saveRegistrationData: (data, field) => {

        let preData;

        const presetJsonData = localStorage.getItem('registrationData');
        if (presetJsonData) {
            const presetData = JSON.parse(presetJsonData);
            if (!preData) {
                preData = presetData;
            };
            preData[field] = data;
        } else {
            preData = {};
            preData[field] = data;
        };

        try {
            // Convert data to JSON string
            const jsonData = JSON.stringify(preData);
            // Store data in local storage under 'registrationData' key
            localStorage.setItem('registrationData', jsonData);
            return true; // Successfully saved
        } catch (error) {
            console.error('Error saving registration data:', error);
            return false; // Failed to save
        }
    },

    // Function to retrieve registration data from local storage
    getRegistrationData: () => {
        try {
            // Retrieve data from local storage
            const jsonData = localStorage.getItem('registrationData');
            // Parse JSON data
            const data = JSON.parse(jsonData);
            return data; // Return parsed data
        } catch (error) {
            console.error('Error retrieving registration data:', error);
            return null; // Return null if retrieval fails
        }
    },

    // Function to clear registration data from local storage
    clearRegistrationData: () => {
        try {
            // Remove data from local storage
            localStorage.removeItem('registrationData');
            return true; // Successfully cleared
        } catch (error) {
            console.error('Error clearing registration data:', error);
            return false; // Failed to clear
        }
    }
};