const  UserProvider = {

    storeUser(data) {
        localStorage.setItem('user', JSON.stringify(data));
    },

    _getCurrentUser() {
        return {
            _id: "65c64593f6ca3d9dd3afbbeb",
            email: "johndoe@younivision.com",
            name: "John Doe",
        }
    },

    getCurrentUser() {
        if (localStorage.getItem('user')) {
            return JSON.parse(localStorage.getItem('user'));
        }
    }

};

export default UserProvider;
