import React from "react";
import { useNavigate } from "react-router-dom";
import { SuccessIcon } from "../../components/Icon";
import CustomerAuthLayout from "./components/CustomerAuthLayout";

const CustomerSignUpSuccess = () => {
	const navigate = useNavigate();
	return (
		<>
			<CustomerAuthLayout altLoginText>
				<div className="row g-4"></div>
				<br />
				<br className="d-none d-md-block" />
				<br className="d-none d-md-block" />
				<div className="text-center">
					<div className="mb-4 mb-md-5">
						<SuccessIcon />
					</div>
					<h2 className="title mb-3 pb-1">
						Sign Up <span className="subtitle">Complete</span>
					</h2>
					<div className="text">
						Join an online community of educators and start educating.
					</div>
					<br />
					<div className="mt-4 mt-sm-5">
						<button
							type="submit"
							className="btn w-100 cmn-btn base h-69"
							onClick={() => navigate("/customer-signin")}
						>
							Sign In
						</button>
					</div>
				</div>
			</CustomerAuthLayout>
		</>
	);
};

export default CustomerSignUpSuccess;
