import React from "react";
import InputSwitch from "../../components/InputSwitch";
import SettingsLayout from "./components/SettingsLayout";

const FriendsRequests = () => {
	return (
		<>
				<h5 className="setting-title">Friends Requests</h5>
				<br />
				<p>Who can send you a friend request</p>
				<br />
				<div className="notify-group">
					<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Everyone</h6>
						<InputSwitch value={true} />
					</div>
					<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Friends of Friends</h6>
						<InputSwitch value={true} />
					</div>
					<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Server Members</h6>
						<InputSwitch value={true} />
					</div>
				</div>
		</>
	);
};

export default FriendsRequests;
