import EmojiPicker from "emoji-picker-react";
import React, { useRef, useState } from "react";
import { EmojiIcon, GifIcon, PlusIcon2, StickerIcon } from "./TradingIcons";

const CommentForm = ({ handleSend, hidePadding, placeholder }) => {

	const [open, setOpen] = useState(false);
	const textboxRef = useRef;
	const [messageContent, setMessageContent] = useState('');

	const handleSubmit = (e) => {
		// e.preventDefault();
		handleSend(messageContent);

	};

	const handleMessageTyped = (e) => {
		let value = e.target.value;
		setMessageContent(value);
	};

	return (
		<>
			<div className={`${hidePadding ? 'pb-0' : 'pb-4'} ${hidePadding ? '' : 'px-4'} mb-2`}>
				<form onSubmit={handleSubmit} className="comments-form">
					<label className="add-file-btn">
						<PlusIcon2 />
						<input type="file" hidden />
					</label>
					<input
					onChange={(e) => handleMessageTyped(e)}
						type="text"
						className="form-control form-control-2"
						placeholder={placeholder ? placeholder : "Type your message here..."}
					/>
					<button onClick={handleSubmit} className="sticker-icon no-gutter" type="button">
						<StickerIcon />
					</button>
					<button className="gif-icon no-gutter" type="button">
						<GifIcon />
					</button>
					<button
						className="emoji-icon no-gutter"
						onFocus={() => setOpen(true)}
						onBlur={() => setOpen(false)}
						type="button"
					>
						<EmojiIcon />
						<div
							className={`emoji--picker-12 ${
								open ? "emoji-picker--open" : ""
							}`}
							style={{ position: "absolute", right: "0", bottom: "0" }}
						>
							<EmojiPicker />
						</div>
					</button>
				</form>
			</div>
		</>
	);
};

export default CommentForm;
