import React, {useEffect, useState} from "react";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import Input from "../../components/Input";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import {useNavigate} from "react-router-dom";

const CoursePricing = () => {

	const { updateCourseDetails, courseDetails, insertCurriculum, curriculums } = useCreateCourse();

	const [currentCourseDetails, setCurrentCourseDetails] = useState();

	const navigate = useNavigate()

    const priceList = [];

    for (let i = 1; i <= 500; i += 0.50) {
        const price = parseFloat(i.toFixed(2));
        const priceObject = { name: `$${price.toFixed(2)}`, value: price };
        priceList.push(priceObject);
    }

	function handleNextTriggered() {
		navigate(`/course-plan/course-message`)
	}

	useEffect(() => {
		const fetchedCourseDetails = courseDetails()
		console.log('fetchedCourseDetails', fetchedCourseDetails)
		setCurrentCourseDetails(fetchedCourseDetails)
	},[])

	return (
		<CoursePlanLayout>
			{ currentCourseDetails && (
				<>
					<div style={{maxWidth: "841px"}}>
						<h5 className="title">Pricing</h5>
					</div>
					<hr
						className="pb-md-3"
						style={{borderWidth: "2px", opacity: "1", borderColor: "#88869F"}}
					/>

					<h6 className="subtitle pb-2 font-semibold">
						Set a price for your course
					</h6>
					<div className="price-wrapper d-flex flex-wrap">
						<div className="item">
							<h6 className="subtitle">Base Currency</h6>
							<div className="example-inputs">
								<Input
									defaultValue={currentCourseDetails ? currentCourseDetails.course_pricing_currency : ''}
									onChange={(e) => updateCourseDetails({ course_pricing_currency: e })}
									options={[
										{name: "Choose One", value: ""},
										{name: "USD - $", value: "USD"},
										{name: "EUR - €", value: "EUR"},
										{name: "JPY - ¥", value: "JPY"},
										{name: "GBP - £", value: "GBP"},
										{name: "AUD - A$", value: "AUD"},
										{name: "CAD - CA$", value: "CAD"},
										{name: "CHF - CHF", value: "CHF"},
										{name: "CNY - ¥", value: "CNY"},
										{name: "SEK - kr", value: "SEK"},
										{name: "NZD - NZ$", value: "NZD"},
										{name: "MXN - Mex$", value: "MXN"},
										{name: "SGD - S$", value: "SGD"},
										{name: "HKD - HK$", value: "HKD"},
										{name: "NOK - kr", value: "NOK"},
										{name: "KRW - ₩", value: "KRW"},
										{name: "TRY - ₺", value: "TRY"},
										{name: "RUB - ₽", value: "RUB"},
										{name: "INR - ₹", value: "INR"},
										{name: "BRL - R$", value: "BRL"},
										{name: "ZAR - R", value: "ZAR"},
									]}
									height={"47px"}
								/>
							</div>
						</div>
						<div className="item">
							<h6 className="subtitle">Price Selection</h6>
							<div className="example-inputs">
								<Input
									defaultValue={currentCourseDetails ? currentCourseDetails.course_pricing_cost : ''}
									onChange={(e) => updateCourseDetails({course_pricing_cost: e})}
									options={[...[{name: 'Select one', value: ''}, {
										name: 'Free',
										value: 'free'
									}], ...priceList]}
									height={"47px"}
								/>
							</div>
						</div>
					</div>
					<div className="d-flex w-100 mt-md-3 gap-4">
						<button
							className="cmn-btn btn-outline px-4 mt-3"
							onClick={() => navigate('/course-plan/landing')}
						>
							Back
						</button>
						<button
							className="cmn-btn px-4 mt-3"
							onClick={() => handleNextTriggered()}
						>
							Save & Continue
						</button>
					</div>
				</>
			)}
		</CoursePlanLayout>
	);
};

export default CoursePricing;
