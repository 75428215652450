import React from "react";
import { Link } from "react-router-dom";
import InputSwitch from "../../components/InputSwitch";
import SettingsLayout from "./components/SettingsLayout";

const PrivacySafety = () => {
	return (
		<>
				<div className="d-flex gap-3 align-items-center justify-content-between">
					<h5 className="setting-title m-0">Privacy & Safety</h5>
				</div>
				<br />
				<p style={{ maxWidth: "591px" }}>
					Find Lession stock images in HD and millions of other
					royalty-free stock photos, 3D objects, illustrations and vectors
					in the Shutterstock collection
				</p>
				<br />
				<div className="notify-group">
					<div className="mb-3 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Server Safety</h6>
					</div>
					<div className="notify-group-inner">
						<div className="d-flex align-items-center justify-content-between mb-4">
							<strong>Allow direct messages from server members</strong>
							<InputSwitch value={false} />
						</div>
						<div className="d-flex align-items-center justify-content-between mb-4">
							<strong>
								Enable message requests from server members you may not
								know
							</strong>
							<InputSwitch value={true} />
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<strong>Allow access to age-restricted content</strong>
							<InputSwitch value={true} />
						</div>
					</div>
				</div>
				<br />
				<br />
				<div className="notify-group">
					<div className="mb-3 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">How We Use Your Data</h6>
					</div>
					<div className="notify-group-inner">
						<div className="d-flex align-items-center justify-content-between mb-4">
							<strong>Use data to improve my experience</strong>
							<InputSwitch value={true} />
						</div>
						<div className="d-flex align-items-center justify-content-between mb-4">
							<strong>Use data to make Tradessly work</strong>
							<InputSwitch value={true} />
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<strong>Use data to improve Tradessly</strong>
							<InputSwitch value={true} />
						</div>
					</div>
				</div>
				<br />
				<br />
				<div className="notify-group">
					<div className="mb-4 d-flex justify-content-between align-items-center gap-2">
						<h6 className="subtitle m-0">Request all of my data</h6>
					</div>
					<div>
						<Link to="#" className="btn cmn-btn btn-outline border">
							Request Data
						</Link>
					</div>
					<br />
					<br />
					<div
						className="rounded"
						style={{
							border: `1px solid #88869F`,
							padding: "13px 15px",
							fontSize: "10px",
						}}
					>
						Check out our{" "}
						<Link className="text-009FD9" to="">
							Terms of Service
						</Link>{" "}
						and{" "}
						<Link className="text-009FD9" to="">
							Privacy Policy
						</Link>
					</div>
				</div>
		</>
	);
};

export default PrivacySafety;
