import React from "react";
import { Link } from "react-router-dom";
import bg from "../../assets/img/videos/video1.png";
import img1 from "../../assets/img/videos/video2.png";
import img2 from "../../assets/img/videos/video3.png";
import img3 from "../../assets/img/videos/video4.png";
import { VideoIcon } from "../../components/Icon";
import ProfileLayout from "./components/ProfileLayout";
import RecentTrade from "./components/RecentTrade";

const ProfileVideos = () => {
	return (
		<>
			<section
					className="banner-section"
					style={{
						background: `url(${bg}) no-repeat center center / cover`,
					}}
				>
					<div className="banner-item" style={{ paddingBlock: "57px" }}>
						<div className="banner-content banner-content-2">
							<h2 className="banner-title">Trading Deconstructed</h2>
							<p
								className="banner-text mb-4"
								style={{ maxWidth: "499px", fontSize: "16px" }}
							>
								Trading is one of the most effective and important ways
								of building a powerhouse squad in FIFA, and this
								Deconstructed Show from and Metafy will give you all the
								tips and tricks you need to.
							</p>
							<Link to="#" className="cmn-btn btn-black">
								<VideoIcon /> Start watching
							</Link>
						</div>
						<div className="banner-right d-flex flex-column gap-4 gap-xl-30">
							{nextdata?.map((item, index) => (
								<Link
									to={"#"}
									className="banner-video-card"
									key={index}
									style={{
										background: `url(${item.img}) no-repeat center center / cover`,
									}}
								>
									<div className="serial">0{index}</div>
									<div className="badge bg-white">Up next</div>
									<div className="name">
										{"0" + index}. {item.title}
									</div>
								</Link>
							))}
						</div>
					</div>
				</section>
				<br />
				<br />
				<div className="d-flex flex-wrap align-items-center justify-content-between">
					<h6 className="m-0 article-section-title">You may also like</h6>
					<Link className="view-all" to="#">
						view all
					</Link>
				</div>
				<br className="d-none d-md-block" />
				<br />
				<RecentTrade />
		</>
	);
};

const nextdata = [
	{
		img: img1,
		title: "Welcome to Trading",
	},
	{
		img: img2,
		title: "The basic of Trading",
	},
	{
		img: img3,
		title: "Usefully resource",
	},
];

export default ProfileVideos;
