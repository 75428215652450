import React from "react";

const NotFound = () => {
	return (
		<>
        <h1> Company Not Found</h1>
        </>
	);
};

export default NotFound;
