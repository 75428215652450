import React, {useEffect, useState} from "react";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import Input from "../../components/Input";
import {useNavigate} from "react-router-dom";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import data from "bootstrap/js/src/dom/data";

const defaultValues = [
	{
		index: 0,
		title: '',
		description: '',
	},
];

const Curriculam = () => {

	const { updateCourseDetails, courseDetails, insertCurriculum, curriculums } = useCreateCourse();

	const [currentCurriculum, setCurrentCurriculam] = React.useState(courseDetails() ? courseDetails().modules : defaultValues);

	const navigate = useNavigate();

	const [currentCourseDetails, setCurrentCourseDetails] = useState();

	function handleCurriculumContentChange(e, index, target) {
		let allContent = currentCurriculum
		let parsedContent = allContent[index];
		if (target === 'title') {
			parsedContent.title = e.target.value;
		}
		if (target === 'description') {
			parsedContent.description = e.target.value;
		}
		allContent[index] = parsedContent
		setCurrentCurriculam(allContent)
		insertCurriculum(allContent)
	}

	function handleNextTriggered() {
		const curriculumItems = curriculums()
		if (curriculumItems.length > 0) {
			// console.
			navigate(`/course-plan/modules/${curriculumItems[0].index}`)
		}
	}

	useEffect(() => {
		const fetchedCourseDetails = courseDetails()
		console.log('fetchedCourseDetails', fetchedCourseDetails)
		setCurrentCurriculam(fetchedCourseDetails.modules ?? defaultValues)
		setCurrentCourseDetails(fetchedCourseDetails)
	},[])

	return (
		<CoursePlanLayout>
			<div style={{maxWidth: "841px"}}>
				<h5 className="title">Curriculum</h5>
				<p className="txt pb-md-3">
					Start putting together your course by creating modules, lessons
					and videos (quizzes, coding exercises and assignments).
				</p>
			</div>
			<hr
				className="pb-md-3"
				style={{borderWidth: "2px", opacity: "1", borderColor: "#88869F"}}
			/>
			{(currentCurriculum ? currentCurriculum : [])?.map((item, index) => {
				return <CurriculamItem handleSectionChange={handleCurriculumContentChange} index={index} key={item.id} data={item}/>;
			})}
			<div>
				<button
					className="btn --badge btn--secondary btn-- font-semibold btn-2"
					type="button"
					onClick={() => {
						setCurrentCurriculam([...(currentCurriculum ?? []), {index: (currentCurriculum ?? []).length, title: '', description: ''}]);
					}}
				>
					+ New Module item
				</button>
			</div>

			<div className="d-flex w-100 mt-md-3 gap-4">
				<button
					className="cmn-btn btn-outline px-4 mt-3"
					onClick={() => navigate('/course-plan/intended-learners')}
				>
					Back
				</button>
				<button
					className="cmn-btn px-4 mt-3"
					onClick={() => handleNextTriggered()}
				>
					Save & Continue
				</button>
			</div>
		</CoursePlanLayout>
	);
};

const CurriculamItem = ({ index, handleSectionChange, data }) => {

	return (
		<>
			<div className="curriculam-new-section mb-4">
				<div className="d-flex flex-wrap">
					<h6 className="subtitle font-semibold pt-3">New Module:</h6>
					<div className="w-100 w-sm-0 flex-grow-1 ps-sm-3">
						<div className="example-inputs">
							<Input
								defaultValue={data ? data.title : ''}
								onChange={(e) => handleSectionChange(e, index, 'title')}
								placeholder="Enter a Title"
								endText={80}
							/>
						</div>
						<h6 className="subtitle font-semibold pt-3">
							Enter a brief description for this section
						</h6>
						<div className="example-inputs">
							<Input
								defaultValue={data ? data.description : ''}
								onChange={(e) => handleSectionChange(e, index, 'description')}
								placeholder="Section description (Optional)"
								endText={200}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};



export default Curriculam;
