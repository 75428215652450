
import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import {Outlet, useParams} from "react-router-dom";

// Create a context to hold the authentication state and methods
const CustomerRegistrationContext = createContext(undefined);

export const useCustomerRegistration = () => useContext(CustomerRegistrationContext);

const defaultValues = {}

const CustomerRegistrationProvider = () => {

    const params = useParams();

    const [currentRegistrationDetails, setCurrentRegistrationDetails] = useState(defaultValues);

    const updateRegistrationDetails = (updates) => {
        const keys = Object.keys(updates);
        const key = keys[0];
        const value = updates[key];

        let parsedRegistrationDetails = currentRegistrationDetails;
        parsedRegistrationDetails[key] = value
        console.log(parsedRegistrationDetails);
        setCurrentRegistrationDetails(parsedRegistrationDetails);
        localStorage.setItem('customer_registrationDetails', JSON.stringify(parsedRegistrationDetails));
    };

    const registrationDetails = () => {
        const storedRegistrationDetails = localStorage.getItem('customer_registrationDetails');
        if (storedRegistrationDetails) {
            return JSON.parse(storedRegistrationDetails);
        }
        return currentRegistrationDetails
    }

    const initialize = () => {
        if (Object.keys(currentRegistrationDetails).length === 0) {
            const storedRegistrationDetails = localStorage.getItem('customer_registrationDetails');
            if (storedRegistrationDetails) {
                setCurrentRegistrationDetails(JSON.parse(storedRegistrationDetails));
            }
        }
    };

    const updateLessonData = (lessonData) => {
        const currentModuleIndex = parseInt(params.curriculumIndex)
        console.log('New Lesson Data', lessonData, 'At Index: ' + currentModuleIndex);
        // const lessons = lessonData.lessons;
        // let parsedLessonData = currentCourseDetails.modules ?? [];
        // parsedLessonData[currentModuleIndex].lessons = lessons;
        // updateCourseDetails({ modules: parsedLessonData });
    };

    const reset = () => {
        localStorage.removeItem('customer_registrationDetails')
        setCurrentRegistrationDetails({})
    };

    useEffect(() => {
        //
    }, []);

    const value = {
        updateRegistrationDetails,
        registrationDetails,
        initialize,
        reset
    };

    return (
        <CustomerRegistrationContext.Provider value={value}>
            <Outlet />
        </CustomerRegistrationContext.Provider>
    );
};

export default CustomerRegistrationProvider;
