import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import superSmash from "../../assets/img/super-smash.png";

import {
	AngleRightIcon,
	BtcIcon,
	CircleShape,
	EthIcon,
	QuoteIcon,
	TrendDown,
	TrendUp,
} from "../../components/Icon";
import ProfileLayout from "./components/ProfileLayout";
import { APIBaseURL, DemoUserID } from "../../util/DomainHelper";

const Profile = ({ onTitleChange }) => {

	const params = useParams();

	const [startDate, setStartDate] = useState(new Date());
	const [userSessions, setUserSessions] = useState();
	const [filteredSessions, setFilteredSessions] = useState();
	const [initialized, setInitialized] = useState(false);
	const [todaySessions, setTodaySessions] = useState([]);

	const [highlightWithRanges, setHighlightWithRanges] = useState([]);

	// highlight dates
	const _highlightWithRanges = [
		{
			"react-datepicker__day--highlighted-custom-1": [
				new Date(2023, 10, 18),
				new Date(2023, 10, 20),
				new Date(2023, 10, 23),
				new Date(2023, 10, 29),
				new Date(2023, 11, 18),
				new Date(2023, 11, 20),
				new Date(2023, 11, 23),
				new Date(2023, 11, 29),
			],
		},
		{
			"react-datepicker__day--highlighted-custom-2": [
				new Date(2023, 10, 24),
				new Date(2023, 10, 27),
				new Date(2023, 10, 28),
				new Date(2023, 10, 22),
				new Date(2023, 11, 24),
				new Date(2023, 11, 27),
				new Date(2023, 11, 28),
				new Date(2023, 11, 22),
			],
		},
	];

	const userSelectedDate = (date) => {
		setStartDate(date);
		if (userSessions) {
			const filteredData = userSessions.filter(item => {
				const startTime = new Date(item.startTime);
  				const endTime = new Date(item.endTime);
				return startTime <= date && endTime >= date;
			});
			setTodaySessions(filteredData);
			console.log(todaySessions);
		}
	};

	const fetchUserSessions = () => {
		const url = APIBaseURL() + `user-sessions/sessions/${params.id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					if (data) {
						setUserSessions(data);
						setupCalendarHilights(data)
					} else {
						console.log('Failed to fetch data');
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	function setupCalendarHilights(data) {
		if (!data) {
			return setInitialized(true);
		}

		let dates = [];
		for (let i = 0; i < data.length; i++) {
			let date = data[i].date;
			const startDate = new Date(data[i].startTime);
			const endDate = new Date(data[i].endTime);
			const datesArray = getDatesBetween(startDate, endDate);
			dates = [...dates, ...datesArray];
		}

		const datesData = (data ? data : []).map((item) => {
			if (item.startTime) {
				const startDate = new Date(item.startTime);
				const endDate = new Date(item.endTime);
				const datesArray = getDatesBetween(startDate, endDate);
				console.log(datesArray); //
				return new Date(item.startTime);
			}
		})

		const parsedRanges = [
			{
				"react-datepicker__day--highlighted-custom-1": dates,
			},
			{
				"react-datepicker__day--highlighted-custom-2": datesData,
			},
		];

		setHighlightWithRanges(parsedRanges)
		return setInitialized(true);
	}

	const getDatesBetween = (startDate, endDate) => {
		const dates = [];
		const currentDate = new Date(startDate);

		while (currentDate <= endDate) {
			dates.push(new Date(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}

		return dates;
	};

	async function initializePage() {
		await fetchUserSessions();
	}

	useEffect(() => {
		initializePage()
		console.log(params)
	},[]);

	return (
		<>
			{ initialized && (
				<>
					<div className="d-flex flex-wrap gap-3">
						<h6 className="sidebar-widget-title side-title">
							Calendar & Updates
						</h6>
						<div className="feedback-product-wrap">
							<div className="row g-4">
								<div className="col-xl-6">
									<div className="datepicker--wrapper" style={{ maxHeight: '510px'}}>
										<DatePicker
											selected={startDate}
											onChange={(date) => userSelectedDate(date)}
											inline
											minDate={new Date()}
											highlightDates={highlightWithRanges}

										/>
									</div>
								</div>
								<div className="col-xl-6">
									<div className="row g-3 g-xxl-4">
										{todaySessions.map((item, index) => {
											return (
												<div className="col-lg-12">
													<div className="super-smash min-h-208px">
														<div className="d-flex justify-content-between">
															<div>
																<div
																	className="d-flex flex-wrap align-items-center gap-3">
															<span className="badge bg-white">
																<span className="dot"></span> LIVE
															</span>
																	<div style={{color: "#37FF7B"}}>
																		NEXT SESSION
																	</div>
																</div>
																<div className="line-limit-3 txt">
																	Lorem ipsum dolor sit amet,
																	consectetur adipiscing elit, sed do
																	eiusmod tempor incididunt ut labore et
																	dolore magna...
																</div>
															</div>
															<div className="date">
																<div className="day">18</div>
																<div className="month">JUNE</div>
															</div>
														</div>
														<div className="prices align-items-center mt-2">
															<div>
																<div className="day">Wednesday</div>
																<div className="time">2:00/4:00</div>
															</div>
															<Link className="text-base" to="#">
																Add to Calendar <AngleRightIcon/>
															</Link>
														</div>
													</div>
												</div>
											)
										})}
										{userSessions && userSessions.map((item, index) => {
											return (
												<div className="col-lg-12">
													<div className="super-smash min-h-208px">
														<div className="d-flex flex-wrap justify-content-between">
															<div className="d-flex align-items-center gap-2">
																<img src={superSmash} alt=""/>
																<div className="name">Supper smash</div>
															</div>
															<span className="badge badge-secondary">
															{(item.type ?? 'Public').toUpperCase()}
														</span>
														</div>
														<div className="mt-2 title">
															{item.title}
														</div>
														<div className="cate">
															<span>{item.description}</span>
															{/* <span className="bullet"></span>
														<span>Trading Coaching</span> */}
														</div>
														<div className="prices">
															<div
																className="price">{item.price ?? '' === '' ? 'FREE' : item.price}
															</div>
															{ item.type === 'one-on-one' && (
																<Link className="text-base" to={`/profile/${params.id}/session/book-session/?sessionId=${item._id}`}>
																	Book <AngleRightIcon/>
																</Link>
															)}
															{ item.type === 'public' && (
																<Link className="text-base" to={`/livestream/${params.id}/?sessionId=${item._id}`}>
																	Join Now <AngleRightIcon/>
																</Link>
															)}
														</div>
													</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
					<br/>
					<br/>
					<div className="d-flex flex-wrap gap-3">
						<h6 className="sidebar-widget-title side-title">
							Products & Feedback
						</h6>
						<div className="feedback-product-wrap">
							<div className="row g-4">
								<div className="col-xl-6">
									<div className="row g-4">
										<div className="col-lg-12 col-md-6">
											<div className="video-1">
												<div className="text-end">
													<Link className="text-base" to="#">
														View <AngleRightIcon/>
													</Link>
												</div>
												<div className="video-1-content">
													<h6 className="subtitle">Free Course</h6>
													<h6 className="title">
														<Link to="#">
															The Essentials of Forex Trading
														</Link>
													</h6>
												</div>
											</div>
										</div>
										<div className="col-lg-12 col-md-6">
											<div className="video-1">
												<div className="text-end">
													<Link className="text-base" to="#">
														Buy <AngleRightIcon/>
													</Link>
												</div>
												<div className="video-1-content">
													<h6 className="subtitle">Free Course</h6>
													<h6 className="title">
														<Link to="#">
															The Essentials of Forex Trading
														</Link>
													</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-xl-6">
									<div className="row g-4">
										<div className="col-lg-12">
											<div className="quote">
												<div className="quote-icon">
													<QuoteIcon/>
												</div>
												<div className="position-relative">
													<blockquote>
														"NovaKnight is the real deal! I am so
														impressed and pleased with his coaching
														skills. Book a session with him now if
														you want to improve rapidly! I'm booking
														my next session"
													</blockquote>
													<div className="d-flex justify-content-between align-items-center">
														<span>Gravety</span>
														<div className="avatar-group">
															<div className="avatar">
																<img src={avatar} alt=""/>
															</div>
															<div className="avatar">
																<img src={avatar} alt=""/>
															</div>
															<div className="avatar">
																<img src={avatar} alt=""/>
															</div>
															<div className="avatar">
																<span>+23</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-12">
											<div className="market-insights">
												<div className="shape">
													<CircleShape/>
												</div>
												<h5 className="title">
													View Jane’s Market Insights & Trends
												</h5>
												<div className="insights">
													<div className="row g-3 g-xxl-4">
														<div className="col">
															<div className="item">
																<div className="icon mb-2">
																	<BtcIcon/>
																</div>
																<TrendUp/>
																<span>trend</span>
																<div className="point">50pts</div>
															</div>
														</div>
														<div className="col">
															<div className="item">
																<div className="icon mb-2">
																	<EthIcon/>
																</div>
																<TrendDown/>
																<span>trend</span>
																<div className="point">30pts</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default Profile;
