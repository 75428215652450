import React from "react";
import SettingsLayout from "./components/SettingsLayout";
import SingleImageUploader from "./components/SingleImageUploader";

const MyAccount = () => {
	const [password, setPassword] = React.useState("");
	const [openPasswordField, setOpenPasswordField] = React.useState(false);
	return (
		<>
				<div className="my-account-edit">
					<div className="d-flex flex-wrap align-items-center justify-content-between gap-3 mb-4">
						<h5 className="setting-title">My Account</h5>
						<SingleImageUploader />
					</div>
					<EditInfo label="Display Name" name="Usef Elshazly" />
					<EditInfo label="Username" name="Usefelshazly121" />
					<EditInfo label="Email" name="**************@gmail.com" />
					<EditInfo
						label="Phone Number"
						name=""
						type="number"
						emptyText="You haven't added a phone number yet."
					/>
					<div className="edit-info-item gap-3 mb-4">
						<div>
							<label className={!openPasswordField ? "name" : "label"}>
								Password
							</label>
							{openPasswordField ? (
								<input
									type={"password"}
									value={password}
									className="form-control"
									onChange={(e) => setPassword(e.target.value)}
								/>
							) : (
								""
							)}
						</div>
						<div>
							<button
								className="btn btn--secondary btn--danger"
								onClick={() => setOpenPasswordField(!openPasswordField)}
							>
								{openPasswordField ? "Update" : "Change"}
							</button>
						</div>
					</div>
					<div className="edit-info-item gap-3 mb-4">
						<div>
							<label className={"name"}>Account Removal</label>
							<div className="label mt-2">
								Disabling your account means you can recover it at any
								time after taking this action.
							</div>
						</div>
					</div>
					<div className="edit-info-item flex-wrap justify-content-start gap-3">
						<div className="max-sm-grow">
							<button className="btn btn--secondary btn--danger">
								Disable Account
							</button>
						</div>
						<div className="max-sm-grow">
							<button className="btn cmn-btn btn-outline">
								Delete Account
							</button>
						</div>
					</div>
				</div>
		</>
	);
};
const EditInfo = ({ label, name, emptyText, type }) => {
	const [value, setValue] = React.useState(name);
	const [open, setOpen] = React.useState(false);

	const handleChange = (event) => {
		type === "number"
			? setValue(event.target.value.replace(/\D/g, ""))
			: setValue(event.target.value);
	};

	return (
		<>
			<div className="edit-info-item gap-3 mb-4">
				<div>
					<label className="label">{label}</label>
					{open ? (
						<input
							type={"text"}
							value={value}
							className="form-control"
							onChange={handleChange}
						/>
					) : (
						<div className="name">{value || emptyText}</div>
					)}
				</div>
				<div>
					{open ? (
						<button
							className="btn btn--secondary"
							onClick={() => setOpen(false)}
						>
							Save
						</button>
					) : (
						<button
							className="btn btn--secondary"
							onClick={() => setOpen(true)}
						>
							{!value ? "Add" : "Edit"}
						</button>
					)}
				</div>
			</div>
		</>
	);
};
export default MyAccount;
