
import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import {Outlet, useParams} from "react-router-dom";

// Create a context to hold the authentication state and methods
const CreateCourseContext = createContext();

export const useCreateCourse = () => useContext(CreateCourseContext);

const defaultValues = {}
const _defaultValues = {
    details: {
        content: {
            title: '',
            subtitle: '',
            description: '',
        },
        basic_info: {
            language: '',
            level: '',
            category: ''
        },
        multimedia: {
            thumbnail: '',
            preview_video: ''
        }
    },
    pricing: {
        currency: '',
        pricing: ''
    },
    messaging: {
        welcome: '',
        completion: ''
    },
    intended_learners: {
        content: [],
        requirements: '',
        target: ''
    },
    description: '',

}
const CreateCourseProvider = ({ children }) => {

    const params = useParams();

    const [currentCurriculums, setCurrentCurriculums] = useState([]);
    const [currentCourseDetails, setCurrentCourseDetails] = useState(defaultValues);

    const updateCourseDetails = (updates) => {
        const keys = Object.keys(updates);
        const key = keys[0];
        const value = updates[key];

        let parsedCourseDetails = currentCourseDetails;
        parsedCourseDetails[key] = value
        console.log(parsedCourseDetails);
        setCurrentCourseDetails(parsedCourseDetails)
    };

    const courseDetails = () => {
        return currentCourseDetails
    }

    const curriculums = () => {
        return currentCurriculums;
    };

    const insertCurriculum = (newCurriculum) => {
        // console.log('newCurriculum', newCurriculum);
        setCurrentCurriculums(newCurriculum)
        updateCourseDetails({ modules: newCurriculum })
    };

    const insertModule = (newModule) => {
        // console.log('newCurriculum', newCurriculum);
        // setCurrentCurriculums(newCurriculum)
    };

    const updateLessonData = (lessonData) => {
        const currentModuleIndex = parseInt(params.curriculumIndex)
        console.log('New Lesson Data', lessonData, 'At Index: ' + currentModuleIndex);
        const lessons = lessonData.lessons;
        let parsedLessonData = currentCourseDetails.modules ?? [];
        parsedLessonData[currentModuleIndex].lessons = lessons;
        updateCourseDetails({ modules: parsedLessonData });
    };

    const removeCurriculum = (curriculum) => {

    };

    const fetchCourse = (courseId) => {
        // Search for course id or draft
    };

    useEffect(() => {
        //
    }, []);

    const value = {
        updateCourseDetails,
        curriculums,
        insertCurriculum,
        removeCurriculum,
        fetchCourse,
        courseDetails,
        updateLessonData
    };

    return (
        <CreateCourseContext.Provider value={value}>
            <Outlet context={currentCurriculums} />
        </CreateCourseContext.Provider>
    );
};

export default CreateCourseProvider;
