import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import arrow from "../../../assets/img/flag/arrow.png";
import aus from "../../../assets/img/flag/aus.png";
import usa from "../../../assets/img/flag/usa.png";
import bg from "../../../assets/img/hero-bg-2.png";
import bg2 from "../../../assets/img/hero-bg.png";
import { NewsIcon, VideoIcon } from "../../../components/Icon";
import { APIBaseURL } from "../../../util/DomainHelper";

const Banner = () => {

	return (
		<section className="banner-section">
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				pagination={{
					clickable: "true",
				}}
				modules={[Pagination]}
			>
				{data.map((item, index) => (
					<SwiperSlide key={index}>
						<div
							className="banner-item"
							style={{
								background: `url(${item.img}) no-repeat center center / cover`,
							}}
						>
							<div className="banner-content">
								<h6 className="subtext">
									<NewsIcon /> DAILY MARKET NEWS
								</h6>
								<h2 className="banner-title">
									Our Partner in <br /> International Trade
								</h2>
								<p className="banner-text">
									Trade refers to the buying, selling, or exchange of
									goods, services, or commodities between individuals,
									businesses.
								</p>
								<Link
									to="#"
									className="cmn-btn btn--secondary border-0"
								>
									<VideoIcon /> Start watching
								</Link>
							</div>
							<div className="banner-right">
								<div className="banner-right-card">
									<div className="top">Note</div>
									<div className="banner-right-body font-semibold">
										<div className="txt1 text-15">
											This update requires action.
										</div>
										<div className="txt2">
											Lorem ipsum dolor sit amet, consectetur
											adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna...
										</div>
										<div className="d-flex flex-wrap gap-4 justify-content-between">
											<div>
												<div className="txt1 mb-2 pb-1">
													Exit Positions
												</div>
												<div className="d-flex flex-wrap gap-2 note-info-items align-items-center">
													<button
														className="no-gutter"
														type="button"
													>
														<img width={22} src={aus} alt="" />
													</button>
													<img src={arrow} width={12} alt="" />
													<button
														className="no-gutter"
														type="button"
													>
														<img width={22} src={usa} alt="" />
													</button>
												</div>
											</div>
											<div>
												<div className="txt1 mb-2 pb-1">
													Buy Positions
												</div>
												<div className="d-flex flex-wrap gap-2 note-info-items align-items-center">
													<button
														className="no-gutter"
														type="button"
													>
														<img width={22} src={usa} alt="" />
													</button>
													<img src={arrow} width={12} alt="" />
													<button
														className="no-gutter"
														type="button"
													>
														<img width={22} src={aus} alt="" />
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};
const data = [
	{
		img: bg,
	},
	{
		img: bg2,
	},
	{
		img: bg,
	},
];
export default Banner;
