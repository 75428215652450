import {SuccessIcon2} from "../../../components/Icon";
import React from "react";
import {useAdminRegistration} from "../../../contexts/AdminRegistrationContext";

const AdminSignUpSuccessCard = ({ slug, name, handleNavigateToCompanySite }) => {

    const { updateRegistrationDetails, registrationDetails, initialize } = useAdminRegistration()

    return(
        <>
            <br/>
            <br className="d-none d-md-block"/>
            <br className="d-none d-md-block"/>
            <div className="text-center mx-auto" style={{maxWidth: "654px"}}>
                <div className="mb-4 mb-md-5 img-white-container">
                    <SuccessIcon2/>
                </div>
                <h2 className="title font-semibold mb-3 pb-1">
                    Welcome Abaord 👋
                </h2>
                {/* <h4 className="title font-semibold mb-3 pb-1">

					</h4> */}
                <div className="text">Hello {name ? name : 'there'}, Your company's domain is <span
                    className="title font-semibold">{slug ? (`${(slug ?? '').toLowerCase()}.trdsly.com`) : 'www.trdsly.com'}</span>.
                </div>
                <br/>
                <div className="mt-4 mt-sm-5">
                    <button
                        type="submit"
                        className="btn w-100 cmn-btn h-69"
                        onClick={handleNavigateToCompanySite}
                    >
                        Go to Dashboard
                    </button>
                </div>
            </div>
        </>
    )
}

export default AdminSignUpSuccessCard;
