import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-quill/dist/quill.snow.css";
import "react-tagsinput/react-tagsinput.css";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from "./components/Layout";

import AdminSignIn from "./pages/admin-auth/AdminSignIn";
import AdminSignUp from "./pages/admin-auth/AdminSignUp";
import AdminSignUpStep2 from "./pages/admin-auth/AdminSignUpStep2";
import AdminSignUpStep3 from "./pages/admin-auth/AdminSignUpStep3";
import AdminSignUpSuccess from "./pages/admin-auth/AdminSignUpSuccess";
import ChartPage from "./pages/charts/ChartPage";
import Community from "./pages/community/Community";
import CommunitySinglePage from "./pages/community/CommunitySinglePage";
import CommunityView from "./pages/community/CommunityView";
import Courses from "./pages/courses/Courses";
import CoursesPreview from "./pages/courses/CoursesPreview";
import CoursesSingle from "./pages/courses/CoursesSingle";

import CourseLanding from "./pages/course-plan/CourseLanding";
import CourseMessage from "./pages/course-plan/CourseMessage";
import CoursePricing from "./pages/course-plan/CoursePricing";
import Curriculam from "./pages/course-plan/Curriculam";
import Curriculum2 from "./pages/course-plan/Curriculum2";
import Curriculum3 from "./pages/course-plan/Curriculum3";
import IntendLearners from "./pages/course-plan/IntendLearners";

import CustomerSignUpStepper from "./pages/customer-auth/CustomerSignUpStepper";
import CustomerSignUpSuccess from "./pages/customer-auth/CustomerSignUpSuccess";
import CustomerSignIn from "./pages/customer-auth/SignIn";
import Dashboard from "./pages/dashboard/Dashboard";
import LivestreamPage from "./pages/livestream/LivestreamPage";
import Profile from "./pages/profile/Profile";
import ProfileBookSession from "./pages/profile/ProfileBookSession";
import ProfileFeed from "./pages/profile/ProfileFeed";
import ProfileSessions from "./pages/profile/ProfileSessions";
import ProfileVideos from "./pages/profile/ProfileVideos";
import Billing from "./pages/settings/Billing";
import FriendsRequests from "./pages/settings/FriendsRequests";
import LanguageSetting from "./pages/settings/LanguageSetting";
import LessonsSettings from "./pages/settings/LessonsSettings";
import MyAccount from "./pages/settings/MyAccount";
import Notifications from "./pages/settings/Notifications";
import PrivacySafety from "./pages/settings/PrivacySafety";
import Subscriptions from "./pages/settings/Subscriptions";
import VoiceAndVideo from "./pages/settings/VoiceAndVideo";
import TradingRooms from "./pages/trading-rooms/TradingRooms";
import { ConfigureApplication } from "./util/CompanyHelper";
import NotFound from "./pages/notfound/NotFound";
import ProfileLayout from "./pages/profile/components/ProfileLayout";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CurrentCompany } from "./util/DomainHelper";
import SettingsLayout from "./pages/settings/components/SettingsLayout";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import PlanSettings from "./pages/course-plan/PlanSettings";
import CreatePlan from "./pages/course-plan/CreatePlan";
import CreateCourseProvider from "./contexts/CreateCourseContext";
import AdminRegistrationProvider from "./contexts/AdminRegistrationContext";
import CourseCreated from "./pages/course-plan/CourseCreated";
import AdminSignUpStep4 from "./pages/admin-auth/AdminSignUpStep4";
import Payout from "./pages/payout/Payout";
import PayoutPayment from "./pages/payout/PayoutPayment";
import CustomerRegistrationProvider from "./contexts/CustomerRegistrationContext";
import Stripe from "./pages/settings/Stripe";


let isAuthenticated;
let company;

function CheckAuthentication() {
	// localStorage.removeItem('user');
	const user = localStorage.getItem('user');
	if (user) {
		isAuthenticated = true;
	} else {
		isAuthenticated = false;
	}
}

async function CheckCompany() {
	// localStorage.removeItem('user');
	const fetchedCompany = await CurrentCompany();
	if (fetchedCompany) {
		company = fetchedCompany;
	}
}

function App() {

	const [pageTitle, setPageTitle] = useState("");

	const handleTitleChange = (newTitle) => {
		setPageTitle(newTitle);
	};

	const Wrapper = ({ children }) => {
		const location = useLocation();
		useEffect(() => {
			setTimeout(() => {
				document.documentElement.scrollTo(0, 0);
			}, 0);
		}, [location.pathname, location.search]);
		return children;
	};

	ConfigureApplication();
	CheckCompany();
	CheckAuthentication();

	// if (!configured) {
	// 	return (<>
	// 	</>)
	// };

	return (
		<>
			<HMSRoomProvider>
				<HelmetProvider>
					<Helmet>
						<title>{company ? company.name : 'Tradessly'} | {pageTitle}</title>
						<meta property="og:title" content={`${company ? company.name : 'Tradessly'} | ${pageTitle}`} />
					</Helmet>
					<BrowserRouter>
						<Wrapper>
							<Routes>
								<>
								<Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/signin" />} />

									<Route element={<CreateCourseProvider onTitleChange={handleTitleChange} />}>
										{/* Course Creation */}
										<Route path="/course-plan/create" element={isAuthenticated ? <CreatePlan onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/settings" element={isAuthenticated ? <PlanSettings onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/intended-learners" element={isAuthenticated ? <IntendLearners onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />}/>
										<Route path="/course-plan/curriculum" element={isAuthenticated ? <Curriculam onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/modules/:curriculumIndex" element={isAuthenticated ? <Curriculum2 onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/success/:courseId" element={isAuthenticated ? <CourseCreated onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/pricing" element={isAuthenticated ? <CoursePricing onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/course-message" element={isAuthenticated ? <CourseMessage onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
										<Route path="/course-plan/landing" element={isAuthenticated ? <CourseLanding onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									</Route>

								<Route element={<ProfileLayout onTitleChange={handleTitleChange} />}>
									{/* Trader Profile */}
									<Route path="/profile/:id/about" element={isAuthenticated ? <Profile onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									<Route path="/profile/:id/video" element={isAuthenticated ? <ProfileVideos /> : <Navigate to="/signin" replace />} />
									<Route path="/profile/:id/feed" element={isAuthenticated ? <ProfileFeed /> : <Navigate to="/signin" replace />} />
									<Route path="/profile/:id/session" element={isAuthenticated ? <ProfileSessions /> : <Navigate to="/signin" replace />} />
									<Route path="/profile/:id/session/book-session" element={isAuthenticated ? <ProfileBookSession /> : <Navigate to="/signin" replace />} />
									{/* <Route path="/:id/*" element={isAuthenticated ? <Profile /> : <Navigate to="/signin" replace />} /> */}
								</Route>
								<Route element={<Layout />}>
									<Route path="/dashboard" element={isAuthenticated ? <Dashboard onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />

									{/* Trading Rooms */}
									<Route path="/trading-rooms" element={isAuthenticated ? <TradingRooms onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									<Route path="/trading-rooms/:id" element={isAuthenticated ? <TradingRooms onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									<Route path="/trading-rooms/:id/room/:id" element={isAuthenticated ? <TradingRooms onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />

									{/* Charts */}
									<Route path="/trade-ideas" element={isAuthenticated ? <ChartPage onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />

									{/* Community */}
									<Route path="/community" element={isAuthenticated ? <Community onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									<Route path="/community/:id" element={isAuthenticated ? <CommunitySinglePage onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									{/* <Route path="/community/trading/:id" element={<CommunitySinglePage />} /> */}

									{/* Live Stream */}
									<Route path="/livestream/:id" element={isAuthenticated ? <LivestreamPage /> : <Navigate to="/signin" replace />} />

									{/* Courses */}
									<Route path="/courses" element={isAuthenticated ? <Courses onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									<Route path="/courses/:id" element={isAuthenticated ? <CoursesSingle onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />
									<Route path="/courses/watch/:id" element={isAuthenticated ? <CoursesPreview onTitleChange={handleTitleChange} /> : <Navigate to="/signin" replace />} />

									<Route element={<SettingsLayout onTitleChange={handleTitleChange} />}>
										{/* Settings */}
										<Route path="/settings/my-account" element={isAuthenticated ? <MyAccount /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/notifications" element={isAuthenticated ? <Notifications /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/billing" element={isAuthenticated ? <Billing /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/friends-requests" element={isAuthenticated ? <FriendsRequests /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/lessons-settings" element={isAuthenticated ? <LessonsSettings /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/privacy-safety" element={isAuthenticated ? <PrivacySafety /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/language" element={isAuthenticated ? <LanguageSetting /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/subscriptions" element={isAuthenticated ? <Subscriptions /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/stripe" element={isAuthenticated ? <Stripe /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/voice-video" element={isAuthenticated ? <VoiceAndVideo /> : <Navigate to="/signin" replace />} />
										<Route path="/settings/*" element={isAuthenticated ? <Navigate to="/settings/my-account" replace /> : <Navigate to="/signin" replace />} />
									</Route>

									<Route path="/finances/payouts" element={<Payout />} />
									<Route path="/finances/payments" element={<PayoutPayment />} />
									<Route path="/finances/disputes" element={<PayoutPayment />} />

								</Route>

								{/* Admin Registration */}
									<Route element={<AdminRegistrationProvider onTitleChange={handleTitleChange} />}>
										{/* Course Creation */}
										<Route path="/auth/register" element={<AdminSignUp />} />
										<Route path="/auth/register/company" element={<AdminSignUpStep2 />} />
										<Route path="/auth/register/trading" element={<AdminSignUpStep3 />} />
										<Route path="/auth/register/package" element={<AdminSignUpStep4 />} />
										<Route path="/auth/register/success" element={<AdminSignUpSuccess />} />
									</Route>

									<Route element={<CustomerRegistrationProvider onTitleChange={handleTitleChange} />}>
										{/* Customer Sign In */}
										<Route path="/signin" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <CustomerSignIn onTitleChange={handleTitleChange} />} />
										<Route path="/register" element={<CustomerSignUpStepper onTitleChange={handleTitleChange} />} />
										<Route path="/success/:verificationId" element={<CustomerSignUpSuccess />} />
										<Route path="/signin/verification/:validationId" element={<CustomerSignUpSuccess />} />
									</Route>

								{/* Error */}
								<Route path="/error" element={<NotFound />} />
								<Route path="*" element={isAuthenticated ? <Navigate to="/" replace /> : <Navigate to="/signin" replace />} />
								</>
							</Routes>
						</Wrapper>
					</BrowserRouter>
				</HelmetProvider>
			</HMSRoomProvider>
			<ToastContainer theme={'dark'} position={'bottom-right'} autoClose={3000} />
		</>
	);
}

export default App;
