import { useEffect, useRef, useState } from "react";
import Wavesurfer from "wavesurfer.js";

const AudioPlayer = ({ url }) => {
	const waveform = useRef(null);

	const [play, setPlay] = useState(false);

	useEffect(() => {
		if (!waveform.current) {
			waveform.current = Wavesurfer.create({
				container: "#waveform",
				waveColor: "#34343E",
				barGap: 4,
				barWidth: 4,
				barRadius: 4,
				cursorWidth: 4,
				cursorColor: "#34343E",
				height: 24,
			});
			waveform.current.load(url);
		}
	}, []);

	const playAudio = () => {
		if (waveform.current.isPlaying()) {
			waveform.current.pause();
			setPlay(false);
		} else {
			waveform.current.play();
			setPlay(true);
		}
	};

	return (
		<div className="d-flex align-items-center gap-4">
			<div>
				<button
					onClick={playAudio}
					className="btn btn--secondary border-0 btn--danger h-45 px-3"
				>
					Check Audio
				</button>
			</div>
			<div id="waveform" className="w-0 flex-grow-1" />
		</div>
	);
};

export default AudioPlayer;
