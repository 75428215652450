import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
const AdminAuthLayout = ({
	children,
	title,
	text,
	subtitle,
	nowrwapper,
	altLoginText,
	fullwidth,
	rightSideComponent,
}) => {
	return (
		<section

			className={`customer-auth-section bg-14141a ${ fullwidth ? "fullwidth" : ""}`}
		>
			{nowrwapper ? (
				children
			) : (
				<div className="d-flex flex-wrap flex-xl-nowrap align-items-start justify-content-between">
					<div
						// style={rightSideComponent ? {} : {minHeight: 'inherit'}}
						className={`customer-auth-wrapper ${ rightSideComponent ? "fulwidth" : "d-flex"}`}
					>
						<div className="customer-auth-wrapper-inner"  >
							<div className="pb-sm-5">
								<Link to="#" className="logo mb-4">
									<img src={logo} alt="" />
								</Link>
							</div>
							{/*{!altLoginText && (*/}
							{/*	<div className="mb-4 mb-sm-5">*/}
							{/*		Have an account?{" "}*/}
							{/*		<Link*/}
							{/*			to="/admin-signin"*/}
							{/*			className="text-base font-bold text-lg"*/}
							{/*		>*/}
							{/*			Sign In*/}
							{/*		</Link>*/}
							{/*	</div>*/}
							{/*)}*/}
							<h2 className="title mb-3 pb-1">
								{title && title}{" "}
								{subtitle && (
									<span className="subtitle">/ {subtitle}</span>
								)}
							</h2>
							<div className="text">{text}</div>
							{children}
						</div>
					</div>
					{rightSideComponent && (
						<div className="customer-auth-right-side">
							<div className="customer-auth-right-side-inner">
								{rightSideComponent}
							</div>
						</div>
					)}
				</div>
			)}
		</section>
	);
};

export default AdminAuthLayout;
