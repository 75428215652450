import React from "react";
import SettingsLayout from "./components/SettingsLayout";

const Billing = () => {
	return (
		<>
				<h5 className="setting-title">Payment Methods</h5>
				<br />
				<div className="d-flex pb-3">
					<div className="payment-txt">
						<h5 className="subtitle">No payment method saved</h5>
						<span>Checkout faster by saving a payment method</span>
					</div>
					<div>
						<button
							type="button"
							className="btn btn--secondary rounded h-45 font-bold"
						>
							Add Payment Method
						</button>
					</div>
				</div>
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Transaction History</h6>
				</div>
				<div className="table-responsive">
					<table className="trx-table table">
						<tbody>
							{data?.map((item, index) => (
								<tr key={index}>
									<td>
										<div className="name-area">
											<h6 className="name">
												Course: Trading like a Pro
											</h6>
											<span className="price">Price : $50</span>
										</div>
									</td>
									<td>
										<div className="date">October 14th, 2023</div>
									</td>
									<td>
										<div className="d-flex justify-content-end">
											<button
												className={`btn --badge btn--secondary btn--${
													item?.status === "Cancelled"
														? "danger"
														: item?.status === "Active"
														? "success"
														: ""
												}`}
												style={{ minWidth: "100px" }}
											>
												{item?.status}
											</button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
		</>
	);
};
const data = [
	{
		name: "Course: Trading like a Pro",
		price: "$50",
		date: "October 14th, 2023",
		status: "Done",
	},
	{
		name: "Course: Trading like a Pro",
		price: "$50",
		date: "October 14th, 2023",
		status: "Active",
	},
	{
		name: "Course: Trading like a Pro",
		price: "$50",
		date: "October 14th, 2023",
		status: "Cancelled",
	},
];

export default Billing;
