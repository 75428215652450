import React from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../assets/img/logo.png";
import { BackIcon } from "./Icon";

const WelcomeModal = () => {
	const [open, setOpen] = React.useState(true);
	const [tosModal, setTosModal] = React.useState(false);
	const ref = React.useRef(null);
	const [activeIndex, setActiveIndex] = React.useState(0);
	const [checked, setChecked] = React.useState(false);
	const [checked2, setChecked2] = React.useState(false);
	const [guideLineModal, setGuideLineModal] = React.useState(false);

	return (
		<>
			<Modal
				show={open}
				// onHide={() => setOpen(false)}
				className="welcome-modal"
				centered
			>
				<Modal.Body>
					<div className="py-4 text-center px-sm-4">
						<img src={logo} width={116} className="mb-30" alt="" />
						<p className="welcome-subtitle">
							Welcome to your trading community!
						</p>
						<Swiper
							ref={ref}
							pagination={{
								clickable: true,
								className: "slider-dots",
							}}
							modules={[Pagination]}
							spaceBetween={30}
							onSwiper={(swiper) => {
								swiper.on("slideChange", () => {
									setActiveIndex(swiper.activeIndex);
								});
							}}
						>
							{data.map(({ title, text }, index) => (
								<SwiperSlide key={index}>
									<div>
										<div className="welcome-img">
											<img src="/img/welcome.png" alt="" />
										</div>
										<div className="text-start">
											<div className="my-30px">
												<div className="slider-dots"></div>
											</div>
											<h6 className="title">{title}</h6>
											<div className="text">{text}</div>
										</div>
									</div>
								</SwiperSlide>
							))}
						</Swiper>
						<div className="d-flex justify-content-between align-items-center">
							{activeIndex !== 0 && (
								<button
									type="button"
									className="text-base text-15 no-gutter font-bold"
									onClick={() => ref.current.swiper.slidePrev()}
								>
									<BackIcon />
								</button>
							)}

							<button
								type="button"
								className="cmn-btn text-16 ms-auto"
								onClick={() => {
									if (activeIndex === data.length - 1) {
										setOpen(false);
										setTosModal(true);
									} else {
										ref.current.swiper.slideNext();
									}
								}}
							>
								Next
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={tosModal}
				// onHide={() => setTosModal(false)}
				className="welcome-modal"
				centered
			>
				<Modal.Body>
					<div className="py-4 text-center px-sm-4">
						<img src={logo} width={116} className="mb-30" alt="" />
						<p className="welcome-subtitle">
							Welcome to your trading community!
						</p>

						<h6 className="title text-start">Our Terms of Service</h6>
						<div className="scroller-txt">
							<span className="txt1">1. Acceptance of Terms</span>
							<div className="txt2">
								By accessing or using our services, you agree to be
								bound by these Terms. If you do not agree to these
								Terms, please do not use our services.
							</div>

							<span className="txt1">2. Changes to Terms</span>
							<div className="txt2">
								We reserve the right to modify or revise these Terms at
								any time. The updated version will be effective upon
								posting. By continuing to use our services after any
								changes, you agree to be bound by the revised Terms.
							</div>

							<span className="txt1">3. Use of Our Services</span>
							<div className="txt3">
								a. User Account: Some features of our services may
								require you to create an account. You are responsible
								for maintaining the confidentiality of your account
								information and for all activities that occur under your
								account.
							</div>
							<div className="txt2">
								b. Prohibited Conduct: You agree not to engage in any
								conduct that violates these Terms, the law, or the
								rights of others. Prohibited conduct includes, but is
								not limited to, unauthorized access, interference with
								our services, and violation of applicable laws.
							</div>

							<span className="txt1">4. Privacy</span>
							<div className="txt2">
								Your use of our services is also governed by our Privacy
								Policy, which can be found [insert link]. Please review
								the Privacy Policy to understand our practices.
							</div>
							<span className="txt1">1. Acceptance of Terms</span>
							<div className="txt2">
								By accessing or using our services, you agree to be
								bound by these Terms. If you do not agree to these
								Terms, please do not use our services.
							</div>

							<span className="txt1">2. Changes to Terms</span>
							<div className="txt2">
								We reserve the right to modify or revise these Terms at
								any time. The updated version will be effective upon
								posting. By continuing to use our services after any
								changes, you agree to be bound by the revised Terms.
							</div>

							<span className="txt1">3. Use of Our Services</span>
							<div className="txt3">
								a. User Account: Some features of our services may
								require you to create an account. You are responsible
								for maintaining the confidentiality of your account
								information and for all activities that occur under your
								account.
							</div>
							<div className="txt2">
								b. Prohibited Conduct: You agree not to engage in any
								conduct that violates these Terms, the law, or the
								rights of others. Prohibited conduct includes, but is
								not limited to, unauthorized access, interference with
								our services, and violation of applicable laws.
							</div>

							<span className="txt1">4. Privacy</span>
							<div className="txt2">
								Your use of our services is also governed by our Privacy
								Policy, which can be found [insert link]. Please review
								the Privacy Policy to understand our practices.
							</div>
						</div>
						<label className="form-check form--check form-check-2 text-start">
							<input
								className="form-check-input"
								type="checkbox"
								checked={checked}
								onChange={(e) => setChecked(e.target.checked)}
							/>
							<span className="form-check-label">
								I read and accept the terms of service.
							</span>
						</label>
						<div className="d-flex justify-content-between align-items-center">
							<button
								type="button"
								className="text-base text-15 no-gutter font-bold"
								onClick={() => {
									setTosModal(false);
									setOpen(true);
									const timeout = setTimeout(() => {
										ref.current.swiper.slideTo(activeIndex);
									}, 0);
									return () => clearTimeout(timeout);
								}}
							>
								<BackIcon />
							</button>

							<button
								type="button"
								className="cmn-btn text-16 ms-auto"
								onClick={() => {
									if (checked) {
										setTosModal(false);
										setGuideLineModal(true);
									} else {
										toast.error(
											"Please accept the terms of service",
											{
												id: "error",
											}
										);
									}
								}}
							>
								Next
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				show={guideLineModal}
				// onHide={() => setGuideLineModal(false)}
				className="welcome-modal"
				centered
			>
				<Modal.Body>
					<div className="py-4 text-center px-sm-4">
						<img src={logo} width={116} className="mb-30" alt="" />
						<p className="welcome-subtitle">
							Welcome to your trading community!
						</p>

						<h6 className="title text-start">Platform Guidelines</h6>
						<div className="scroller-txt">
							<span className="txt1">1. Acceptance of Terms</span>
							<div className="txt2">
								By accessing or using our services, you agree to be
								bound by these Terms. If you do not agree to these
								Terms, please do not use our services.
							</div>

							<span className="txt1">2. Changes to Terms</span>
							<div className="txt2">
								We reserve the right to modify or revise these Terms at
								any time. The updated version will be effective upon
								posting. By continuing to use our services after any
								changes, you agree to be bound by the revised Terms.
							</div>

							<span className="txt1">3. Use of Our Services</span>
							<div className="txt3">
								a. User Account: Some features of our services may
								require you to create an account. You are responsible
								for maintaining the confidentiality of your account
								information and for all activities that occur under your
								account.
							</div>
							<div className="txt2">
								b. Prohibited Conduct: You agree not to engage in any
								conduct that violates these Terms, the law, or the
								rights of others. Prohibited conduct includes, but is
								not limited to, unauthorized access, interference with
								our services, and violation of applicable laws.
							</div>

							<span className="txt1">4. Privacy</span>
							<div className="txt2">
								Your use of our services is also governed by our Privacy
								Policy, which can be found [insert link]. Please review
								the Privacy Policy to understand our practices.
							</div>
							<span className="txt1">1. Acceptance of Terms</span>
							<div className="txt2">
								By accessing or using our services, you agree to be
								bound by these Terms. If you do not agree to these
								Terms, please do not use our services.
							</div>

							<span className="txt1">2. Changes to Terms</span>
							<div className="txt2">
								We reserve the right to modify or revise these Terms at
								any time. The updated version will be effective upon
								posting. By continuing to use our services after any
								changes, you agree to be bound by the revised Terms.
							</div>

							<span className="txt1">3. Use of Our Services</span>
							<div className="txt3">
								a. User Account: Some features of our services may
								require you to create an account. You are responsible
								for maintaining the confidentiality of your account
								information and for all activities that occur under your
								account.
							</div>
							<div className="txt2">
								b. Prohibited Conduct: You agree not to engage in any
								conduct that violates these Terms, the law, or the
								rights of others. Prohibited conduct includes, but is
								not limited to, unauthorized access, interference with
								our services, and violation of applicable laws.
							</div>

							<span className="txt1">4. Privacy</span>
							<div className="txt2">
								Your use of our services is also governed by our Privacy
								Policy, which can be found [insert link]. Please review
								the Privacy Policy to understand our practices.
							</div>
						</div>
						<label className="form-check form--check form-check-2 text-start">
							<input
								className="form-check-input"
								type="checkbox"
								checked={checked2}
								onChange={(e) => setChecked2(e.target.checked)}
							/>
							<span className="form-check-label">
								I read and accept the guidelines.
							</span>
						</label>
						<div className="d-flex justify-content-between align-items-center">
							<button
								type="button"
								className="text-base text-15 no-gutter font-bold"
								onClick={() => {
									setTosModal(true);
									setGuideLineModal(false);
								}}
							>
								<BackIcon />
							</button>

							<button
								type="button"
								className="cmn-btn text-16 ms-auto"
								onClick={() => {
									if (checked2) {
										setGuideLineModal(false);
									} else {
										toast.error("Please accept the guidelines", {
											id: "error",
										});
									}
								}}
							>
								Finish
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
const data = [
	{
		title: "Your Command Center: Navigate with Ease!",
		text: "Effortlessly navigate through your personalized command center! Explore the main side navigation bar to access key features and tools. Your dashboard is your control hub – make the most of it!",
	},
	{
		title: "Interact with Your Community",
		text: "Engage and connect with fellow traders! Share insights, comment on updates, and show your appreciation for great content. Your community awaits your valuable input.",
	},
	{
		title: "Courses: Learn from Verified Experts",
		text: "Empower yourself with knowledge! Dive into curated courses from trading experts. Expand your skill set and gain the insights needed to thrive in the dynamic world of trading.",
	},
	{
		title: "Stay Ahead with Hot Trade Signals",
		text: "Stay in the loop with real-time signals and trade tips! Follow the signals, discover hot trades, and ensure you're always ahead of the curve in the fast-paced world of trading.",
	},
	{
		title: "Build Your Profile",
		text: "Craft your trading identity! Develop a profile that reflects your expertise and achievements. Showcase your journey, connect with like-minded traders, and establish your presence in the trading community.",
	},
];
export default WelcomeModal;
