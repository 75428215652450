import React, { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { SettingIcon } from "../../../components/Icon";
import Layout from "../../../components/Layout";
import { PerformLogout } from "../../../util/CompanyHelper";

const SettingsLayout = ({ children, onTitleChange }) => {

	const [open, setOpen] = React.useState(false);

	useEffect(() => {
		const newTitle = "Settings";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	return (
		<>
				<div className="w-100 d-xl-none pt-3 text-end">
					<buton
						className="btn btn--secondary border-0 rounded"
						onClick={() => setOpen(!open)}
					>
						Menus <SettingIcon />
					</buton>
				</div>
				<div className="settings-wrapper">
					<div className={`settings-sidebar ${open ? "active" : ""}`}>
						<AccountSidebar />
						<div style={{ borderTop: "1px solid #9494A8" }}>
							<div className="settings-sidebar__item">
								<div className="settings-sidebar__item-submenu">
									<div className="settings-sidebar__item-submenu-item">
										<NavLink to={'/settings/logout'} onClick={() => PerformLogout()} >Logout</NavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="settings-article">
						<Outlet />
					</div>
				</div>
		</>
	);
};
const AccountSidebar = () => {
	return (
		<>
			{sidebarData.map((item, index) => {
				return (
					<div className="settings-sidebar__item" key={index}>
						<div className="settings-sidebar__item-title">
							<span>{item.name}</span>
						</div>
						<div className="settings-sidebar__item-submenu">
							{item.submenu.map((subitem, subindex) => {
								return (
									<div
										className="settings-sidebar__item-submenu-item"
										key={subindex}
									>
										<NavLink to={subitem.path}>
											{subitem.name}
										</NavLink>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
		</>
	);
};

const sidebarData = [
	{
		name: "User Settings",
		submenu: [
			{
				name: "My Account",
				path: "/settings/my-account",
			},
			{
				name: "Privacy & Safety",
				path: "/settings/privacy-safety",
			},
			{
				name: "Lessons Settings",
				path: "/settings/lessons-settings",
			},
			{
				name: "Friends Requests",
				path: "/settings/friends-requests",
			},
		],
	},

	{
		name: "Billing Settings",
		submenu: [
			{
				name: "Stripe Connect",
				path: "/settings/stripe",
			},
			{
				name: "Subscriptions",
				path: "/settings/subscriptions",
			},
			{
				name: "Billing",
				path: "/settings/billing",
			},
		],
	},

	{
		name: "App Settings",
		submenu: [
			{
				name: "Voice & Video",
				path: "/settings/voice-video",
			},
			{
				name: "Notifications",
				path: "/settings/notifications",
			},
			{
				name: "Language",
				path: "/settings/language",
			},
		],
	},
];

export default SettingsLayout;
