import React, { useState } from "react";
import existingImage from "../../../assets/img/avatar.png";
import { PlusIcon4 } from "../../../components/Icon";
const SingleImageUploader = () => {
	// single image upload and view
	const [file, setFile] = useState(null);
	const [image, setImage] = useState(existingImage);

	const handleFileChange = (e) => {
		const selected = e.target.files[0];
		const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
		if (selected && ALLOWED_TYPES.includes(selected.type)) {
			setFile(selected);
			// set the image
			setImage(URL.createObjectURL(e.target.files[0]));
		} else {
			setFile(null);
		}
	};

	return (
		<>
			<div className="avatar-upload">
				<label>
					<PlusIcon4 />
					<input
						type="file"
						className="form-control-file"
						id="exampleFormControlFile1"
						onChange={(e) => handleFileChange(e)}
						hidden
					/>
				</label>
				<img src={image} alt="" />
			</div>
		</>
	);
};

export default SingleImageUploader;
