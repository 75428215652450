import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import avatar from "../../assets/img/avatar.png";
import {
	CalendarIcon2,
	CommentsIcon,
	EyeSlash,
	PinnedIcon,
	ShareIcon,
	UserFollowIcon,
} from "../../components/Icon";

import ReactionIcon from "../../components/ReactionIcon";
import ProfileLayout from "./components/ProfileLayout";
import ProfileTrades from "./ProfileTrades";
import { APIBaseURL, CurrentCompany } from "../../util/DomainHelper";
import CommentForm from "../trading-rooms/components/CommentForm";
import NetworkingProvider from "../../providers/NetworkingProvider";
import UserProvider from "../../providers/UserProvider";

const ProfileFeed = () => {

	return (
		<>
			<div className="row g-4">
					<div className="col-xl-6 col-xxl-7">
						<div className="feed-posts">
							<PinnedPost loadMore />
						</div>
					</div>
					<div className="col-xl-6 col-xxl-5">
						<ProfileTrades />
					</div>
				</div>
		</>
	);
};

export const PinnedPost = ({ loadMore, count }) => {
	const [openComments, setOpenComeents] = React.useState(true);

	const [pinnedFeedPostItems, setPinnedFeedPostItems] = useState();
	const [feedPostItems, setFeedPostItems] = useState();
	const [paginationIndex, setPaginationIndex] = useState(0);

	const fetchFeedPosts = async () => {
		const company = await CurrentCompany();
		const url = APIBaseURL() + `post-feed/items/${company._id}`;
		try {
			fetch(url, {
				method: 'POST',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({pageIndex: paginationIndex, perPage: 10})
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					// console.log(data);
					if (data) {
						// setCommunities(fetchedCommunities);
						setFeedPostItems((data ?? []).reverse());
					} else {
						console.log('Failed to fetch data');
						// setCommunities(fetchedCommunities);
						setFeedPostItems([]);
					}
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchFeedPosts();
	}, [count]);

	return (
		<>
			{pinnedFeedPostItems && pinnedFeedPostItems.map((feedPostItem, index) => {
				return (
					<>
					<div className="post-item pinned-card bg-292933 mt-4">
						<div className="pinned-post-header">
							<div>
								<span className="text-base">
									<PinnedIcon />{" "}
								</span>
								Pinned post
							</div>
							<button className="no-gutter text-white">
								Hide <EyeSlash />
							</button>
						</div>
						<div className="pinned-card-body">
							<div className="d-flex flex-wrap justify-content-between align-items-center mb-4 gap-2 gap-sm-3">
								<Link to="#" className="media __media">
									<img src={avatar} alt="" />
									<div className="media-body">
										<h6 className="text-16 font-semibold m-0 mb-1">
											Jane Cooper
										</h6>
										<small>Top member</small>
									</div>
								</Link>
								<button type="button" className="cmm-btn load-more">
									Follow <UserFollowIcon />
								</button>
							</div>
							<p className="normal-text">
								Trade refers to the buying, selling, or exchange of goods,
								services, or commodities between individuals, businesses, or
								countries. It is a fundamental economic activity that has been
								a part of human civilization for centuries. Trade can take
								place locally, nationally, or internationally and can involve
								a wide range of products and services.
							</p>
							<div className="d-flex flex-wrap gap-2 post-icons">
								<ReactionIcon />
								<button
									type="button"
									className="no-gutter text-16"
									onClick={() => setOpenComeents(!openComments)}
								>
									<CommentsIcon /> <span>136 Comments</span>
								</button>
								<button type="button" className="no-gutter ms-auto text-16">
									Share <ShareIcon />
								</button>
							</div>
						</div>
					</div>
					<br />
					</>
				)
			})}
			{openComments && (
				<>
				{feedPostItems && feedPostItems.map((feedPostItem, index) => {
					return(
						<>
							<RegularPost feedPost={feedPostItem} />
						</>
					)
				})}
				</>
			)}
			{ (pinnedFeedPostItems ?? []).length === 0 && (feedPostItems ?? []).length === 0 && (
				<div className="row text-center justify-content-center align-content-center mt-3" style={{ height: '120px', borderRadius: '12px', borderColor: "gray", backgroundColor: '#1e1e27'}}>
					<label>
						Be the first to leave a comment
					</label>
				</div>
			)}
			{loadMore && (pinnedFeedPostItems ?? []).length > 0 || (feedPostItems ?? []).length > 0 && (
				<div className="text-center mt-4 pt-3">
					<button className="cmn-btn load-more" type="button">
						Load More
					</button>
				</div>
			)}
		</>
	);
};

export const RegularPost = ({ feedPost, nested }) => {

	const [ showComment, setShowComment ] = React.useState(false);

	function handleShowComments() {
		setShowComment(!showComment)
	}

	async function handleSend(e) {
		console.log('Comment:', e, 'Post ID:', feedPost.post._id);
		const currentUser = UserProvider.getCurrentUser()
		if (e && feedPost.post._id && currentUser) {
			try {
				const payload = {
					content: e,
					userId: currentUser._id,
					visibility: 'public'
				}
				const dataResponse = await NetworkingProvider.fetchData(`post-feed/comments/create/${feedPost.post._id}`, 'POST', payload)
				console.log(dataResponse)
			} catch (err) {
				console.log(err)
			}
		}
	}

	return (
		<>
			<div className="post-item">
				<div className={`d-flex flex-wrap justify-content-between align-items-center ${nested ? 'mb-2' : 'mb-4'} pb-1 gap-2 gap-sm-3`}>
					<Link to="#" className="media __media">
						<img
							src={feedPost ? (feedPost.user.photo !== '' ? feedPost.user.photo : '/avatar/blankUser.jpg') : '/avatar/blankUser.jpg'}
							alt=""
						/>
						<div className="media-body">
							<h6 className="text-16 font-semibold m-0 mb-1">{feedPost ? (`${feedPost.user.firstName} ${feedPost.user.lastName}`) : ''}</h6>
							<small>{feedPost ? (feedPost.post.timestamp ? feedPost.post.timestamp : '01-01-2024 12:00 AM') : '01-01-2024 12:00 AM'}</small>
						</div>
					</Link>
				</div>
				<p className={`normal-text ${nested ? 'mb-2' : 'mb-4'} `}>
					{feedPost ? feedPost.post.content : ''}
				</p>
				<div className="d-flex flex-wrap gap-2 post-icons">
					<ReactionIcon reactions={feedPost ? ((feedPost.interactions ?? []).length) : '0'}/>
					<button onClick={() => handleShowComments()} type="button" className="no-gutter text-16">
						<CommentsIcon/> <span>{feedPost ? ((feedPost.comments ?? []).length) : '0'} Comments</span>
					</button>
					<button type="button" className="no-gutter ms-auto text-16">
						Share <ShareIcon/>
					</button>
				</div>
				{showComment ? (
					<>
						<br/>
						<CommentForm hidePadding={true} handleSend={handleSend}/>
					</>
				) : null}

				{showComment && feedPost && (feedPost.comments ?? []).length > 0 && (
					<>
						<div className="py-3 ">
							<hr style={{borderColor: "#4A4768"}}/>
						</div>

						{feedPost.comments.map((comment, index) => (
							<div className={'mt-4'} style={{ marginLeft: '50px'}}>
								<RegularPost feedPost={comment} nested={true}/>
							</div>
						))}
					</>
				)}
			</div>
			{nested ? (
				<div className="py-0">
					<hr style={{borderColor: "transparent"}}/>
				</div>
			) : (
				<div className="py-3 ">
					<hr style={{borderColor: "#4A4768"}}/>
				</div>
			)}
		</>
	);
};

export default ProfileFeed;
