import React from "react";
import { useNavigate } from "react-router-dom";

import { NotificationHeader } from "./CreatePlan";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
const PlanSettings = ({ onTitleChange }) => {

	const { updateCourseDetails,
		curriculums,
		insertCurriculum,
		removeCurriculum,
		fetchCourse } = useCreateCourse();

	function handleTargetSelectionChange(selected) {
		console.log("selected", selected);
		updateCourseDetails({ time_allocation: selected})
	}

	return (
		<section className="setting-notification-main">
			<NotificationHeader />
			<div className="container">
				<div className="setting-notifications-content text-center">
					<h3 className="title">
						How much time should be spent on this course?
					</h3>
					<p className="text">
						There's no wrong answer. We collect this information to give you detailed analytics on user progress.
					</p>
					<div style={{ maxWidth: "647px" }} className="mx-auto">
						<div className="d-flex flex-column gap-3">
							{data?.map((item, index) => (
								<div
									onClick={() => handleTargetSelectionChange(item.value)}
									key={index}
									className="language-radio-item style-3"
								>
									<label className="form-check form--radio">
										<input
											type="radio"
											className="form-check-input"
											name="language"
										/>
										<span className="form-check-label">
											{item.name}
										</span>
									</label>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<NofificationBottombar />
		</section>
	);
};

const NofificationBottombar = () => {

	const navigate = useNavigate();

	const { courseDetails } = useCreateCourse();

	function checkForCompletionToProceed() {
		if (courseDetails) {
			navigate('/course-plan/intended-learners')
		}
	}

	return (
		<footer className="setting-notification-footer mt-auto">
			<button
				className="btn --badge btn--secondary btn-- text-20 font-semibold bg-transparent"
				type="button"
				onClick={() => navigate(-1)}
			>
				Previous
			</button>
			<button
				onClick={() => checkForCompletionToProceed()}
				className="btn --badge btn--secondary btn-- text-20 font-semibold"
				type="button"
			>
				Continue
			</button>
		</footer>
	);
};
const data = [
	{
		name: "Entry Level Course (0-2 hours)",
		img: "https://via.placeholder.com/150",
		value: 0
	},
	{
		name: "Intermediate Course (2-4 hours)",
		img: "https://via.placeholder.com/150",
		value: 1
	},
	{
		name: "Advanced Course (5+ hours)",
		img: "https://via.placeholder.com/150",
		value: 2
	},
	{
		name: "Reference Material (Come back at any time)",
		img: "https://via.placeholder.com/150",
		value: 3
	},
];
export default PlanSettings;
