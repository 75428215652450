import React, { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import img1 from "../../assets/img/courses/1.png";
import img2 from "../../assets/img/courses/2.png";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import { APIBaseURL, CurrentCompany } from "../../util/DomainHelper";
import CourseCard from "./components/CourseCard";
import NetworkingProvider from "../../providers/NetworkingProvider";

const defaultCategoryValues = { value: '', name: 'All Courses', _id: ''}

const Courses = ({ onTitleChange }) => {

	const navigate = useNavigate();

	const [paginationIndex, setPaginationIndex] = useState(0);
	const [courseCategories, setCourseCategories] = useState([]);
	const [courses, setCourses] = useState([]);

	const [freeCourses, setFreeCourses] = useState();
	const [featuredCourses, setFeaturedCourses] = useState();
	const [selectedCategory, setSelectedCategory] = useState(defaultCategoryValues)
	const [filteredCourses, setFilteredCourses] = useState([])

	async function fetchCourseCategories() {
		try {
			const company = await CurrentCompany(false);
			if (!company) {
				return
			}

			const dataResponse = await NetworkingProvider.fetchData(`courses/categories/${company._id}`, 'GET');
			// console.log(dataResponse);
			const data = dataResponse.data;
			if (data) {
				console.log(data)
				const parsedCategories = (data ?? []).map((category) => {
					return { name: category.title, value: category._id };
				});
				setCourseCategories(parsedCategories)
			}
		} catch (e) {

		}
	}

	const fetchCourses = async () => {
		const company = await CurrentCompany(false);
		if (company) {
			const url = APIBaseURL() + `courses/courses/${company._id}`;
			try {
				fetch(url, {
					method: 'POST',
					headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
					},
					body: JSON.stringify({pageIndex: paginationIndex, perPage: 10})
				}).then(async (response) => {
					if (response.ok) {
						const responseJSON = await response.json();
						const data = responseJSON.items;
						sortCourses(data);
					}
				})
			} catch(error) {
				console.log(error);
			}
		}
	};

	const sortCourses = (retrievedCourses) => {
		const filteredFreeCourses = retrievedCourses.filter(item => item.course.price === 0);
		const filteredFeaturedCourses = retrievedCourses.filter(item => item.course.recommended === true);
		setCourses(retrievedCourses);
		setFreeCourses(filteredFreeCourses);
		setFeaturedCourses(filteredFeaturedCourses);
	};

	function handleCoursesCategorySelection(e) {
		const targetIndex = courseCategories.findIndex((category) => category.value === e);
		if (e !== '') {
			setSelectedCategory(courseCategories[targetIndex])
			// console.log(courses)
			const filtered = courses.filter((item) => item.course.category === e);
			// console.log(filtered)
			return setFilteredCourses(filtered)

		} else {
			return setSelectedCategory(defaultCategoryValues)
		}
	}

	useEffect(() => {
		fetchCourseCategories();
		fetchCourses();
	},[]);

	useEffect(() => {
		const newTitle = "Courses";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	return (
		<>
			<br/>
			<div className="d-flex gap-3 flex-wrap align-items-center cate-title-header">
				{/*<h5>Categories</h5>*/}
				<div style={{width: '300px'}}>
					<Input
						onChange={(e) => handleCoursesCategorySelection(e)}
						height="37px"
						options={[...[{name: 'All Courses', value: ''}], ...courseCategories]}
					/>
				</div>
				<button
					className="cmn-btn ms-auto px-4"
					onClick={() => navigate('/course-plan/create')}
				>
					Create New Course
				</button>
			</div>
			<br/>
			<div className="my-course-title">
				<h6>{selectedCategory.value === '' ? 'All Courses' : selectedCategory.name}</h6>
			</div>

			<div className="row g-4">
				{selectedCategory.value === '' && (
					<>
						{(courses ?? []).length === 0 && (
							<div className="row text-center justify-content-center align-content-center mt-3"
								 style={{
									 width: "100%",
									 height: '300px',
									 borderRadius: '12px',
									 borderColor: "gray",
									 backgroundColor: '#1e1e27',
									 margin: '2px',
								 }}>
								<label>
									No Courses Found
								</label>
							</div>
						)}
						{courses && (courses ?? []).map((course, index) => (
							<CourseCard key={index} course={course}/>
						))}
					</>
				)}
				{selectedCategory.value !== '' && (
					<>
						{filteredCourses.length === 0 && (
							<div className="row text-center justify-content-center align-content-center mt-3"
								 style={{
									 width: "100%",
									 height: '300px',
									 borderRadius: '12px',
									 borderColor: "gray",
									 backgroundColor: '#1e1e27',
									 margin: '2px',
								 }}>
								<label>
									No Courses Found
								</label>
							</div>
						)}
						{filteredCourses && filteredCourses.map((course, index) => (
							<CourseCard key={index} course={course}/>
						))}
					</>
				)}
			</div>
			<br/>
			<br/>
			<div className="my-course-title">
				<h6>Featured Courses</h6>
				<Link className="view-all">view all</Link>
			</div>
			<div className="row g-4">
				{featuredCourses && featuredCourses.map((course, index) => (
					<CourseCard key={index} course={course}/>
				))}
			</div>
			<br/>
			<br/>
			<div className="row g-4">
				<div className="col-sm-6">
					<div className="exciting-feature-card">
						<h6 className="subtext">Exciting Features Await You:</h6>
						<h5 className="title">
							<Link to="/courses" className="text-hover text-white">
								Unleash Your Trading Potential with Our Exciting
								Upcoming Course!
							</Link>
						</h5>
						<button className="no-gutter">Add to calendar</button>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="exciting-feature-card">
						<h6 className="subtext">Live Trading Sessions:</h6>
						<h5 className="title">
							<Link to="/courses" className="text-hover text-white">
								Immerse yourself in real-time trading experiences
								with our expert instructors
							</Link>
						</h5>
						<button className="no-gutter">Visit Live Trading</button>
					</div>
				</div>
			</div>
			<br/>
			<br/>
			<div className="my-course-title">
				<h6>Free Courses</h6>
				<Link className="view-all">view all</Link>
			</div>
			<div className="row g-4">
				{freeCourses && freeCourses.map((course, index) => (
					<CourseCard key={index} course={course}/>
				))}
			</div>
		</>
	);
};


const mycourse = [
	{
		id: "1",
		img: img1,
		title: "The Real Estate Agent Academy",
		author: "Graham Stephan",
		discount: 80,
	},
	{
		id: "2",
		img: img2,
		title: "My Next $100,000 Investment",
		author: "Andrei Jikh",
		discount: 20,
	},
];

const featuredcourse = [
	{
		id: "1",
		img: img1,
		title: "The Real Estate Agent Academy",
		author: "Graham Stephan",
		price: "$199.99",
	},
	{
		id: "2",
		img: img2,
		title: "My Next $100,000 Investment",
		author: "Andrei Jikh",
		price: "$199.99",
	},
	{
		id: "2",
		img: img2,
		title: "My Next $100,000 Investment",
		author: "Andrei Jikh",
		price: "$199.99",
	},
];

const freecourse = [
	{
		id: "1",
		img: img1,
		title: "The Real Estate Agent Academy",
		author: "Graham Stephan",
		price: "FREE",
	},
	{
		id: "2",
		img: img2,
		title: "My Next $100,000 Investment",
		author: "Andrei Jikh",
		price: "FREE",
	},
	{
		id: "2",
		img: img2,
		title: "My Next $100,000 Investment",
		author: "Andrei Jikh",
		price: "FREE",
	},
];

export default Courses;
