import { useEffect, useState } from "react";

const useScreen = () => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const updateWidth = () => {
			setWidth(window.innerWidth);
		};
		window.addEventListener("resize", updateWidth);
		updateWidth();
		return () => window.removeEventListener("resize", updateWidth);
	}, []);

	return width;
};

export default useScreen;
