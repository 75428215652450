import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import arrow from "../../assets/img/flag/arrow.png";
import aus from "../../assets/img/flag/aus.png";
import usa from "../../assets/img/flag/usa.png";
import {
	CalendarIcon2,
	CommentsIcon,
	DollarIcon,
	EuroIcon,
	NextSlide,
	PinnedIcon,
	PrevSlide,
	ProfileAddIcon2,
	ShareIcon,
	UpperArrowChartIcon,
} from "../../components/Icon";
import ReactionIcon from "../../components/ReactionIcon";

export const ProfileTrades = () => {
	const ref = React.useRef(null);

	const goNext = () => {
		ref.current.swiper.slideNext();
	};
	const goPrev = () => {
		ref.current.swiper.slidePrev();
	};
	const ref2 = React.useRef(null);

	const goNext2 = () => {
		ref2.current.swiper.slideNext();
	};
	const goPrev2 = () => {
		ref2.current.swiper.slidePrev();
	};
	return (
		<div>
			<div className="feed-trades">
				<br />
				<div className="counter-area style-1">
					<div className="counter-item">
						<div className="counter-value">20</div>
						<div className="counter-label">Wins</div>
					</div>
					<div className="counter-item">
						<div className="counter-value">08</div>
						<div className="counter-label">Losses</div>
					</div>
					<div className="counter-item">
						<div className="counter-value">28</div>
						<div className="counter-label">Positions</div>
					</div>
					<div className="counter-item">
						<div className="counter-value text-sm">2,000,000</div>
						<div className="counter-label">PORTFOLIO SIZE</div>
					</div>
					<div className="counter-item">
						<ProfileAddIcon2 />
						<div className="counter-value">
							<small>20k</small>
						</div>
						<div className="counter-label text-capitalize">Followers</div>
					</div>
				</div>
				<br />
				<br />
				<div className="row g-4">
					<div className="col-xl-12">
						<div className="bg-292933 rounded-22">
							<div className="pinned-post-header font-heading">
								<div>
									<PinnedIcon /> Pinned Trade
								</div>
							</div>
							<TradeItem icon status="winning" />
						</div>
						{/* <br />
						<br />
						<div className="mb-3 d-flex justify-content-between align-items-center">
							<h6 className="sidebar-widget-title mb-0">
								Recent Trades
							</h6>
							<div className="d-flex gap-3">
								<button className="no-gutter" onClick={goPrev}>
									<PrevSlide />
								</button>
								<button className="no-gutter" onClick={goNext}>
									<NextSlide />
								</button>
							</div>
						</div>
						<Swiper
							ref={ref}
							slidesPerView={1}
							spaceBetween={24}
							loop={true}
						>
							{["", "", ""].map((item, index) => (
								<SwiperSlide key={index}>
									<TradeItem status="losing" />
									<br />
									<TradeItem status="winning" />
								</SwiperSlide>
							))}
						</Swiper> */}
					</div>
					<div className="col-xl-12">
						<div className="mb-3 d-flex justify-content-between align-items-center">
							<h6 className="sidebar-widget-title mb-0">
								Recent Trades
							</h6>
							<div className="d-flex gap-3">
								<button className="no-gutter" onClick={goPrev2}>
									<PrevSlide />
								</button>
								<button className="no-gutter" onClick={goNext2}>
									<NextSlide />
								</button>
							</div>
						</div>
						<Swiper
							ref={ref2}
							slidesPerView={1}
							spaceBetween={24}
							loop={true}
						>
							{["", "", ""].map((item, index) => (
								<SwiperSlide key={index}>
									<TradeItem status={"loosing"} />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
				</div>
			</div>
		</div>
	);
};

const TradeItem = ({ status, icon }) => {
	return (
		<>
			<div className="trade-card">
				<div className="status">
					{status === "winning" ? (
						<>
							<span>Winning</span>
							<div className="amount" style={{ color: "#57BCAF" }}>
								$3k
							</div>
						</>
					) : (
						<>
							<span>Losing</span>
							<div className="amount">$3k</div>
						</>
					)}
				</div>
				<div className="exchanges">
					{" "}
					{icon ? (
						<>
							<div className="d-flex align-items-center gap-2">
								<DollarIcon color="#37FF7B" />{" "}
								<span className="line-arrow"></span>
								<EuroIcon color="#37FF7B" />
							</div>
						</>
					) : (
						"USD -> EUR"
					)}{" "}
				</div>
				<div className="item-area">
					<div className="item">
						<div className="label">Total Value</div>
						<div className="value">$50k</div>
					</div>
					<div className="item">
						<div className="label">Strategy</div>
						<div className="value">Long</div>
					</div>
				</div>
				<div className="d-flex flex-wrap gap-2 post-icons">
					<ReactionIcon />
					<button type="button" className="no-gutter text-16">
						<CommentsIcon /> <span>136 Comments</span>
					</button>
					<div className="ms-auto">
						<small className="text-16 d-flex align-items-center gap-1 text-text">
							<CalendarIcon2 /> <span>24 Oct,2021</span>
						</small>
					</div>
				</div>
			</div>
		</>
	);
};

const SignalItem = ({ redish }) => {
	return (
		<>
			<div className={`signal--item ${redish ? "redish" : ""}`}>
				<div className="signal--item-top">
					<div className="usd-long">
						<div>
							<h6 className="name font-semibold">USD</h6>
							United States Dollar
						</div>
						<div className="d-flex flex-wrap gap-2 note-info-items align-items-center justify-content-center">
							<button className="no-gutter" type="button">
								<img width={22} src={usa} alt="" />
							</button>
							<img src={arrow} width={12} alt="" />
							<button className="no-gutter" type="button">
								<img width={22} src={aus} alt="" />
							</button>
						</div>
						<div className="long-badge">
							<span>LONG</span>{" "}
							<span>
								<UpperArrowChartIcon />
							</span>
						</div>
					</div>
				</div>
				<div className="signal--item-middle">
					<div>
						<div className="item">
							<span>Growth</span>
							<strong>+20.45%</strong>
						</div>
						<div className="item">
							<span>Signal type</span>
							<strong>Update</strong>
						</div>
					</div>
					<div>
						<div className="item">
							<span>Signal ID</span>
							<strong>35821</strong>
							<button
								className="d-inline-block btn btn-secondary"
								type="button"
							>
								Copy
							</button>
						</div>
						<div className="item">
							<span>Alert ID</span>
							<strong>47523</strong>
						</div>
					</div>
					<div>
						<div className="item">
							<span>SL</span>
							<strong>1.181</strong>
						</div>
						<div className="item">
							<span>TP</span>
							<strong>1.19</strong>
						</div>
					</div>
				</div>
				<div className="signal--item-bottom">
					<div>
						<strong>Time Stamp:</strong>
						<span>12 November 2022 12:57</span>
					</div>
					<button className="no-gutter text-white" type="button">
						Share <ShareIcon color="white" />
					</button>
				</div>
			</div>
		</>
	);
};

export default ProfileTrades;
