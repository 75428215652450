import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import img from "../../assets/img/signal-img.png";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../components/Icon";
import Input from "../../components/Input";
import AdminAuthLayout from "./components/AdminAuthLayout";
import {useAdminRegistration} from "../../contexts/AdminRegistrationContext";
import {toast} from "react-toastify";
const AdminSignUp = () => {

	const { updateRegistrationDetails, registrationDetails, initialize, reset } = useAdminRegistration();

	const [checked, setChecked] = useState("unchecked");
	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [agreedToTerms, setAgreedToTerms] = useState(false);

	const configurePageDefaults = () => {
		console.log('Configuring the page defaults');
		// startLoading();
		// fetchTradingTypes();
	};

	function handleSubmit(e) {
		e.preventDefault();
		if (!name || !email || !password) {
			return toast.warning("Please make sure you fill out all fields");
		}
		if (!agreedToTerms) {
			return toast.warning("You must read and agree to the terms of service");
		}
		if (!email.includes("@") || !email.includes(".")) {
			return toast.warning("Please enter a valid email");
		}
		if (password.length < 8) {
			return toast.warning("Password must be at least 8 characters");
		}
		if (!name.includes(" ")) {
			return toast.warning("First and last name is required");
		}

		updateRegistrationDetails({email: email})
		updateRegistrationDetails({password: password})
		updateRegistrationDetails({name: name})
		updateRegistrationDetails({agreedToTerms: agreedToTerms})

		proceedUserSignUp()
	}

	const fetchDefaultTradingFlags = (fetchedTradingTypes) => {

	};

	const proceedUserSignUp = () => {
		navigate("/auth/register/company");
	};

	useEffect(() => {
		initialize()
		reset()
		configurePageDefaults();
	},[]);

	return (
		<>
			<AdminAuthLayout
				title="Sign Up"
				text="Join an online community of educators and start educating."
				rightSideComponent={<SliderArea />}
			>
				<form onSubmit={handleSubmit}>
					<div className="mb-4 pb-sm-3 mt-4 mt-sm-5">
						<div className="row g-3">
							<div className="col-6">
								<Input onChange={(e) => setName(e.target.value)} label="Name" />
							</div>
							<div className="col-6">
								<Input onChange={(e) => setEmail(e.target.value)} label="Email" />
							</div>
						</div>
					</div>
					<div className="mb-4 pb-sm-2">
						<Input onChange={(e) => setPassword(e.target.value)} label="Password" type={"password"} />
					</div>
					<div className="mb-4 pb-sm-1">
						<label className="form-check form--check">
							<input
								type="checkbox"
								className="form-check-input"
								checked={agreedToTerms}
								onChange={(e) => setAgreedToTerms(e.target.checked)}
							/>
							<span
								className="form-check-label"
								// style={{ maxWidth: "269px" }}
							>
								I’VE READ AND AGREE WITH{" "}
								<Link className="text-base base-2" to={''}>
									TERMS OF SERVICE
								</Link>{" "}
								AND OUR{" "}
								<Link to={''} className="text-base base-2">PRIVACY POLICY</Link>
							</span>
						</label>
					</div>

					<div className="mb-4">
						<button type="submit" className="btn w-100 cmn-btn  h-69">
							Sign Up
						</button>
					</div>
				</form>
				<div className="mb-4">
					<div className="or">Or sign up with</div>
				</div>
				<div className="d-flex gap-4 mb-4 pb-sm-3 social-wrapper">
					<button className="no-gutter">
						<FacebookIcon />
					</button>
					<button className="no-gutter">
						<GoogleIcon />
					</button>
					<button className="no-gutter">
						<AppleIcon />
					</button>
				</div>
			</AdminAuthLayout>
		</>
	);
};

const SliderArea = () => {
	return (
		<>
			<div className="signal-admin-slider">
				<Swiper
					pagination={{
						clickable: true,
					}}
					modules={[Pagination]}
				>
					{["", "", ""]?.map((item, i) => (
						<SwiperSlide key={i}>
							<div className="signal-slider-img">
								<img src={img} alt="" />
							</div>
							<div className="badge badge-soft-base">
								Updated Signals
							</div>
							<h3 className="title">
								Send Signals To Your Community And Share Insightful
								Predictions.
							</h3>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</>
	);
};

export default AdminSignUp;
