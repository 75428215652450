import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import avatar from "../../../assets/img/avatar.png";
import eagleIcon from "../../../assets/img/eagle-icon.png";
import kingIcon from "../../../assets/img/king-icon.png";
import Header from "../../../components/Header";
import {
	CalendarIcon3,
	CommentsIcon,
	DollarIcon,
	EditIcon,
	EuroIcon,
	FeedActiveIcon,
	FeedIcon,
	HeartFill,
	PinnedIcon,
	PolicyCancelIcon,
	ProfileActiveIcon,
	ProfileAddIcon,
	ProfileIcon,
	SessionActiveIcon,
	SessionIcon,
	SessionPriceIcon,
	VideoIcon,
	VideoIcon2,
} from "../../../components/Icon";
import { APIBaseURL, DemoUserID } from "../../../util/DomainHelper";

const ProfileLayout = ({ hideSidebar, children, onTitleChange }) => {

	const [show, setShow] = React.useState(false);
	const [currentProfile, setCurrentProfile] = useState();

	const params = useParams();
	const navigate = useNavigate();

	const fetchUserProfile = () => {
		const url = APIBaseURL() + `profile/profile/${params.id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.profile;
					if (data != undefined && data != null) {
						setCurrentProfile(data);
						onTitleChange(`Trader | ${data.displayName}`);
					} else {
						console.log('Failed to fetch data');
					};
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchUserProfile()
	},[]);

	// useEffect(() => {
	// 	const newTitle = "Trader Profile";
	// 	onTitleChange(newTitle);
	// }, [onTitleChange]);

	return (
		<>
			<Header logo />
			<div className="d-flex flex-wrap align-items-start gap-3">
			<div
					className={`profile-sidebar pt-4 ${
						hideSidebar ? "d-none d-lg-block" : ""
					}`}
				>
					<div className="d-flex justify-content-between mb-4 pb-1">
						<button
							className="btn btn--secondary border-0 px-3"
							onClick={() => navigate('/')}
						>
							{"< "}Back{" "}
						</button>
						<button
							type="button"
							className="btn px-4 py-2"
							style={{
								border: "1px solid rgba(255, 255, 255, 0.1)",
							}}
						>
							<ProfileAddIcon />
						</button>
					</div>
					<div className="text-center">
						<div className="d-flex align-items-center gap-3">
							<img
								src={currentProfile ? currentProfile.profilePhotoThumbnailURL ?? avatar : ''}
								width={107}
								alt=""
								className="profile-img"
							/>
							<div className="w-0 flex-grow-1">
								<h5 className="name mb-1">{currentProfile ? currentProfile.displayName ?? '' : ''}</h5>
								<div>
									<span>Master Trader</span>
								</div>
								<div className="d-flex gap-2 align-items-center justify-content-center mt-2">
									<img src={kingIcon} width={20} alt="" />
									<img src={eagleIcon} width={20} alt="" />
								</div>
							</div>
						</div>
						<div className="profile-counts">
							<button type="button" className="btn">
								20k Followers
							</button>
							<button type="button" className="btn">
								100 Following
							</button>
						</div>
						<div className="text-start mt-3 mb-30">
							{show ? (currentProfile ? currentProfile.biography ?? '' : '') : (currentProfile ? currentProfile.biography ?? '' : '').substring(0, 163)}...
							<span
								className="cursor-pointer text-base ms-1"
								onClick={() => setShow(!show)}
							>
								{show ? "Read Less" : "Read More"}
							</span>
						</div>
						<div className="badge-area">
							<span className="badge badge-soft-success">{currentProfile ? currentProfile.wins ?? 0 : 0} Wins</span>
							<span className="badge badge-soft-danger">{currentProfile ? currentProfile.losses ?? 0 : 0} Losses</span>
						</div>
						<button className="btn btn--secondary text-base w-100 mt-3 h-44 border-0">
							Book a Session
						</button>
						<ul className="sidebar-session-info py-4">
							<li>
								<div className="left">
									<SessionPriceIcon />
									<span>Sessions starting at</span>
								</div>
								<div className="right">$20</div>
							</li>
							<li>
								<div className="left">
									<CalendarIcon3 />
									<span>Next availability</span>
								</div>
								<div className="right">8:00 pm Today</div>
							</li>
							<li>
								<div className="left">
									<PolicyCancelIcon />
									<span>Cancellation policy</span>
								</div>
								<div className="right text-success">Flexible</div>
							</li>
							<li>
								<div className="left">
									<SessionPriceIcon />
									<span>Currency pair</span>
								</div>
								<div className="right d-flex align-items-center gap-1">
									<DollarIcon /> / <EuroIcon />
								</div>
							</li>
						</ul>
						<div className="pb-4">
							<div className="post-item pinned-card bg-base text-start">
								<div className="pinned-post-header">
									<div>
										<PinnedIcon /> Pinned post
									</div>
								</div>
								<div className="pinned-card-body">
									<div className="d-flex flex-wrap justify-content-between align-items-center mb-3 gap-2 gap-sm-3">
										<Link to="#" className="media __media">
											<img src={avatar} alt="" />
											<div className="media-body">
												<h6 className="text-16 font-semibold m-0 mb-1">
													Jane Cooper
												</h6>
												<small>Top member</small>
											</div>
										</Link>
									</div>
									<p className="normal-text">
										Trade refers to the buying, selling, or exchange
										of goods, services, or commodities between
										individuals, businesses, or countries.
									</p>
									<div className="d-flex flex-wrap gap-2 post-icons">
										<button
											type="button"
											className="no-gutter text-12"
										>
											<div className="text-base">
												<HeartFill />
											</div>{" "}
											<span>24k+</span>
										</button>
										<button
											type="button"
											className="no-gutter text-12"
										>
											<CommentsIcon /> <span>136 Comments</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w-0 flex-grow-1 pe-lg-4 px-xxl-38 pt-4">
					<div className="profile-card">
						<div className="profile-card-header">
							<ul className="nav-menu">
								<li>
									<NavLink to={`/profile/${params.id}/about`}>
										<span className="inactive">
											<ProfileIcon />
										</span>
										<span className="active">
											<ProfileActiveIcon />
										</span>
										<span>About</span>
									</NavLink>
								</li>
								<li>
									<NavLink to={`/profile/${params.id}/feed`}>
										<span className="inactive">
											<FeedIcon />
										</span>
										<span className="active">
											<FeedActiveIcon />
										</span>
										<span>Feed</span>
									</NavLink>
								</li>
								<li>
									<NavLink to={`/profile/${params.id}/session`}>
										<span className="inactive">
											<SessionIcon />
										</span>
										<span className="active">
											<SessionActiveIcon />
										</span>
										<span>Sessions</span>
									</NavLink>
								</li>
								<li>
									<NavLink to={`/profile/${params.id}/video`}>
										<span className="inactive">
											<VideoIcon />
										</span>
										<span className="active">
											<VideoIcon2 />
										</span>
										<span>Video</span>
									</NavLink>
								</li>
							</ul>
							<button
								type="button"
								className="btn btn--secondary border-0"
							>
								Edit <EditIcon />
							</button>
						</div>
						<div className="profile-card-body">
							<Outlet />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const biotext = `As one of the world's most knowledgeable and technically proficient Zelda players, and one of the main contributors to Izaw's "The Art of Zelda'quide, I've pioneered and technically proficient Zelda players, and one of the main contributors to Izaw's "The Art of Zelda'quide, I've pioneered`;

export default ProfileLayout;
