import React from "react";
import AudioPlayer from "../../components/AudioPlayer";
import Input from "../../components/Input";
import InputSwitch from "../../components/InputSwitch";
import SettingsLayout from "./components/SettingsLayout";

const VoiceAndVideo = () => {
	return (
		<>
				<div className="d-flex gap-3 align-items-center justify-content-between">
					<h5 className="setting-title m-0">Voice Settings</h5>
				</div>
				<br />
				<br />
				<div className="notify-group">
					<div className="row">
						<div className="col-md-6">
							<div className="notify-group-inner p-0 bg-transparent text-white">
								<Input
									label={"Input Device"}
									options={[
										{ value: "0", name: "(Default)" },
										{ value: "1", name: "Stripe" },
										{ value: "2", name: "SSLCommerz" },
									]}
								/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="notify-group-inner p-0 bg-transparent text-white">
								<Input
									label={"Output Device"}
									options={[
										{ value: "0", name: "(Default)" },
										{ value: "1", name: "Stripe" },
										{ value: "2", name: "SSLCommerz" },
									]}
								/>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Mic Test</h6>
					<AudioPlayer url={"/audio.mp3"} />
				</div>
				<br />
				<hr />
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Input Mode</h6>
					<div className="d-flex justify-content-between gap-3">
						<div className="col-6">
							<div className="language-radio-item style-2">
								<label className="form-check form--radio">
									<input
										type="radio"
										className="form-check-input"
										name="voice"
									/>
									<span className="form-check-label text-16">
										Voice Activity
									</span>
								</label>
							</div>
						</div>
						<div className="col-6">
							<div className="language-radio-item style-2">
								<label className="form-check form--radio">
									<input
										type="radio"
										className="form-check-input"
										name="voice"
									/>
									<span className="form-check-label text-16">
										Push to Talk
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<br />
				<hr />
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Video Settings</h6>
					<div
						className="notify-group-inner d-flex align-items-end justify-content-center p-4"
						style={{ height: "192px" }}
					>
						<button className="btn btn--secondary border-0 btn--danger h-45 px-3">
							Check Video
						</button>
					</div>
				</div>
				<br />
				<div className="d-flex flex-wrap align-items-center justify-content-between">
					<span>Always preview video</span>
					<InputSwitch value={true} />
				</div>
				<br />
				<div className="notify-group">
					<h6 className="subtitle mb-3">Camera</h6>
					<div className="notify-group-inner p-0 bg-transparent text-white">
						<Input
							options={[
								{ value: "0", name: "(Default)" },
								{ value: "1", name: "Stripe" },
								{ value: "2", name: "SSLCommerz" },
							]}
						/>
					</div>
				</div>
				<br />
				<hr />
				<br />
				<div className="notify-group">
					<h6 className="subtitle mb-3">Advanced</h6>
					<div className="d-flex flex-wrap align-items-center justify-content-between">
						<span>Echo Cancelling</span>
						<InputSwitch value={true} />
					</div>
					<br />
					<br className="d-none d-md-block" />
					<h6 className="subtitle mb-3">Noise Suppression</h6>
					<div className="d-flex flex-column gap-2">
						<div className="language-radio-item style-2">
							<label className="form-check form--radio">
								<input
									type="radio"
									className="form-check-input"
									name="voice"
								/>
								<span className="form-check-label text-16">
									Standard
								</span>
							</label>
						</div>
						<div className="language-radio-item style-2">
							<label className="form-check form--radio">
								<input
									type="radio"
									className="form-check-input"
									name="voice"
								/>
								<span className="form-check-label text-16">Krisp</span>
							</label>
						</div>
						<div className="language-radio-item style-2">
							<label className="form-check form--radio">
								<input
									type="radio"
									className="form-check-input"
									name="voice"
								/>
								<span className="form-check-label text-16">None</span>
							</label>
						</div>
					</div>
				</div>
				<br />
				<div className="d-flex flex-wrap align-items-center justify-content-between">
					<span>Automatic Gain Control</span>
					<InputSwitch value={true} />
				</div>
				<br />
				<hr />
				<button className="btn btn--secondary border-0 btn--danger h-45 px-3 mt-4">
					Reset Voice Setting
				</button>
		</>
	);
};

export default VoiceAndVideo;
