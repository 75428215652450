import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../components/Icon";
import Input from "../../components/Input";
import CustomerAuthLayout from "./components/CustomerAuthLayout";
import { APIBaseURL } from "../../util/DomainHelper";
import { CurrentCompanyConfiguration } from "../../util/CompanyHelper";
import {toast} from "react-toastify";

const CustomerSignIn = ({ onTitleChange }) => {

	const navigate = useNavigate();

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();

	const [companyConfiguration, setCompanyConfiguration] = useState();

	const fetchCompanyConfiguration = async () => {
		const fetchedCompanyConfig = await CurrentCompanyConfiguration();
		if (fetchedCompanyConfig) {
			setCompanyConfiguration(fetchedCompanyConfig);
		}
	};

	function handleEmailChange(e) {
		setEmail(e.target.value)
	}

	function handlePasswordChange(e) {
		setPassword(e.target.value)
	}

	const tryUserSignIn = async () => {

		console.log('email', email);
		console.log('password', password);

		if (!email || !password) {
			console.log('Please fill out required fields');
			return;
		}

		try {
			const url = APIBaseURL() + `auth/login`;

			fetch(url, {
				method: 'POST',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({email: email, password: password})
			}).then(async (response) => {
				const responseJSON = await response.json();
				if (response.ok) {
					const auth = responseJSON.auth ?? false;
					const user = responseJSON.user;

					if (auth === true && user) {
						toast.success(`Welcome back, ${user.firstName ?? 'Valued customer'}. You've been logged in successfully.`)
						localStorage.setItem('user', JSON.stringify(user));
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					} else {
						// Navigate to verify page
						navigate(`/signin/verification/${user.validation ?? ''}`);
					}
				} else {
					const errorMessage = responseJSON.message ?? '';

					toast.error(errorMessage)
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetchCompanyConfiguration();
	},[]);

	useEffect(() => {
		const newTitle = "Sign In";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	return (
		<>
			<CustomerAuthLayout nowrwapper>
				<div className="customer-auth-login-wrapper">
					<div className="customer-auth-login-wrapper-inner">
						<Link to="#" className="logo">
							<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logo) : logo} alt="" />
						</Link>
						<div className="mb-4">
							<Input label="Email" onChange={handleEmailChange}/>
						</div>
						<div className="mb-4">
							<Input label="Password" type={"password"} onChange={(e) => handlePasswordChange(e)}/>
						</div>
						<div className="mb-4">
							<button onClick={() => tryUserSignIn()}
								type="submit"
								className="btn w-100 cmn-btn base h-69"
							>
								Sign In
							</button>
						</div>
						<div className="mb-4">
							<div className="or">Or sign in with</div>
						</div>
						<div className="d-flex gap-4 mb-4 pb-sm-3 social-wrapper">
							<button className="no-gutter">
								<FacebookIcon />
							</button>
							<button className="no-gutter">
								<GoogleIcon />
							</button>
							<button className="no-gutter">
								<AppleIcon />
							</button>
						</div>
						<div className="text-center">
							Don’t have an account?{" "}
							<Link
								to="/register"
								className="text-base base-2 font-bold text-lg"
							>
								Sign Up
							</Link>
						</div>
					</div>
				</div>
			</CustomerAuthLayout>
		</>
	);
};

export default CustomerSignIn;
