import React from "react";
import img2 from "../../assets/img/plan/plan-bg-2.png";
import { QuestionMark } from "../../components/Icon";
import Input from "../../components/Input";
import SettingsLayout from "./components/SettingsLayout";

const Subscriptions = () => {
	return (
		<>
				<div className="d-flex gap-3 align-items-center justify-content-between">
					<h5 className="setting-title m-0">Your Subscriptions</h5>
				</div>
				<br />
				<p>
					These are your current subscriptions. They will be billed on the
					same cycle. You can update any subscription at any time.
				</p>
				<br />
				<div
					className={`card card-2`}
					style={{
						background: `url(${img2}) no-repeat center center / cover`,
					}}
				>
					<div className="card-body gap-3">
						<div>
							<div className="card-title">
								Tradessly <span className="badge">PRO</span>
							</div>
							<div className="card-text">$99.99 / Year</div>
						</div>
						<div>
							<button type="button" className={`plan-btn`}>
								Edit Subscription
							</button>
						</div>
					</div>
				</div>
				<br />
				<br />
				<div className="notify-group">
					<h6 className="subtitle">Payment</h6>
					<div className="row g-3">
						<div className="col-md-6">
							<div className="notify-group-inner h-100">
								<div>
									<h6 className="font-18 font-medium pt-2 pb-2">
										Billing Information
									</h6>
									<div className="text-14 text-white">
										Your subscription will end on June 10, 2024
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="notify-group-inner h-100">
								<div>
									<h6 className="font-18 font-medium">Pay with</h6>
									<div>
										<Input
											options={[
												{ value: "0", name: "(Default)" },
												{ value: "1", name: "Stripe" },
												{ value: "2", name: "SSLCommerz" },
											]}
										/>
									</div>
									<div className="pt-3">
										<small>Applies to all subscriptions</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<br />
				<br />
				<p className="pb-2">
					When you have a gift while you have an active subscription, or
					accept a gift that differs from your current subscription, it
					will appear here.
				</p>
				<div className="notify-group">
					<div className="notify-group-inner d-flex align-items-center gap-3">
						<QuestionMark />
						<div>You have no unused credits</div>
					</div>
				</div>
		</>
	);
};

export default Subscriptions;
