import { io } from "socket.io-client";
import { WebsocketBaseURL } from "./DomainHelper";

var connectionOptions = {
    "force new connection": true,
    // reconnectionAttempts: "Infinity",
    timeout: 10000,
    transports: ["websocket"],
};

export const socket = io(WebsocketBaseURL(), connectionOptions);