import React from "react";
import { CalendarIcon4, ProIcon } from "./Icon";

const StandardSignalCard4 = ({ props }) => {


	return (
		<>
			<div className="signal-widget extra-signal-widget extra-signal-widget-4">
				<div className="pro-title">
					<span>PRO</span>
					<ProIcon />
				</div>
				<div className="signal-widget-wrapper">
					<div className="d-flex flex-wrap align-items-center justify-content-center pt-3 gap-1 pb-3 timestamp">
						<CalendarIcon4 />
						<span
							className="font-semibold"
							style={{ color: "#212128", opacity: "0.5" }}
						>
							12 November 2022 12:57
						</span>
					</div>
					<h5 className="text-center d-flex align-items-center justify-content-center gap-2 mb-3">
						<span>{props ? (props.trade_idea ? props.trade_idea.symbol : props ? props.symbol : '') : ''}</span> <img src="/flags/check.svg" alt="" />{" "}
					</h5>
					<div className="d-flex justify-content-center currencies-tab">
						{/*<button className="no-gutter">EUR</button>*/}
						<button className="no-gutter active">{props ? (props.trade_idea ? (props.trade_idea.execution_category).toUpperCase() : props ? (props.execution_category).toUpperCase() : '') : ''}</button>
					</div>
					<div className="pt-4 d-flex justify-content-between info-texts">
						<div className="me-auto">
							<div className="mb-2">
								<h6 className="s-title">47523</h6>
								<small>Signal ID</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">{props ? (props.trade_idea ? props.trade_idea.execution_type : props ? props.execution_type : '').toUpperCase() : ''}</h6>
								<small>Trade Style</small>
							</div>
							<div className="mb-2">
								{(props ? (props.trade_idea ? (props.trade_idea.entries ? props.trade_idea.entries : []) : props ? (props.entries ? props.entries : []) : []) : []).map((entry, index) => {
									return (
										<div className="mb-2" key={index}>
											<h6 className="s-title">{props ? entry.value : ''}</h6>
											<small>Entry {index + 1}</small>
											<button style={{marginLeft: '10px'}}
												// onClick={() => copyValue(entry.value)}
													className="d-inline-block btn btn-secondary"
													type="button"
											>
												Copy
											</button>
										</div>
									)
								})}

								{/*<small>Signal ID</small>{" "}*/}
								{/*<button*/}
								{/*	className="d-inline-block btn btn-secondary"*/}
								{/*	type="button"*/}
								{/*>*/}
								{/*	Copy*/}
								{/*</button>*/}
							</div>
						</div>
						<div className="mx-auto">
							<div className="mb-2">
								{(props ? (props.trade_idea ? (props.trade_idea.values_1 ? props.trade_idea.values_1 : []) : props && (props.values_1 ? props.values_1 : [])) : []).map((entry, index) => {
									return (
										<div className="mb-2" key={index}>
											<h6 className="s-title">{entry.value}</h6>
											<small>TP {index + 1}</small>{" "}
											<button
												style={{marginLeft: '10px'}}
												// onClick={() => copyValue(entry.value)}
												className="d-inline-block btn btn-secondary"
												type="button"
											>
												Copy
											</button>
										</div>
									)
								})}
								{/*<h6 className="s-title">1.19</h6>*/}
								{/*<small>TP</small>*/}
							</div>
							<div className="mb-2">
								{(props ? (props.trade_idea ? (props.trade_idea.values_2 ? props.trade_idea.values_2 : []) : props ? (props.values_2 ? props.values_2 : []) : []) : []).map((entry, index) => {
									return (
										<div className="mb-2" key={index}>
											<h6 className="s-title">{entry.value}</h6>
											<small>SL {index + 1}</small>{" "}
											<button
												style={{marginLeft: '10px'}}
												// onClick={() => copyValue(entry.value)}
												className="d-inline-block btn btn-secondary"
												type="button"
											>Copy
											</button>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StandardSignalCard4;
