import { useState } from "react";
import { Eye, EyeSlash } from "./Icon";

const Input = ({
				   icon,
				   label,
				   type,
				   eye,
				   value,
				   textarea,
				   height,
				   secondaryValue,
				   required,
				   options,
				   endText,
					onChange,
				   ...rest
			   }) => {
	const [inputType, setInputType] = useState(type);

	if (options?.length > 0)
		return (
			<>
				<div>
					{label && (
						<label className="form-label form--label">
							{label}{" "}
							{required === "true" && (
								<span style={{ color: "red" }}>*</span>
							)}
						</label>
					)}
					<div className="position-relative">
						{icon && <span className="input-left-icon">{icon}</span>}
						<select
							onChange={(e) => onChange(e.target.value)}
							required
							className="form-select form-control-2"
							style={{ height: height ? height : "" }}
							{...rest}
						>
							{options?.map((item, i) => (
								<option key={i} value={item?.value}>
									{item?.name}
								</option>
							))}
						</select>
					</div>
				</div>
			</>
		);
	return (
		<div>
			{label && (
				<label className="form-label form--label">
					{label}{" "}
					{required == "true" && <span style={{ color: "red" }}>*</span>}
				</label>
			)}
			<div className="position-relative">
				{textarea ? (
					<textarea
						required
						onChange={onChange}
						className="form-control form-control-2 pt-2"
						type={inputType ? inputType : "text"}
						{...rest}
						value={value}
						style={{ height: height ? height : "" }}
					></textarea>
				) : (
					<>
						<input
							required={required}
							className={`form-control form-control-2 ${
								type === "password" ? "password" : ""
							} ${endText ? "pe-5" : ""}`}
							type={inputType ? inputType : "text"}
							{...rest}
							value={value}
							onChange={onChange}
							style={{
								opacity: secondaryValue ? "0" : "",
								position: "relative",
								zIndex: "3",
								height: height ? height : "",
							}}
						/>
						{secondaryValue && (
							<input
								className={`form-control form-control-2 ${
									type === "password" ? "password" : ""
								}`}
								style={{
									position: "absolute",
									left: "0",
									top: "0",
									height: height ? height : "",
								}}
								value={
									secondaryValue === "() -" ? "Phone" : secondaryValue
								}
							/>
						)}
					</>
				)}
				{icon && <span className="input-left-icon">{icon}</span>}
				{endText && <span className="input-right-text">{endText}</span>}
				{type === "password" &&
					(inputType === "password" ? (
						<span
							className="eye"
							onClick={() => {
								if (inputType === "password") {
									setInputType("text");
								} else {
									setInputType("password");
								}
							}}
						>
							<Eye />
						</span>
					) : (
						<span
							className="eye"
							onClick={() => {
								if (inputType === "password") {
									setInputType("text");
								} else {
									setInputType("password");
								}
							}}
						>
							<EyeSlash />
						</span>
					))}
			</div>
		</div>
	);
};

export default Input;
