import React, {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import Input from "../../components/Input";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import {useNavigate} from "react-router-dom";

const CourseLanding = () => {

	const { updateCourseDetails,
		curriculums,
		insertCurriculum,
		removeCurriculum,
		fetchCourse, courseDetails, updateLessonData } = useCreateCourse();

	const navigate = useNavigate();

	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");

	const [currentCourseDetails, setCurrentCourseDetails] = useState();

	function handleCourseThumbnailSelection(e) {

	}

	function handleCoursePromotionalVideoSelection(e) {

	}

	function handleNextTriggered() {
		navigate(`/course-plan/pricing`)
	}

	useEffect(() => {
		const fetchedCourseDetails = courseDetails()
		console.log('fetchedCourseDetails', fetchedCourseDetails)
		setCurrentCourseDetails(fetchedCourseDetails)
	},[])

	return (
		<CoursePlanLayout>
			{ currentCourseDetails && (
				<>
					<div>
						<h5 className="title">Course Overview</h5>
						<p className="txt pb-md-3">
							Your course landing page is crucial. If it’s done right, it can
							also help you gain visibility in search engines like Google. As
							you complete this section, think about creating a compelling
							Course Landing Page that demonstrates why someone would want to
							enroll in your course. Learn more about creating your course
							landing page and course title standards.
						</p>
						<hr
							className="pb-md-3"
							style={{
								borderWidth: "2px",
								opacity: "1",
								borderColor: "#88869F",
							}}
						/>
						<h5 className="subtitle mb-2">Course Title</h5>
						<div className="example-inputs text-text">
							<Input
								value={currentCourseDetails ? currentCourseDetails.course_title : ''}
								onChange={(e) => updateCourseDetails({course_title: e.target.value})}
								placeholder="Title" height={"47px"} endText={55}/>
							<small className="input-small-txt">
								Your title should be a mix of attention-grabbing, informative,
								and optimized for search
							</small>
							<br/>
						</div>
						<h5 className="subtitle mb-2">Course Subtitle</h5>
						<div className="example-inputs text-text">
							<Input
								value={currentCourseDetails ? currentCourseDetails.course_subtitle : ''}
								onChange={(e) => updateCourseDetails({course_subtitle: e.target.value})}
								placeholder="Course Subtitle"
								height={"47px"}
								endText={120}
							/>
							<small className="input-small-txt">
								Use 1 or 2 related keywords, and mention 3-4 of the most
								important areas that you've covered during your course.
							</small>
							<br/>
						</div>
						<h5 className="subtitle">Course Description</h5>
						<ReactQuill
							theme="snow"
							defaultValue={currentCourseDetails ? currentCourseDetails.course_description : ''}
							// value={value}
							onChange={(e) => updateCourseDetails({course_description: e})}
							placeholder="Course Description"
						/>
						<small className="input-small-txt mt-1">
							Description should have minimum 200 words.
						</small>
						<br/>
						{/*<h5 className="subtitle">Congratulations Message</h5>*/}
						{/*<ReactQuill theme="snow" value={value2} onChange={setValue2} />*/}
						{/*<br />*/}
						<h5 className="subtitle">Basic Info</h5>
						<div className="price-wrapper d-flex flex-wrap gap-4 justify-content-between">
							<div className="item">
								<div className="example-inputs">
									<Input
										defaultValue={currentCourseDetails ? currentCourseDetails.course_language : ''}
										onChange={(e) => updateCourseDetails({course_language: e})}
										options={[
											{
												name: "Select a language",
												value: "",
											},
											{
												name: "English",
												value: "en",
											},
											{
												name: "Spanish",
												value: "es",
											},
											{
												name: "Chinese (Simplified)",
												value: "cs",
											},
											{
												name: "Other",
												value: "other",
											},
										]}
										height={"47px"}
									/>
								</div>
							</div>
							<div className="item">
								<div className="example-inputs">
									<Input
										defaultValue={currentCourseDetails ? currentCourseDetails.course_level : ''}
										onChange={(e) => updateCourseDetails({course_level: e})}
										options={[
											{
												name: "Select Skill Level",
												value: "",
											},
											{
												name: "Beginner",
												value: "beginner",
											},
											{
												name: "Standard",
												value: "standard",
											},
											{
												name: "Intermediate",
												value: "intermediate",
											},
											{
												name: "Expert",
												value: "expert",
											},
										]}
										height={"47px"}
									/>
								</div>
							</div>
							<div className="item">
								<div className="example-inputs">
									<Input
										defaultValue={currentCourseDetails ? currentCourseDetails.course_focus_area : ''}
										onChange={(e) => updateCourseDetails({course_focus_area: e})}
										options={[
											{
												name: "Select Area (Optional)",
												value: "",
											},
											{
												name: "Crypto Trading",
												value: "crypto",
											},
											{
												name: "Forex Trading",
												value: "forex",
											},
											{
												name: "Futures",
												value: "futures",
											},
											{
												name: "Stock Market Trading",
												value: "stock-market",
											},
										]}
										height={"47px"}
									/>
								</div>
							</div>
						</div>
						<br/>
						<h5 className="subtitle">
							What is primarily taught in your course?
						</h5>
						<div className="example-inputs text-text">
							<div className="row">
								<div className="col-md-6">
									<Input
										value={currentCourseDetails ? currentCourseDetails.course_focus : ''}
										placeholder="e.g. Crypto Trading" height={"47px"}
										onChange={(e) => updateCourseDetails({course_focus: e.target.value})}/>
								</div>
							</div>
						</div>
						<br/>

						<h5 className="subtitle">Course Thumbnail</h5>
						<div className="row example-inputs align-items-center">
							{/*<div className="col-md-6">*/}
							{/*	<Input textarea height={"249px"} />*/}
							{/*</div>*/}
							<div className="col-md-6">
								<label className="mb-3 file-label-txt">
									Upload your course image here. It must meet our course
									image quality standards to be accepted. Important
									guidelines: 750x422 pixels; .jpg, .jpeg,. gif, or .png. no
									text on the image.
								</label>
								<div className="file-input">
									<div className="file-input-input">
										<input type="file" id="basic" onChange={handleCourseThumbnailSelection}/>
									</div>
									<label className="file-input-label" htmlFor="basic">
										Upload File
									</label>
								</div>
							</div>
						</div>
						<br/>
						<h5 className="subtitle">Promotional Video</h5>
						<div className="row example-inputs align-items-center">
							{/*<div className="col-md-6">*/}
							{/*	<Input textarea height={"249px"} />*/}
							{/*</div>*/}
							<div className="col-md-6">
								<label className="mb-3 file-label-txt">
									Upload your course image here. It must meet our course
									image quality standards to be accepted. Important
									guidelines: 750x422 pixels; .jpg, .jpeg,. gif, or .png. no
									text on the image.
								</label>
								<div className="file-input">
									<div className="file-input-input">
										<input type="file" id="promotional"
											   onChange={handleCoursePromotionalVideoSelection}/>
									</div>
									<label className="file-input-label" htmlFor="promotional">
										Upload File
									</label>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex w-100 mt-md-3 gap-4">
						<button
							className="cmn-btn btn-outline px-4 mt-3"
							onClick={() => navigate('/course-plan/intended-learners')}
						>
							Back
						</button>
						<button
							className="cmn-btn px-4 mt-3"
							onClick={() => handleNextTriggered()}
						>
							Save & Continue
						</button>
					</div>
				</>
			)}
		</CoursePlanLayout>
	);
};

export default CourseLanding;
