import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../../assets/img/course-author.png";
import { RealEstateIcon } from "../../components/Icon";
const CompletedModal = ({ show, setShow }) => {
	return (
		<>
			<Modal
				show={show}
				onHide={setShow}
				className="course-completed-modal"
				centered
			>
				<Modal.Body>
					<div className="course-completed-header text-center">
						<h2 className="title">Congratulations!</h2>
						<h5 className="subtitle">
							You have completed The Real Estate Agent Academy course and
							can now add it to your profile.
						</h5>
					</div>
					{/* Info */}
					<div className="course-completed-info">
						<img src={img} className="img" alt="" />
						<div className="content">
							<div className="content-top">
								<h3 className="sub-text">
									The Real Estate Agent Academy
								</h3>
								<span className="text-base name">Graham Stephan:</span>
								<blockquote>
									“Now you are ready to start leveraging real estate
									for profit. Flipping houses is a mix of intuition and
									numbers, start taking risks and I promise you it will
									pay off.”
								</blockquote>
							</div>
							<div className="d-flex align-items-center gap-3">
								<RealEstateIcon />
								<div className="w-0 flex-grow-1 font-semibold fz-15">
									Real Estate Badge Added to Profile
								</div>
							</div>
						</div>
					</div>
					<div className="text-center pb-4 pb-xxl-5 pt-5">
						<Link to="#" className="cmn-btn">
							Go to Profile
						</Link>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default CompletedModal;
