/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {ArrowLeft, CategoryIcon, ClearIcon2, CreateNewIcon, SettingIcon} from "../../../components/Icon";
import { CaretIcon, MembersIcon, MessageIcon, VoiceIcon } from "./TradingIcons";
import globeTradeImg from "./img/globe-trade-img.png";
import img1 from "./img/sidebar-bottom-icons/1.png";
import img2 from "./img/sidebar-bottom-icons/2.png";
import img3 from "./img/sidebar-bottom-icons/3.png";
import img4 from "./img/sidebar-bottom-icons/4.png";
import img5 from "./img/sidebar-bottom-icons/5.png";
import voiceChannelImage1 from "./img/voice-channel/1.png";
import voiceChannelImage2 from "./img/voice-channel/2.png";
import { APIBaseURL, CurrentCompany } from "../../../util/DomainHelper";

const TradeRoomsSidebar = ({ open, setOpen, tradeRooms, selectedRoom, preselectedRoom, selectedIndex, handleCreateNewTradingRoom }) => {

	// const [tradeRooms, setTradeRooms] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const fetchTradeRooms = async () => {
		// const company = await CurrentCompany();
		// const url = APIBaseURL() + `trading-rooms/rooms/${company._id}`;
		// try {
		// 	fetch(url, {
		// 		method: 'GET',
		// 		headers: {
		// 		'origin': '*',
		// 		'x-requested-with': '*',
		// 		'Content-Type': 'application/json'
		// 		}
		// 	}).then(async (response) => {
		// 		if (response.ok) {
		// 			const responseJSON = await response.json();
		// 			const data = responseJSON.trading_rooms;
		// 			if (data != undefined && data != null) {
		// 				setTradeRooms(data);
		// 			} else {
		// 				console.log('Failed to fetch data');
		// 			};
		// 		}
		// 	})
		// } catch(error) {
		// 	console.log(error);
		// }
	};

	const userSelectedNewRoom = (e) => {
		selectedRoom(e);
	};

	useEffect(() => {
		// fetchTradeRooms();
	},[]);

	return (
		<>
			<div style={{ height: '100vh'}} className={`trade-rooms-sidebar ${open ? "active" : ""}`}>
				<div className="trade-rooms-sidebar-inner">
					<div className="title-area position-relative">
						<div  className={'justify-content-between d-flex align-items-start'}>
							<div>
								<h5 className="title mt-2">Trading Rooms</h5>
								{/*<button*/}
								{/*	className="no-gutter d-xl-none abs-right align-top"*/}
								{/*	onClick={() => setOpen(!open)}*/}
								{/*>*/}
								{/*	<ArrowLeft/>*/}
								{/*</button>*/}
							</div>
							<button
								onClick={() => handleCreateNewTradingRoom()}
								className="no-gutter"
								type="button"
							>
								<CreateNewIcon/>
							</button>
						</div>


					</div>
					<div className="trade-rooms-menu">
					{tradeRooms && tradeRooms.map((item, index) => (
							<RoomItem item={item} key={index} initialOpen={index === 0}
									  selectedRoom={userSelectedNewRoom} preselectedRoom={preselectedRoom}
									  parentIndex={index} rooms={tradeRooms} selectedParentIndex={selectedIndex}/>
						))}
						<hr/>
						{/*{voiceChannel.map((item, index) => (*/}
						{/*	<RoomItem item={item} key={index} parentIndex={selectedIndex} rooms={tradeRooms} />*/}
						{/*))}*/}
					</div>
					<div className="rooms-sidebar-bottom" >
						<div className="top-part">
							<img src={globeTradeImg} alt="" />
							<div>
								<h6 className="subtitle">Global Trade</h6>
								<Link to="#" className="text-14 text-text">
									#globaltrade
								</Link>
							</div>
							<div className="ms-auto">
								<button type="button" className="cmn-btn">
									Join Voice
								</button>
							</div>
						</div>
						<div className="bottom-part">
							<div className="d-flex flex-wrap justify-content-center gap-14px">
								{attachmentImages.map((item, index) => (
									<div
										className="rooms-sidebar-bottom-item"
										key={index}
									>
										<button type="button" className="no-gutter">
											<img src={item.img} alt="" />
										</button>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const RoomItem = ({ item, rooms, initialOpen, selectedRoom, allTradeRooms, preselectedRoom, parentIndex, selectedParentIndex }) => {

	const [menuOpen, setMenuOpen] = React.useState(true);
	const navigate = useNavigate();
	const params = useParams();

	const handleClickMenu = (e, submenu) => {
		if (submenu) {
			e.preventDefault();
			setMenuOpen(!menuOpen)
		}
	};

	const navigateToTradingRoom = (subitem) => {

		selectedRoom(subitem);

	};

	useEffect(() => {
		// if (item.category) {
		// 	item.rooms && setMenuOpen(false);
		// 	item.rooms && item.rooms.forEach((subitem) => {
		// 		if (subitem._id === params.id) {
		// 			setMenuOpen(true);
		// 		} else {
		// 			setMenuOpen(false);
		// 		}
		// 	});
		// }

	}, [window.location.pathname]);

	useEffect(() => {
		// console.log(getQueryParam('room'));
	});

	console.log(window.location.pathname)

	const getQueryParam = (paramName) => {
		const queryString = window.location.search;
		const paramIndex = queryString.indexOf(`${paramName}=`);

		if (paramIndex !== -1) {
		  const startIndex = paramIndex + paramName.length + 1; // Add 1 to skip '='
		  const endIndex = queryString.indexOf('&', startIndex) !== -1 ? queryString.indexOf('&', startIndex) : undefined;
		  const paramValue = endIndex !== undefined ? queryString.substring(startIndex, endIndex) : queryString.substring(startIndex);
		  return paramValue;
		} else {
		  return null;
		}
	};

	console.log('Selection:',selectedParentIndex, parentIndex)

	return (
		<>
			{ item && (
				<div className={`menu-item `}>
					<NavLink
						to={item.url}
						// to={item.url._id + "?room=" + (item.submenu && (item.submenu ?? []).length > 0 ? item.submenu[0].room._id ?? '' : '')}
						className={`d-flex align-items-center gap-2 ${parentIndex === selectedParentIndex ? 'parent-menu-link-active' : 'parent-menu-link'}`}
						onClick={(e) => handleClickMenu(e, item)}
					>
					<span className="caret">
						<CaretIcon/>
					</span>
						<span>{item.category ? item.category.title : ''}</span>
						<span className="ms-auto">
						<SettingIcon/>
					</span>
					</NavLink>
					{(item.rooms ?? []).length > 0 && item.rooms && (
						<Collapse in={menuOpen}>
							<div className="submenu">
								<div className="submenu-inner">
									{item.rooms?.map((subitem, index) => (
										<div
											className={`d-flex justify-content-between align-items-center ${getQueryParam('room') === subitem._id ? 'submenu-item-active' : 'submenu-item'} gap-2 ms-3`}>
											<Link
												onClick={() => navigateToTradingRoom(subitem)}
												to={"/trading-rooms/" + item.category._id + "?room=" + subitem._id}
											>
												<div className="d-flex align-items-center gap-1 flex-grow-1"
													 style={{cursor: 'pointer'}}>
													{subitem.img && (
														<img src={subitem.img} className="me-2" alt=""/>
													)}
													{subitem.icon && <span>{subitem.icon}</span>}
													{subitem.title}
													{subitem.members && (
														<span className="members-count">
													{subitem.members}
												</span>
													)}
													<span className="ms-auto">
												{subitem?.mesageOption || subitem?.voiceOption}
											</span>
													<button type="button" className="no-gutter">
														{subitem?.voiceOption && <VoiceIcon/>}
													</button>
													<button type="button" className="no-gutter">
														{subitem?.mesageOption && (
															<MessageIcon color="#282834"/>
														)}
													</button>
												</div>
											</Link>

											{/* <NavLink
											onClick={() => navigateToTradingRoom(subitem)}
											to={"/trading-rooms/" + item.category._id + "?room=" + subitem._id}
											key={index}
											className={
												"d-flex align-items-center gap-1 flex-grow-1"
											}
										>
											{subitem.img && (
												<img
													src={subitem.img}
													className="me-2"
													alt=""
												/>
											)}
											{subitem.icon && <span>{subitem.icon}</span>}
											{subitem.title}
											{subitem.members && (
												<span className="members-count">
													{subitem.members}
												</span>
											)}
											<span className="ms-auto">
												{subitem?.mesageOption ||
													subitem?.voiceOption || <SettingIcon />}
											</span>
											<button type="button" className="no-gutter">
												{subitem?.voiceOption && <VoiceIcon />}
											</button>
											<button type="button" className="no-gutter">
												{subitem?.mesageOption && (
													<MessageIcon color="#282834" />
												)}
											</button>
										</NavLink> */}
										</div>
									))}
								</div>
							</div>
						</Collapse>
					)}
				</div>
			)}
		</>
	);
};

const menu = [
	{
		title: "🎉 Announcement",
		url: "/trading-rooms/announcement",
		submenu: [
			{
				name: "Trade Idea",
				url: "/trading-rooms/announcement/trade-idea",
			},
			{
				name: "Promotions",
				url: "/trading-rooms/announcement/promotions",
			},
		],
	},
	{
		title: "Support",
		url: "/trading-rooms/support",
		submenu: [
			{
				name: "Trade Idea",
				url: "/trading-rooms/support/trade-idea",
			},
			{
				name: "Promotions",
				url: "/trading-rooms/support/promotions",
			},
		],
	},
	{
		title: "Trade",
		url: "/trading-rooms/trade",
		submenu: [
			{
				name: "Trade Idea",
				url: "/trading-rooms/trade/trade-idea",
			},
			{
				name: "Promotions",
				url: "/trading-rooms/trade/promotions",
			},
		],
	},
	{
		title: "Categories",
		url: "/trading-rooms/categories",
		submenu: [
			{
				name: "Trade Idea",
				url: "/trading-rooms/categories/trade-idea",
				members: "3 Member",
				icon: <MembersIcon/>,
			},
			{
				name: "Global Trade Team ",
				url: "/trading-rooms/categories/global-trade-team",
				members: "33 Member",
			},
			{
				name: "Expert Trade Team ",
				url: "/trading-rooms/categories/Expert-Trade-Team",
				members: "33 Member",
			},
		],
	},
	{
		title: "# General",
		url: "/trading-rooms/genaral",
		submenu: [
			{
				name: "# Live Stream",
				url: "/trading-rooms/genaral/live-stream",
			},
		],
	},
	{
		title: "Trade services",
		url: "/trading-rooms/trade-services",
		submenu: [
			{
				name: "# Live Stream",
				url: "/trading-rooms/trade-services/live-stream",
			},
		],
	},
	{
		title: "Trade link",
		url: "/trading-rooms/trade-link",
		submenu: [
			{
				name: "# Live Stream",
				url: "/trading-rooms/trade-link/live-stream",
			},
		],
	},
];
const voiceChannel = [
	{
		title: "Voice Channel",
		url: "/trading-rooms/voice-channel",
		submenu: [
			{
				name: "Trade services",
				url: "/trading-rooms/voice-channel/trade-services",
				img: voiceChannelImage1,
				mesageOption: true,
				voiceOption: true,
			},
			{
				name: "Trade services",
				url: "/trading-rooms/voice-channel/trade-services1",
				img: voiceChannelImage2,
				mesageOption: true,
				voiceOption: true,
			},
			{
				name: "Trade services",
				url: "/trading-rooms/voice-channel/trade-services2",
				img: voiceChannelImage2,
				mesageOption: true,
				voiceOption: true,
			},
			{
				name: "Trade services",
				url: "/trading-rooms/voice-channel/trade-services3",
				img: voiceChannelImage2,
				mesageOption: true,
				voiceOption: true,
			},
		],
	},
];
const attachmentImages = [
	{img: img1},
	{img: img2},
	{img: img3},
	{img: img4},
	{img: img5},
];
export default TradeRoomsSidebar;
