import React from "react";
import { NavLink } from "react-router-dom";
import {
	DisputeIcon,
	DisputeIcon2,
	PaymentIcon,
	PaymentIcon2,
	PayoutIcon,
	PayoutIcon2,
} from "../../../components/Icon";

const PayoutLayout = ({ children }) => {
	return (
		<>
			<br />
			<div className="profile-card">
				<div className="profile-card-header">
					<ul className="nav-menu mx-auto py-6px">
						<li>
							<NavLink to={`/finances/payouts`}>
								<span className="inactive">
									<PayoutIcon />
								</span>
								<span className="active">
									<PayoutIcon2 />
								</span>
								<span>Payout</span>
							</NavLink>
						</li>
						<li>
							<NavLink to={`/finances/payments`}>
								<span className="inactive">
									<PaymentIcon />
								</span>
								<span className="active">
									<PaymentIcon2 />
								</span>
								<span>Payment</span>
							</NavLink>
						</li>
						<li>
							<NavLink to={`/finance/dispute`}>
								<span className="inactive">
									<DisputeIcon />
								</span>
								<span className="active">
									<DisputeIcon2 />
								</span>
								<span>Disputes</span>
							</NavLink>
						</li>
					</ul>
				</div>
				<div className="profile-card-body py-4">
					<div className="py-3">{children}</div>
				</div>
			</div>
		</>
	);
};

export default PayoutLayout;
