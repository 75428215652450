import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import img from "../../assets/img/avatar.png";
import { AngleRightIcon } from "../../components/Icon";
import Layout from "../../components/Layout";
import CompletedModal from "./CompletedModal";
import { APIBaseURL } from "../../util/DomainHelper";

const CoursesPreview = ({ onTitleChange }) => {

	const params = useParams();

	const [activeVideoOption, setActiveVideoOption] = useState('Overview');
	const [show, setShow] = React.useState(false);

	const [activeModuleIndex, setActiveModuleIndex] = useState(0);
	const [active, setActive] = React.useState(0);

	const [lessonVideoData, setLessonVideoData] = useState();
	const [modules, setModules] = useState();

	const toggleVideoOption = (e) => {
		const selection = e.target.id;
		setActiveVideoOption(selection);
	};

	const fetchLessonVideo = () => {
		const url = APIBaseURL() + `courses/lesson/${params.id}`;
		try {
			fetch(url, {
				method: 'GET',
				headers: {
				'origin': '*',
				'x-requested-with': '*',
				'Content-Type': 'application/json'
				}
			}).then(async (response) => {
				if (response.ok) {
					const responseJSON = await response.json();
					const data = responseJSON.data;
					onTitleChange(data.title);
					fetchLessonModule(data);
				}
			})
		} catch(error) {
			console.log(error);
		}
	};

	const fetchLessonModule = (fetchedLessonVideo) => {
		const moduleId = fetchedLessonVideo.module;
		if (moduleId) {
			const url = APIBaseURL() + `courses/module/${moduleId}`;
			try {
				fetch(url, {
					method: 'GET',
					headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
					}
				}).then(async (response) => {
					if (response.ok) {
						const responseJSON = await response.json();
						const data = responseJSON.data;
						fetchCourseDetails(fetchedLessonVideo, data, moduleId);
					}
				})
			} catch(error) {
				console.log(error);
			}
		}
	};

	const fetchCourseDetails = (fetchedLessonVideo, fetchedCourseModule, moduleId) => {
		const courseId = fetchedCourseModule.course;
		if (courseId) {
			const url = APIBaseURL() + `courses/modules/${courseId}`;
			try {
				fetch(url, {
					method: 'GET',
					headers: {
					'origin': '*',
					'x-requested-with': '*',
					'Content-Type': 'application/json'
					}
				}).then(async (response) => {
					if (response.ok) {
						const responseJSON = await response.json();
						const data = responseJSON.data;
						const targetMainIndex = data.findIndex((module) => module.module._id === moduleId);
						const targetInnerIndex = data[targetMainIndex].videos.findIndex((video) => video._id === params.id);

						setData(targetMainIndex, targetInnerIndex, fetchedLessonVideo, data);
					}
				})
			} catch(error) {
				console.log(error);
			}
		}
	};

	const setData = (fetchedModuleIndex, fetchedVideoIndex, fetchedLessonVideo, fetchedModuleData) => {
		setActiveModuleIndex(fetchedModuleIndex);
		setActive(fetchedVideoIndex);
		setLessonVideoData(fetchedLessonVideo);
		setModules(fetchedModuleData);
	};

	useEffect(() => {
		fetchLessonVideo();
	},[]);

	return (
		<>
				<br />
				<div className="row g-4">
					<div className="col-md-7 col-xl-8">
						<div className="preview-wrapper">
							<div className="preview-video">
								<iframe
									width="100%"
									src={lessonVideoData ? lessonVideoData.mediaURL : ''}
									title="Lesson video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									height={"527px"}
								></iframe>
							</div>
							<ul className="nav-menu">
								<li>
									<Link onClick={(e) => toggleVideoOption(e)} id="Overview" className={activeVideoOption === 'Overview' ? "active" : ''}>
										Overview
									</Link>
								</li>
								<li>
									<Link onClick={(e) => toggleVideoOption(e)} id="Q&A" className={activeVideoOption === 'Q&A' ? "active" : ''}>Q&A</Link>
								</li>
								<li>
									<Link onClick={(e) => toggleVideoOption(e)} id="Notes" className={activeVideoOption === 'Notes' ? "active" : ''}>Notes</Link>
								</li>
								<li>
									<Link onClick={(e) => toggleVideoOption(e)} id="Reviews"className={activeVideoOption === 'Reviews' ? "active" : ''}>Reviews</Link>
								</li>
							</ul>
						</div>
						<br />
						<div className="d-flex flex-wrap justify-content-between align-items-center mb-20 gap-3">
							<div className="preview-title">
								{lessonVideoData ? lessonVideoData.title : ''}
							</div>
							<button
								type="button"
								className="btn btn--secondary border-0  font-bold"
								style={{
									height: "52px",
									width: "180px",
								}}
								onClick={() => setShow(true)}
							>
								Next Lesson <AngleRightIcon color={"white"} />
							</button>
						</div>
						<div
							className="fz-15 font-semibold text-E0E0E050"
							style={{ maxWidth: "768px" }}
						>
							{lessonVideoData ? lessonVideoData.description : ''}
						</div>
					</div>
					<div className="col-md-5 col-xl-4">
						<div className="course-preview-accordion course-preview-sidebar-accordion">
							<Accordion
								defaultActiveKey={activeModuleIndex}
								style={{
									background: "#0e0e11",
									"--bs-accordion-bg": "#0e0e11",
								}}
							>
								{modules && modules.map((item, index) => (
									<Accordion.Item eventKey={index} key={index}>
										<Accordion.Header>{item.module.title}</Accordion.Header>
										<Accordion.Body>
											<ul className="sub-data">
												{item.videos.map((subItem, subIndex) => (
													<li key={subIndex}>
														<Link
															to={`/courses/watch/${subItem?._id}`}
															className={
																subIndex === active
																	? "active"
																	: ""
															}
														>
															<span className="me-auto">
																{subItem.title}
															</span>
															{subItem?.previewURL ? (
																<span className="text-base">
																	Preview
																</span>
															) : (
																""
															)}
															<span className="text-white">
																{subItem.duration}
															</span>
														</Link>
													</li>
												))}
											</ul>
										</Accordion.Body>
									</Accordion.Item>
								))}
							</Accordion>
						</div>
						<div className="instructor-info pt-4">
							<div className="subtitle mb-4">About the instructor</div>
							<div className="d-flex align-items-center gap-3 mb-20">
								<img
									src={img}
									width={70}
									style={{
										borderRadius: "50%",
										aspectRatio: "1",
										objectFit: "cover",
									}}
									alt=""
								/>
								<div className="name-area">
									<h6 className="name">Graham Stephan</h6>
									<span className="designation">Trading Expert</span>
								</div>
							</div>
							<div>
								As a 30 year old real estate agent and investor who
								started working in real estate shortly after turning 18,
								with over $120,000,000 in residential real ...
							</div>
						</div>
					</div>
				</div>
				<CompletedModal show={show} setShow={setShow} />
		</>
	);
};

const coursePreviewData = [
	{
		title: "Module 1 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 2 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 3 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 4 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
	{
		title: "Module 5 - Getting Started with Real Estate",
		subData: [
			{
				title: "Lesson 1 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 2 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 3 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 4 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
			{
				title: "Lesson 5 - Who Graham is",
				url: "/courses/preview/lesson-1",
			},
		],
	},
];
export default CoursesPreview;
