import React from "react";
import { country } from "../admin-auth/AdminSignUpStep3";
import SettingsLayout from "./components/SettingsLayout";

const LanguageSetting = () => {
	return (
		<>
				<div className="d-flex gap-3 align-items-center justify-content-between">
					<h5 className="setting-title m-0">Language</h5>
				</div>
				<br />
				<div className="d-flex flex-column gap-3">
					{country?.map((item, index) => (
						<div key={index} className="language-radio-item">
							<label className="form-check form--radio">
								<input
									type="radio"
									className="form-check-input"
									name="language"
								/>
								<span className="form-check-label">{item.name}</span>
								<img src={item.img} alt="" className="ms-auto" />
							</label>
						</div>
					))}
				</div>
		</>
	);
};

export default LanguageSetting;
