import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import ArticleArea from "./components/ArticleArea";

const Dashboard = ({ onTitleChange }) => {

	useEffect(() => {
		const newTitle = "Dashboard";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	return (
		<>
			<br />
			<ArticleArea />
		</>
	);
};

export default Dashboard;
