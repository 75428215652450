import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import avatar from "../assets/img/avatar.png";
import diamond from "../assets/img/icons/diamond.png";

import { Collapse } from "react-bootstrap";
import favicon from "../assets/img/favicon.png";
import logo from "../assets/img/logo.png";
import Header from "./Header";
import {
	AngleLeft,
	ArrowLeft,
	ArrowRight,
	BookMarkIcon,
	CalendarIcon,
	CategoryIcon,
	ChartIcon,
	ClearIcon2,
	CommunityIcon,
	CourseIcon,
	DashboardIcon,
	FollowingIcon,
	LibraryIcon,
	MessageIcon,
	NotifyIcon,
	SettingIcon,
	SparkelsIcon,
	PlusIcon,
	TrendingRoomsIcon,
} from "./Icon";

import cImg1 from "../assets/img/community-icon/1.png";
import cImg2 from "../assets/img/community-icon/2.png";
import cImg3 from "../assets/img/community-icon/3.png";
import cImg4 from "../assets/img/community-icon/4.png";
import cImg5 from "../assets/img/community-icon/5.png";
import useScreen from "../hooks/useScreen";
import { APIBaseURL, DemoUserID } from "../util/DomainHelper";
import {CurrentCompany, CurrentCompanyConfiguration, PerformLogout} from "../util/CompanyHelper";
import SignalPost from "./SignalPost";
import {toast} from "react-toastify";
import userProvider from "../providers/UserProvider";

const Layout = ({ livestream, chatbarClose, children }) => {

	const [open, setOpen] = useState(false);
	const [collapseId, setCollapseId] = useState(null);
	const [userData, setUserData] = useState();
	const [company, setCompany] = useState();
	const [companyConfiguration, setCompanyConfiguration] = useState();
	const [sidebarBottomInfoOpen, setSidebarBottomInfoOpen] = useState(false);

	const [newSignalModalOpen, setNewSignalModalOpen] = useState(false);

	const screen = useScreen();

	const navigate = useNavigate();

	const handleLogout = () => {
		console.log('Logout...');
		PerformLogout();
	};

	function setSidebarOpen(e) {
		setOpen(e)
	}

	const fetchUserProfile = async () => {
		const user = userProvider.getCurrentUser()
		if (user) {
			setUserData(user)
		}
		// const user = JSON.parse(localStorage.getItem('user'));
		// if (user) {
		// 	const url = APIBaseURL() + `profile/profile/${user._id}`;
		// 	try {
		// 		const response = await fetch(url, {
		// 			method: 'GET',
		// 			headers: {
		// 				'origin': '*',
		// 				'x-requested-with': '*',
		// 				'Content-Type': 'application/json'
		// 			}
		// 		});
		//
		// 		const responseJSON = await response.json();
		// 		const data = responseJSON.profile;
		// 		setUserData(data);
		// 	} catch(error) {
		// 		console.log(error);
		//
		// 	}
		// }
	};

	const fetchCompanyConfiguration = async () => {
		const fetchedCompanyConfig = await CurrentCompanyConfiguration();
		const fetchedCompany = await CurrentCompany(false)
		if (fetchedCompanyConfig) {
			setCompanyConfiguration(fetchedCompanyConfig);
		}
		if (fetchedCompany) {
			setCompany(fetchedCompany)
		}
	};

	useEffect(() => {
		fetchCompanyConfiguration();
		fetchUserProfile();
	},[]);

	function handleSubMenuItem(e, item) {
		e.preventDefault();
		if (item.url === 'logout') {
			return handleLogout()
		} else {
			navigate(item.url)
		}
	}

	function handleSentTradeIdea() {
		toast.success('Successfully sent trade idea');
	}

	function handleCreateNewTradeIdea() {
		setNewSignalModalOpen(true)
	}

	function handleError(e) {
		toast.dark(e)
		// toast.error(e)
	}

	return (
		<>
			<main className="main-section">
				{livestream ? (
					""
				) : (
					<button
						className={`no-gutter text-white close-menu-icon ${
							open ? "active" : ""
						}`}
						onClick={() => setOpen(!open)}
					>
						{open ? <ArrowLeft /> : <ArrowRight />}
					</button>
				)}
				<aside
					className={`sidebar ${open ? "active" : ""} ${
						chatbarClose ? "chatbar-close" : ""
					}`}
				>
					<Link to="/" className="logo d-lg-none">
						<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logo) : logo} alt="logo" />
					</Link>
					<Link to="/" className="logo d-none d-lg-block">
						{open ? (
							<img src={companyConfiguration ? (companyConfiguration.favicon !== '' ? companyConfiguration.favicon : favicon) : favicon} width={50} alt="logo" />
						) : (
							<img src={companyConfiguration ? (companyConfiguration.darkLogo !== '' ? companyConfiguration.darkLogo : logo) : logo} alt="logo" />
						)}
					</Link>
					{/* <button
						className="no-gutter text-white p-2 close-menu"
						onClick={() => setOpen(!open)}
					>
						<ClearIcon />
					</button> */}
					{livestream ? (
						<>
							<div className="sidebar-communities border-0 pb-0 ms-0 mt-0">
								<button
									className="p-0 m-0 border-0 outline-0 w-100 stream-back-btn text-start ps-4"
									onClick={() => navigate(-1)}
								>
									<h6 className="subtitle m-0 ps-2">
										<AngleLeft /> <span className="ms-2">Home</span>
									</h6>
								</button>
							</div>
							{/* Live audience */}
							<div className="audiences-menu">
								{audiences.map((item, index) => (
									<div key={index} className="audience-item">
										<h6 className="subtitle">{item.title}</h6>
										<div className="audience-list">
											{item.subData.map((subItem, subIndex) => (
												<div key={subIndex} className="audience">
													<Link to="#" className="media mb-4">
														<div className="avatar">
															<img
																src={subItem.img}
																alt="user"
															/>
														</div>
														<div className="media-body">
															<h6 className="mt-0">
																{subItem.name}
															</h6>
															<small>
																{subItem.designation}
															</small>
														</div>
														<div className="status">
															{subItem.status === "active" ? (
																<div className="active">
																	{subItem.tradingAmount}
																</div>
															) : (
																<span className="inactive"></span>
															)}
														</div>
													</Link>
												</div>
											))}
										</div>
									</div>
								))}
							</div>
						</>
					) : (
						<>
							<div className="sidebar-communities">
								<h6 className="subtitle">Communities</h6>
								<div className="d-flex flex-wrap gap-3">
									{communities
										.map((item, index) => (
											<Link
												key={index}
												to={item.url}
												className="w-0 flex-grow-1"
												onClick={() =>
													setOpen(screen >= 992 ? false : true)
												}
											>
												<img
													src={item.img}
													width="41"
													alt="community"
												/>
											</Link>
										))
										.slice(0, screen >= 992 ? (open ? 1 : 4) : 4)}
									{screen >= 992 ? (
										!open ? (
											<button
												type="button"
												className="no-gutter w-0 flex-grow-1"
											>
												<img
													src={cImg5}
													width="41"
													alt="community"
												/>
											</button>
										) : (
											""
										)
									) : (
										<button
											type="button"
											className="no-gutter w-0 flex-grow-1"
											onClick={() => setOpen(false)}
										>
											<img src={cImg5} width="41" alt="community" />
										</button>
									)}
								</div>
							</div>
							<div className="sidebar-menu">
								<ul>
									{sidebarMenu.map((item, index) => (
										<li
											key={index}
											onClick={() =>
												item.url === "/settings" &&
												navigate(`/settings/my-account`)
											}
										>
											{item?.url ? (
												<NavLink
													to={item?.url}
													onClick={() => {
														setOpen(false);
														setCollapseId(
															collapseId === item.name
																? null
																: item.name
														);
													}}
												>
													<span className="icon">{item.icon}</span>
													<span className="name">{item.name}</span>
													{item?.sparkles && (
														<span className="sparkles">
															{item.sparkles}
														</span>
													)}
												</NavLink>
											) : (
												<>
													<button
														type="button"
														onClick={() => {
															setOpen(false);
															setCollapseId(
																collapseId === item.name
																	? null
																	: item.name
															);
															navigate(item.submenu[0].url);
														}}
														className={
															collapseId === item.name
																? "active"
																: ""
														}
													>
														<span className="icon">
															{item.icon}
														</span>
														<span className="name">
															{item.name}
														</span>
														{item?.sparkles && (
															<span className="sparkles">
																{item.sparkles}
															</span>
														)}
													</button>
													<Collapse in={collapseId === item.name}>
														<div>
															<ul className="submenu">
																{item?.submenu?.map(
																	(subItem, subIndex) => (
																		<li key={subIndex}>
																			<NavLink
																				to={subItem.url}
																				onClick={() =>
																					setOpen(false)
																				}
																			>
																				<span className="name">
																					{subItem.name}
																				</span>
																			</NavLink>
																		</li>
																	)
																)}
															</ul>
														</div>
													</Collapse>
												</>
											)}
										</li>
									))}
								</ul>
							</div>
							<div className="px-2">
								<hr
									className="mx-4 mb-4"
									style={{ borderTopWidth: "2px" }}
								/>
							</div>
						</>
					)}
					<div
						className={`sidebar-bottom mt-auto ${
							sidebarBottomInfoOpen ? "active" : ""
						}`}
					>
						{screen >= 992 && open ? (
							<div className="sidebar-bottom-top border-0 p-3">
								<div className="media justify-content-center">
									<div
										className="avatar cursor-pointer"
										// onClick={() => handleLogout()}
									>
										<img src={avatar} className="img" alt="user" />
									</div>
								</div>
							</div>
						) : (
							<>
								<div className="sidebar-bottom-top">
									<div className="media">
										<div className="avatar">
											<img style={{ objectFit: 'cover'}} src={userData ? (userData.photo != '' ? userData.photo : '/avatar/blankUser.jpg') : '/avatar/blankUser.jpg'} className="img" alt="user" />
											{/* <img
												src={diamond}
												className="diamond"
												alt=""
											/> */}
										</div>
										<div className="media-body">
											<small>@copper . 11.9k</small>
											<h5 className="mt-0">{userData ? (`${userData.firstName} ${userData.lastName}`) : ''}</h5>
											<small className="text-base font-bold">
												{ company ? company.name : 'Tradessly'}
											</small>
										</div>
									</div>
									<Collapse in={sidebarBottomInfoOpen}>
										<div>
											<div className="progress-info">
												<strong className="progress-label d-block mb-2">
													Rank 12
												</strong>
												<div className="progress __progress">
													<div
														className="progress-bar"
														style={{ width: "30%" }}
													></div>
												</div>
												<div className="progress-label d-flex justify-content-between mt-3">
													<span>1,056</span>
													<span>2000</span>
												</div>
											</div>
										</div>
									</Collapse>
									<button
										className="cmn-btn w-100 mt-4 py-0"
										type="button"
										style={{ height: "37px" }}
										onClick={() => handleCreateNewTradeIdea()}
									>
										New Trade Idea <PlusIcon />
									</button>
									<Collapse in={sidebarBottomInfoOpen}>
										<div>
											<ul className="sidebar-menu-2">
												{sidebarMenu2.map((item, index) => (
													<li key={index}>
														<Link
															onClick={(e) => handleSubMenuItem(e, item)} to={'/'}>
															<span className="icon">
																{item.icon}
															</span>
															<span className="name">
																{item.name}
															</span>
															{item?.sparkles && (
																<span className="sparkles">
																	{item.sparkles}
																</span>
															)}
														</Link>
													</li>
												))}
											</ul>
										</div>
									</Collapse>
								</div>
								<div className="sidebar-bottom-bottom">
									<button
										className="no-gutter"
										type="button"
										onClick={() =>
											setSidebarBottomInfoOpen(
												!sidebarBottomInfoOpen
											)
										}
									>
										{sidebarBottomInfoOpen ? (
											<ClearIcon2 />
										) : (
											<CategoryIcon />
										)}
									</button>
									<Link className="no-gutter" to="#">
										<CalendarIcon />
									</Link>
									<Link className="no-gutter" to="#">
										<MessageIcon />
									</Link>
									<Link className="no-gutter" to="#">
										<NotifyIcon />
									</Link>
								</div>
							</>
						)}
					</div>
				</aside>
				<article className="articles">
					<Header sidebarOpen={open} setSidebarOpen={setOpen} />
					<div className="container-fluid">
						<br />
						<Outlet context={setSidebarOpen} />
					</div>
				</article>
			</main>
			<SignalPost show={newSignalModalOpen} onHide={() => setNewSignalModalOpen(false)} onSuccess={() => handleSentTradeIdea()} onError={handleError}/>
		</>
	);
};

const sidebarMenu = [
	{
		icon: <DashboardIcon />,
		name: "Dashboard",
		url: "/dashboard",
	},
	{
		icon: <TrendingRoomsIcon />,
		name: "Trading Rooms",
		url: "/trading-rooms",
	},

	{
		icon: <ChartIcon />,
		name: "Trade Ideas",
		url: "/trade-ideas",
	},

	{
		icon: <CommunityIcon />,
		name: "Community",
		url: "/community",
	},

	{
		icon: <CourseIcon />,
		name: "Courses",
		url: "/courses",
		sparkles: <SparkelsIcon />,
	},

	{
		icon: <TrendingRoomsIcon />,
		name: "Finances",
		submenu: [
			{
				name: "Payments",
				url: "/finances/payments",
			},
			{
				name: "Payouts",
				url: "/finances/payouts",
			},
			{
				name: "Disputes",
				url: "/finances/disputes",
			},
		],
	},

	// {
	// 	icon: <SettingIcon />,
	// 	name: "Settings",
	// 	url: "/settings",
	// },
];
const sidebarMenu2 = [
	{
		icon: <LibraryIcon />,
		name: "Library",
		url: "/library",
	},
	{
		icon: <BookMarkIcon />,
		name: "Bookmark",
		url: "/bookmark",
	},

	{
		icon: <FollowingIcon />,
		name: "Following",
		url: "/following",
	},
	{
		icon: <SettingIcon />,
		name: "Settings",
		url: "/settings",
	},
	{
		icon: <SettingIcon />,
		name: "Logout",
		url: "logout",
	},
];
const communities = [
	{
		img: cImg1,
		url: "#",
	},
	{
		img: cImg2,
		url: "#",
	},
	{
		img: cImg3,
		url: "#",
	},
	{
		img: cImg4,
		url: "#",
	},
];

const audiences = [
	{
		title: "Following",
		subData: [
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading 2023 / New Si...",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading 2023 / New Si...",
				tradingAmount: "90,343",
				status: "inactive",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading 2023 / New Si...",
				tradingAmount: "90,343",
				status: "inactive",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading 2023 / New Si...",
				tradingAmount: "90,343",
				status: "inactive",
			},
		],
	},
	{
		title: "Recommended",
		subData: [
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
			{
				img: avatar,
				name: "Jane Cooper",
				designation: "Trading",
				tradingAmount: "90,343",
				status: "active",
			},
		],
	},
];

export default Layout;
