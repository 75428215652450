import React from "react";

import { Link } from "react-router-dom";
import {
	CalendarIcon,
	CopyIcon,
	ExpertMembers,
	MessageIcon,
	RankIcon1,
	RankIcon2,
} from "./TradingIcons";
import idea from "./img/idea.png";
import img1 from "./img/members/1.png";
import img10 from "./img/members/10.png";
import img11 from "./img/members/11.png";
import img12 from "./img/members/12.png";
import img2 from "./img/members/2.png";
import img3 from "./img/members/3.png";
import img4 from "./img/members/4.png";
import img5 from "./img/members/5.png";
import img6 from "./img/members/6.png";
import img7 from "./img/members/7.png";
import img8 from "./img/members/8.png";
import img9 from "./img/members/9.png";

import { ArrowRight } from "../../../components/Icon";
import sidebartopauthor from "./img/trade-idea-author.png";
import sidebartopbg from "./img/trade-idea-bg.png";

const TradeRoomsRightSidebar = ({ rightSidebarOpen, setRightSidebarOpen }) => {
	const handleSubmit = (e) => {
		e.preventDefault();
	};
	return (
		<>
			<div
				className={`trade-rooms-right-sidebar ${
					rightSidebarOpen ? "active" : ""
				}`}
			>
				<div className="trade-rooms-right-siebar-inner">
					<button
						className="d-xl-none  mb-4 no-gutter"
						onClick={() => setRightSidebarOpen(false)}
					>
						<ArrowRight />
					</button>

					<div
						className="trade-rooms-right-sidebar-top"
						style={{
							background: `url(${sidebartopbg}) no-repeat center center / cover`,
						}}
					>
						<button className="no-gutter" type="button">
							<img src={sidebartopauthor} alt="" />
						</button>
					</div>
					<div className="text-center">
						<h6 className="idea-subtitle mb-2">
							Trade idea <img src={idea} alt="" />
						</h6>
						<p className="mb-2 text-14">
							A consultant's role is to help you navigate the complex
							world of import and export trade compliance regulations.
						</p>
						<Link className="text-base text-underline" to="#">
							Read More
						</Link>
					</div>

					<form onSubmit={handleSubmit} className="search-form mt-4">
						<div className="group-input">
							<button
								type="submit"
								className="btn btn--danger btn--secondary border-0"
							>
								<CopyIcon />
								<span>Copy link</span>
							</button>
							<input
								type="text"
								placeholder="Global Trade Team"
								className="form-control"
							/>
						</div>
					</form>
					<br />
					<hr />
					<br />
					<button
						type="submit"
						className="btn btn--danger btn--secondary border-0 font-400 gap-2 align-items-center h-48 w-100 justify-content-start d-flex mb-3"
					>
						<CalendarIcon />
						<span>Custom 11: Sections pro</span>
					</button>
					<button
						type="submit"
						className="btn btn--danger btn--secondary bg-transparent border-base font-400 gap-2 align-items-center h-48 w-100 justify-content-start d-flex mb-4"
					>
						<ExpertMembers />
						<span>Expert Members</span>
					</button>
					<hr />
					<div className="d-flex gap-2 justify-content-between align-items-center mt-3 mb-4">
						<select className="form-select form--select p-0">
							<option>Member(10)</option>
							<option>Member(10)</option>
							<option>Member(10)</option>
						</select>
						<button className="px-3 btn cmn-btn" type="button">
							Add member
						</button>
					</div>
					<div className="members-wrapper">
						{roomMembers.map((item, index) => (
							<div className="members-item" key={index}>
								<h6 className="members-title">
									<span>{item.title}</span>
								</h6>
								<div className="members-list">
									{item.members.map((member, index) => (
										<div className="member" key={index}>
											<Link
												to="#"
												className="d-flex align-items-center gap-2 flex-grow-1"
											>
												<img src={member.img} alt="" />
												<div className="d-flex align-items-center gap-1 flex-grow-1">
													<div className="member-name">
														{member.name}
													</div>
													{member.rank === "1" && (
														<div className="member-rank">
															<RankIcon1 />
														</div>
													)}
													{member.rank === "2" && (
														<div className="member-rank">
															<RankIcon2 />
														</div>
													)}
												</div>
											</Link>
											<button className="ms-auto no-gutter">
												<MessageIcon />
											</button>
										</div>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

const roomMembers = [
	{
		title: "Group Admins (2)",
		members: [
			{
				name: "Robert Fox",
				img: img1,
				id: "1",
			},
			{
				name: "Robert Fox",
				img: img2,
				id: "2",
			},
		],
	},
	{
		title: "Master Traders (3)",
		members: [
			{
				name: "Theresa Webb",
				img: img3,
				id: "1",
				rank: "1",
			},
			{
				name: "Devon Lane",
				img: img4,
				id: "2",
				rank: "2",
			},
			{
				name: "Courtney Henry",
				img: img5,
				id: "3",
			},
		],
	},
	{
		title: "Members (3)",
		members: [
			{
				name: "Esther Howard",
				img: img6,
				id: "1",
			},
			{
				name: "Arlene McCoy",
				img: img7,
				id: "2",
			},
			{
				name: "Jenny Wilson",
				img: img8,
				id: "3",
			},
			{
				name: "Robert Fox",
				img: img9,
				id: "3",
			},
			{
				name: "Floyd Miles",
				img: img10,
				id: "3",
			},
			{
				name: "Jenny Wilson",
				img: img11,
				id: "3",
			},
			{
				name: "Darrell Steward",
				img: img12,
				id: "3",
			},
		],
	},
];

export default TradeRoomsRightSidebar;
