import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import img1 from "../../../assets/img/recent/1.png";
import img2 from "../../../assets/img/recent/2.png";
import { AngleRightIcon } from "../../../components/Icon";

const RecentTrade = () => {
	
	return (
		<section className="recent-trade">
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				// breakpoints
				breakpoints={{
					576: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 1.1,
					},
					840: {
						slidesPerView: 1.2,
					},
					992: {
						slidesPerView: 1,
					},
					1200: {
						slidesPerView: 1.06,
						spaceBetween: 20,
					},
					1500: {
						slidesPerView: 1.2,
						spaceBetween: 30,
					},
				}}
			>
				{data?.map((item, index) => (
					<SwiperSlide key={index}>
						<div className="recent-trade-card">
							<div
								className="trade-img"
								style={{
									background: `url(${item.img1}) no-repeat center center / cover`,
								}}
							>
								<span className="badge bg-base">Free</span>
								<h6 className="trade-title">
									<Link to="#">{item.title}</Link>
								</h6>
								<div className="trade-cate">{item.cate}</div>
							</div>
							<div className="trade-content">
								<img src={item.img2} alt="" />
								<div>
									<h6 className="trade-title">
										<Link to="#">{item.title}</Link>
									</h6>
									<div className="trade-cate">{item.cate}</div>
									<div className="trade-text">{item.text}</div>
									<div className="d-flex justify-content-between">
										<div className="trade-price">{item.price}</div>
										<Link to="#" className="text-base view">
											Buy <AngleRightIcon />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	);
};

const data = [
	{
		img1: img1,
		img2: img2,
		title: "Trade Beyond ",
		cate: "Marvel Shop",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna...",
		price: "$199.9",
	},
	{
		img1: img1,
		img2: img2,
		title: "Trade Beyond ",
		cate: "Marvel Shop",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna...",
		price: "$199.9",
	},
	{
		img1: img1,
		img2: img2,
		title: "Trade Beyond ",
		cate: "Marvel Shop",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna...",
		price: "$199.9",
	},
	{
		img1: img1,
		img2: img2,
		title: "Trade Beyond ",
		cate: "Marvel Shop",
		text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna...",
		price: "$199.9",
	},
];

export default RecentTrade;
