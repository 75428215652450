/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ReactDatePicker from "react-datepicker";
import { CalendarIcon3 } from "../../components/Icon";
import Layout from "../../components/Layout";
import PayoutLayout from "./components/PayoutLayout";

const PayoutPayment = () => {
	const [startDate, setStartDate] = React.useState(new Date());
	return (
		<PayoutLayout>
			<div
				className="row g-4 payout-wrapper"
				style={{ "--body": "#1C1C24" }}
			>
				<div className="col-lg-12">
					<div className="card card-2 rounded-22 h-100">
						<div className="card-body py-3">
							<div className="w-100 d-flex align-items-center justify-content-between">
								<div>
									<div>
										<h6 className="title font-semibold text-md-24 mb-10px">
											Gross earning
										</h6>
										<div className="text-88869F mb-3">
											Total Gross amount :
										</div>
									</div>
									<div className="text-white text-md-20 font-heading font-semibold">
										$100.09
									</div>
								</div>
								<div>
									<img src="/gross.svg" className="mw-100" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-12">
					<div className="card card-2 rounded-22 h-100">
						<div className="card-body">
							<div className="d-flex flex-wrap gap-3 w-100 justify-content-between align-items-center">
								<div className="setting-title font-heading">
									Customer purchases List
								</div>
								<div className="d-flex flex-wrap gap-2 gap-sm-3">
									<select className="form-select form-control w-auto bg-transparent border rounded-12 h-48 text-88869F">
										<option>Last 7 days</option>
										<option>Last 7 days</option>
										<option>Last 7 days</option>
										<option>Last 7 days</option>
									</select>
									<div className="date-item">
											<span className="date-icon">
												<CalendarIcon3 />
											</span>
										<ReactDatePicker
											selected={startDate}
											onChange={(date) => setStartDate(date)}
											minDate={new Date()}
										/>
									</div>
									<button
										className="btn btn--secondary h-48 border-0 text-88869F px-4"
										type="button"
									>
										Download
									</button>
								</div>
							</div>

							<div className="table-responsive w-100 mt-5">
								<table className="trx-table table table-striped">
									<thead className="custom-thead">
									<tr>
										<th className="px-3">Date</th>
										<th className="px-3">Items</th>
										<th className="PX-3">Customer</th>
										<th className="px-3">Purchase date</th>
										<th className="px-3 text-center">Status</th>
										<th className="px-3 text-center">Amount</th>
									</tr>
									</thead>
									<tbody>
									{data?.map((item, index) => (
										<tr key={index}>
											<td className="py-2 px-3">
														<span className="text-white text-16 word-nobreak">
															Sep 25, 2023
														</span>
											</td>
											<td className="py-2 px-3">
														<span className="text-white text-16 word-nobreak">
															Trading Session
														</span>
											</td>
											<td className="py-2 px-3">
												<div className="d-flex align-items-center gap-2">
													<img
														src="/customer.png"
														width={22}
														alt=""
													/>
													<span className="text-white text-16 word-nobreak">
																Jenny Wilson
															</span>
												</div>
											</td>
											<td className="py-2 px-3">
												<div className="text-white text-16 word-nobreak">
													Sep 25, 2023 4:40 PM
												</div>
											</td>
											<td className="py-2 px-3 text-center">
												<button
													className={`btn text-14 font-regular --badge btn--secondary btn--success border-0`}
													style={{ minWidth: "100px" }}
												>
													Completed
												</button>
											</td>
											<td className="text-center">
														<span className="text-white text-16">
															$23.00
														</span>
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
							<div className="d-flex flex-wrap justify-content-between gap-3 w-100 mt-3">
								<div className="d-flex align-items-center gap-2">
									<span className="text-12 text-88869F">Show </span>
									<input
										type="text"
										className="form-control form-control-2 h-28 w-66 border px-0 text-center text-12 bg-transparent rounded"
										placeholder="10"
									/>
								</div>
								<div className="d-flex align-items-center gap-2">
										<span className="text-12 text-88869F">
											Showing 1 to 10 of 100 entries{" "}
										</span>
									<div className="d-flex flex-wrap gap-2">
										<nav aria-label="Page navigation example">
											<ul className="pagination __pagination">
												<li className="page-item">
													<a className="page-link" href="#">
														Previous
													</a>
												</li>
												<li className="page-item">
													<a
														className="page-link active"
														href="#"
													>
														1
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														2
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														3
													</a>
												</li>
												<li className="page-item">
													<a className="page-link" href="#">
														Next
													</a>
												</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</PayoutLayout>
	);
};

const radiodata = [
	{
		label: "Quarterly (Mar 29, 2021)",
		value: "quarterly",
	},
	{
		label: "Monthly (last Wednesday of each month)",
		value: "quarterly",
	},
	{
		label: "Twice per month (1st and 3rd Wednesday of each month)",
		value: "quarterly",
	},
	{
		label: "Weekly (every Wednesday)",
		value: "quarterly",
	},
];

const data = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

export default PayoutPayment;
