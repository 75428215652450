import React from "react";

import { UpperArrowChartIcon2 } from "./Icon";

const StandardSignalCard3 = () => {
	return (
		<>
			<div className="signal-widget extra-signal-widget extra-signal-widget-3">
				<div className="signal-widget-wrapper">
					<h5 className="text-center d-flex align-items-center justify-content-center gap-2">
						<span>+20.45%</span> <img src="/flags/check.svg" alt="" />{" "}
					</h5>
					<div className="usd-long mt-3">
						<div>
							<h6 className="name">USD</h6>
							United States Dollar
						</div>
						<div className="long-badge">
							<span>LONG</span>{" "}
							<span>
								<UpperArrowChartIcon2 />
							</span>
						</div>
					</div>
					<div className="d-flex flex-wrap align-items-center justify-content-between mt-3 gap-1 time-stamp border-bottom-2 pb-2 timestamp">
						<strong className="name font-semibold">Time Stamp:</strong>
						<span
							className="font-semibold"
							style={{ color: "#212128", opacity: "0.5" }}
						>
							12 November 2022 12:57
						</span>
					</div>
					<div className="pt-4 d-flex justify-content-between info-texts">
						<div className="me-auto">
							<div className="mb-2">
								<h6 className="s-title">47523</h6>
								<small>Alert ID</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">35821</h6>
								<small>Signal ID</small>{" "}
								<button
									className="d-inline-block btn btn-secondary"
									type="button"
								>
									Copy
								</button>
							</div>
						</div>
						<div className="mx-auto">
							<div className="mb-2">
								<h6 className="s-title">1.19</h6>
								<small>TP</small>
							</div>
							<div className="mb-2">
								<h6 className="s-title">1.181</h6>
								<small>SL</small>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default StandardSignalCard3;
