import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
	CurrentBalanceIcon,
	NextSlide,
	PrevSlide,
	UpperArrowChartIcon,
} from "../../components/Icon";
import SignalCard from "../../components/SignalCard";
import TradingViewWidget from "./components/TradeChart";
import NetworkingProvider from "../../providers/NetworkingProvider";
import {CurrentCompany} from "../../util/CompanyHelper";
import ConfigureSignal from "../../components/ConfigureSignal";
import SignalPost from "../../components/SignalPost";
import {toast} from "react-toastify";

const ChartPage = ({ onTitleChange }) => {

	const ref = React.useRef(null);
	const [open, setOpen] = useState(0);
	const [configureSignalModalOpen, setConfigureSignalModalOpen] = useState(false);
	const [newSignalModalOpen, setNewSignalModalOpen] = useState(false);

	const [tradeIdeas, setTradeIdeas] = useState([]);

	const goNext = () => {
		ref.current.swiper.slideNext();
	};
	const goPrev = () => {
		ref.current.swiper.slidePrev();
	};

	async function initializePage() {
		try {
			const currentCompany = await CurrentCompany(false);
			if (!currentCompany) {
				return;
			}
			const dataResponse = await NetworkingProvider.fetchData(`trade-ideas/items/${currentCompany._id}`, 'POST');
			const data = dataResponse.data;
			const message = dataResponse.message;
			if (data) {
				return setTradeIdeas(data)
			}
			if (message) {
				return console.log(message)
			}
		} catch (e) {
			console.log(e);
		}
	}

	function handleSentTradeIdea() {
		toast.success('Successfully sent trade idea');
	}

	function handleError(e) {
		toast.dark(e)
		// toast.error(e)
	}

	useEffect(() => {
		const newTitle = "Charts";
		onTitleChange(newTitle);
	}, [onTitleChange]);

	useEffect(() => {
		initializePage()
	},[])

	return (
		<>
			<div className="bg-section" style={{ borderRadius: "36px" }}>
					<div className="px-2 px-xxl-5 pb-3 pt-4">
						<div className="mb-4 d-flex justify-content-between align-items-center">
							<h5 className="mb-0">Trade Ideas</h5>
							<div className="d-flex gap-3">
								<button className="no-gutter" onClick={goPrev}>
									<PrevSlide />
								</button>
								<button className="no-gutter" onClick={goNext}>
									<NextSlide />
								</button>
							</div>
						</div>
						<div className="counter-area style-2">
							<div className="w-100">
								<Swiper
									slidesPerView={"auto"}
									spaceBetween={"12"}
									ref={ref}
								>
									<SwiperSlide style={{ width: "162px" }}>
										<div className="counter-item">
											<div className="counter-value">20</div>
											<div className="counter-label">OPEN POSITIONS</div>
										</div>
									</SwiperSlide>
									<SwiperSlide style={{ width: "162px" }}>
										<div className="counter-item">
											<div className="counter-value">12</div>
											<div className="counter-label">WINS</div>
										</div>
									</SwiperSlide>
									<SwiperSlide style={{ width: "162px" }}>
										<div className="counter-item">
											<div className="counter-value">08</div>
											<div className="counter-label">LOSSES</div>
										</div>
									</SwiperSlide>
									<SwiperSlide style={{ width: "332px" }}>
										<div className="counter-item">
											<div className="d-flex align-items-end gap-2 justify-content-between w-100 ps-2 text-start">
												<div>
													{/*<CurrentBalanceIcon />{" "}*/}
													<small>Trade Idea Configuration</small>
													<div className="counter-value my-0">
														<small>FX Layout 1</small>
													</div>
												</div>
												<button onClick={() => setConfigureSignalModalOpen(!configureSignalModalOpen)} className="btn btn--secondary rounded h-42 px-3 ">
													Edit Layout
												</button>
											</div>
										</div>
									</SwiperSlide>
									{/*<SwiperSlide style={{ width: "332px" }}>*/}
									{/*	<div className="counter-item">*/}
									{/*		<div className="d-flex align-items-end gap-2 justify-content-between w-100 ps-2 text-start">*/}
									{/*			<div>*/}
									{/*				<CurrentBalanceIcon />{" "}*/}
									{/*				<small>Current balance</small>*/}
									{/*				<div className="counter-value mb-0 mt-2 d-flex align-items-center gap-2">*/}
									{/*					<small>$3,574.11</small>*/}
									{/*					<span className="upto">*/}
									{/*						Up 11%{" "}*/}
									{/*						<UpperArrowChartIcon color="white" />{" "}*/}
									{/*					</span>*/}
									{/*				</div>*/}
									{/*			</div>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*</SwiperSlide>*/}
								</Swiper>
							</div>
						</div>
						<div className="mt-4">
							<TradingViewWidget />
						</div>
						<div className="trading--signal-wrapper">
							<div className="nav--menu gap-4">
								<ul className="nav-menu">
									{menu?.map(({name}, i) => (
										<li>
											<Link
												to="#"
												className={open === i ? "active" : ""}
												onClick={() => setOpen(i)}
											>
												<span>{name}</span>
											</Link>
										</li>
									))}
								</ul>
								<button
									className="cmn-btn ms-auto px-4"
									onClick={() => setNewSignalModalOpen(true)}
								>
									New Trade Idea
								</button>
								<Link href="#" className="text-body">
									View All
								</Link>
							</div>
							<div className="mt-4">
								<Swiper
									spaceBetween={24}
									breakpoints={{
										320: {
											slidesPerView: 1,
										},
										400: {
											slidesPerView: 1.3,
										},
										576: {
											slidesPerView: 2.2,
										},
										992: {
											slidesPerView: 2.1,
										},
										1200: {
											slidesPerView: 2.6,
										},
										1440: {
											slidesPerView: 3.6,
										},
										1520: {
											slidesPerView: 4,
										},
										2000: {
											slidesPerView: 5.6,
										},
									}}
								>
									{tradeIdeas && tradeIdeas?.map((item, i) => (
										<SwiperSlide key={i}>
											<SignalCard props={item} />
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
					</div>
				</div>
			<ConfigureSignal show={configureSignalModalOpen} onHide={() => setConfigureSignalModalOpen(false)} />
			<SignalPost show={newSignalModalOpen} onHide={() => setNewSignalModalOpen(false)} onSuccess={() => handleSentTradeIdea()} onError={handleError}/>
		</>
	);
};

const menu = [
	{
		name: "Active Trade Ideas",
	},
	{
		name: "Closed Positions",
	},
	{
		name: "Archive",
	},
];

export default ChartPage;
