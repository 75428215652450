import React from "react";
import { useNavigate } from "react-router-dom";
import { SuccessIcon2 } from "../../components/Icon";
import AdminAuthLayout from "./components/AdminAuthLayout";

const AdminSignUpSuccess = () => {

	const navigate = useNavigate();
	
	return (
		<>
			<AdminAuthLayout fullwidth altLoginText>
				<br />
				<br className="d-none d-md-block" />
				<br className="d-none d-md-block" />
				<div className="text-center mx-auto" style={{ maxWidth: "654px" }}>
					<div className="mb-4 mb-md-5 img-white-container">
						<SuccessIcon2 />
					</div>
					<h2 className="title font-semibold mb-3 pb-1">
						Verification Required
					</h2>
					{/* <h4 className="title font-semibold mb-3 pb-1">
						
					</h4> */}
					<div className="text">Hello Dahmeyon, We sent a verification link to <span className="title font-semibold">dahmeyonmcd@gmail.com</span>.</div>
					<br />
					<div className="mt-4 mt-sm-5">
						<button
							type="submit"
							className="btn w-100 cmn-btn h-69"
							onClick={() => navigate("/admin-signin")}
						>
							Sign In
						</button>
					</div>
				</div>
			</AdminAuthLayout>
		</>
	);
};

export default AdminSignUpSuccess;
