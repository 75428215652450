import React from "react";

const InputCheckbox = ({ value, title }) => {
	const [checked, setChecked] = React.useState(value);
	return (
		<>
			<label className="form-check form--check form-check-2">
				<input
					className="form-check-input"
					type="checkbox"
					checked={checked}
					onChange={(e) => setChecked(e.target.checked)}
				/>
				<span className="form-check-label">{title}</span>
			</label>
		</>
	);
};

export default InputCheckbox;
