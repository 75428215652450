import React, {useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import Input from "../../../components/Input";
import ReactQuill from "react-quill";
import CoursePlanLayout from "../../../components/CoursePlanLayout";
import {toast} from "react-toastify";
import NetworkingProvider from "../../../providers/NetworkingProvider";
import {CurrentCompany} from "../../../util/CompanyHelper";
import UserProvider from "../../../providers/UserProvider";

const CreateCommunityModal = (props) => {

	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [visibility, setVisibility] = useState('');
	const [thumbnail, setThumbnail] = useState();

	const [initialized, setInitialized] = useState(true);

	async function handlePublishCommunity() {
		if (!title || !description || !visibility) {
			return toast.error("Please fill out all required fields.");
		}
		try {
			const currentCompany = await CurrentCompany(false)
			const payload = {
				title: title,
				description: description,
				visibility: visibility,
				thumbnail: thumbnail ?? '',
				company: currentCompany._id,
				user: UserProvider.getCurrentUser()._id
			}
			const dataResponse = await NetworkingProvider.fetchData('community/create', 'POST', payload);
			const data = dataResponse.data;
			const message = dataResponse.message;
			if (data) {
				toast.success('Successfully published to communities.');
				return props.onSuccess()
			}
			if (message) {
				return toast.error(message ?? 'Error occured');
			}
		} catch (e) {
			console.error(e);
		}
	}

	function handleThumbnailChange(e) {
		const target = e.target;
		const files = target.files;
		if (files && files.length) {
			console.log('files:', files);
		}
	}

	useEffect(() => {

	},[])

	return (
		<>
			<Modal
				{...props}
				size="xl"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="signalModal"

			>
				{ initialized && (
					<>
						<div className="container container-xxl-fluid" >
							<main className="course-plan-layout">
								<div>
									<h5 className="title">Create New Community</h5>
									<p className="txt pb-md-3">
										Let's create your new community. Fill out the form below with any relavant
										information.
									</p>
									<hr
										className="pb-md-3"
										style={{
											borderWidth: "2px",
											opacity: "1",
											borderColor: "#88869F",
										}}
									/>
									<h5 className="subtitle mb-2">Title</h5>
									<div className="example-inputs text-text">
										<Input
											value={title ? title : ''}
											onChange={(e) => setTitle(e.target.value)}
											placeholder="Title" height={"47px"} endText={55}/>
										<small className="input-small-txt">
											Your title should be a mix of attention-grabbing, informative,
											and optimized for search
										</small>
										<br/>
									</div>
									{/*<h5 className="subtitle mb-2">Course Subtitle</h5>*/}
									{/*<div className="example-inputs text-text">*/}
									{/*	<Input*/}
									{/*		// value={currentCourseDetails ? currentCourseDetails.course_subtitle : ''}*/}
									{/*		// onChange={(e) => updateCourseDetails({course_subtitle: e.target.value})}*/}
									{/*		placeholder="Course Subtitle"*/}
									{/*		height={"47px"}*/}
									{/*		endText={120}*/}
									{/*	/>*/}
									{/*	<small className="input-small-txt">*/}
									{/*		Use 1 or 2 related keywords, and mention 3-4 of the most*/}
									{/*		important areas that you've covered during your course.*/}
									{/*	</small>*/}
									{/*	<br/>*/}
									{/*</div>*/}
									<h5 className="subtitle">Description</h5>
									<Input textarea={true}
										defaultValue={description ? description : ''}
										onChange={(e) => setDescription(e.target.value)}
										   height={"125px"}/>
									<small className="input-small-txt mt-1">
										Description should have minimum 200 words.
									</small>
									<br/>
									{/*<h5 className="subtitle">Congratulations Message</h5>*/}
									{/*<ReactQuill theme="snow" value={value2} onChange={setValue2} />*/}
									{/*<br />*/}
									{/*<h5 className="subtitle">Additional Info</h5>*/}
									{/*<div className="price-wrapper d-flex flex-wrap gap-4 justify-content-between">*/}
									{/*	<div className="item">*/}
									{/*		<div className="example-inputs">*/}
									{/*			*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*	<div className="item">*/}
									{/*		<div className="example-inputs">*/}
									{/*			<Input*/}
									{/*				// defaultValue={currentCourseDetails ? currentCourseDetails.course_level : ''}*/}
									{/*				// onChange={(e) => updateCourseDetails({course_level: e})}*/}
									{/*				options={[*/}
									{/*					{*/}
									{/*						name: "Select Skill Level",*/}
									{/*						value: "",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Beginner",*/}
									{/*						value: "beginner",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Standard",*/}
									{/*						value: "standard",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Intermediate",*/}
									{/*						value: "intermediate",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Expert",*/}
									{/*						value: "expert",*/}
									{/*					},*/}
									{/*				]}*/}
									{/*				height={"47px"}*/}
									{/*			/>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*	<div className="item">*/}
									{/*		<div className="example-inputs">*/}
									{/*			<Input*/}
									{/*				// defaultValue={currentCourseDetails ? currentCourseDetails.course_focus_area : ''}*/}
									{/*				// onChange={(e) => updateCourseDetails({course_focus_area: e})}*/}
									{/*				options={[*/}
									{/*					{*/}
									{/*						name: "Select Area (Optional)",*/}
									{/*						value: "",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Crypto Trading",*/}
									{/*						value: "crypto",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Forex Trading",*/}
									{/*						value: "forex",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Futures",*/}
									{/*						value: "futures",*/}
									{/*					},*/}
									{/*					{*/}
									{/*						name: "Stock Market Trading",*/}
									{/*						value: "stock-market",*/}
									{/*					},*/}
									{/*				]}*/}
									{/*				height={"47px"}*/}
									{/*			/>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*</div>*/}
									{/*<br/>*/}
									<h5 className="subtitle">
										Select Community Visibility
									</h5>
									<div className="example-inputs text-text">
										<div className="row">
											<div className="col-md-12">
												{/*<Input*/}
												{/*	// value={currentCourseDetails ? currentCourseDetails.course_focus : ''}*/}
												{/*	placeholder="e.g. Crypto Trading" height={"47px"}*/}
												{/*	// onChange={(e) => updateCourseDetails({course_focus: e.target.value})}*/}
												{/*/>*/}
												<Input
													defaultValue={visibility ? visibility : ''}
													onChange={(e) => setVisibility(e)}
													options={[
														{
															name: "Select one",
															value: "",
														},
														{
															name: "Public",
															value: "global",
														},
														{
															name: "Invite Only",
															value: "invite",
														},
														{
															name: "Subscribers Only",
															value: "subscribers",
														}
													]}
													height={"47px"}
												/>
											</div>
										</div>
									</div>
									<br/>

									<h5 className="subtitle">Community Thumbnail</h5>
									<div className="row example-inputs align-items-center">
										{/*<div className="col-md-6">*/}
										{/*	<Input textarea height={"249px"} />*/}
										{/*</div>*/}
										<div className="col-md-5">
											<label className="mb-3 file-label-txt">
												Upload your community image here. It must meet our course
												image quality standards to be accepted. Important
												guidelines: 750x422 pixels; .jpg, .jpeg,. gif, or .png. no
												text on the image.
											</label>
											<div className="file-input">
												<div className="file-input-input">
													<input type="file" id="basic" onChange={(e) => handleThumbnailChange(e)} />
												</div>
												<label className="file-input-label" htmlFor="basic">
													Upload File
												</label>
											</div>
										</div>
									</div>
									<br/>
									{/*<h5 className="subtitle">Banner Photo</h5>*/}
									{/*<div className="row example-inputs align-items-center">*/}
									{/*	/!*<div className="col-md-6">*!/*/}
									{/*	/!*	<Input textarea height={"249px"} />*!/*/}
									{/*	/!*</div>*!/*/}
									{/*	<div className="col-md-6">*/}
									{/*		<label className="mb-3 file-label-txt">*/}
									{/*			Upload your course image here. It must meet our course*/}
									{/*			image quality standards to be accepted. Important*/}
									{/*			guidelines: 750x422 pixels; .jpg, .jpeg,. gif, or .png. no*/}
									{/*			text on the image.*/}
									{/*		</label>*/}
									{/*		<div className="file-input">*/}
									{/*			<div className="file-input-input">*/}
									{/*				<input type="file" id="basic"/>*/}
									{/*			</div>*/}
									{/*			<label className="file-input-label" htmlFor="basic">*/}
									{/*				Upload File*/}
									{/*			</label>*/}
									{/*		</div>*/}
									{/*	</div>*/}
									{/*</div>*/}
								</div>
								<div className="d-flex w-100 mt-md-3 gap-4" style={{ height: '60px'}}>
									<button
										className="cmn-btn btn-outline px-4 mt-3"
										onClick={() => props.onHide()}
									>
										Cancel
									</button>
									<button
										className="cmn-btn px-4 mt-3"
										onClick={() => handlePublishCommunity()}
									>
										Publish
									</button>
								</div>
							</main>
						</div>
					</>
				)}

			</Modal>
		</>
	);
};

export default CreateCommunityModal;
