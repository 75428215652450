import React, {useEffect, useState} from "react";
import ReactQuill from "react-quill";
import CoursePlanLayout from "../../components/CoursePlanLayout";
import {useNavigate} from "react-router-dom";
import {useCreateCourse} from "../../contexts/CreateCourseContext";
import NetworkingProvider from "../../providers/NetworkingProvider";
import UserProvider from "../../providers/UserProvider";
import {toast} from "react-toastify";
import {CurrentCompany} from "../../util/CompanyHelper";

const CourseMessage = () => {

	const { updateCourseDetails,
		curriculums,
		insertCurriculum,
		removeCurriculum,
		fetchCourse, courseDetails, updateLessonData } = useCreateCourse();

	const navigate = useNavigate();

	const [currentCourseDetails, setCurrentCourseDetails] = useState();
	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");

	async function handleNextTriggered() {
		await publishCourse()
	}

	async function publishCourse() {
		const currentUser = UserProvider.getCurrentUser()
		const currentCompany = await CurrentCompany(false)

		if (!currentUser || !currentCompany) {
			return console.log('Missing something')
		}

		try {
			let payload = courseDetails()
			payload.uploader = currentUser._id ?? ''
			const dataResponse = await NetworkingProvider.fetchData(`courses/create/${currentCompany._id}`, 'POST', payload)
			// console.log(dataResponse)
			const result = dataResponse.data;
			const message = dataResponse.message;
			if (result) {
				toast.success('Course published successfully.');
				return navigate(`/course-plan/success/${result}`)
			}
			if (message) {
				return toast.error(message ?? 'Failed to publish course');
			}
		} catch (e) {
			console.log(e)
		}
	}

	useEffect(() => {
		const fetchedCourseDetails = courseDetails()
		setCurrentCourseDetails(fetchedCourseDetails)
	},[])

	return (
		<CoursePlanLayout>
			{ currentCourseDetails && (
				<>
					<div style={{maxWidth: "841px"}}>
						<h5 className="title">Course messages</h5>
						<hr
							className="pb-md-3"
							style={{
								borderWidth: "2px",
								opacity: "1",
								borderColor: "#88869F",
							}}
						/>
						<p className="txt pb-md-3">
							Write messages to your students (optional) that will be sent
							automatically when they join or complete your course to encourage
							students to engage with course content. If you do not wish to
							send a welcome or congratulations message, leave the text box
							blank.
						</p>
						<h5 className="subtitle">Welcome Message</h5>
						<ReactQuill
							placeholder="Course Description"
							theme="snow"
							defaultValue={currentCourseDetails ? currentCourseDetails.course_welcome_message : ''}
							onChange={(e) => updateCourseDetails({course_welcome_message: e})}
						/>
						<br/>
						<h5 className="subtitle">Congratulations Message</h5>
						<ReactQuill
							placeholder="Course Description"
							theme="snow"
							defaultValue={currentCourseDetails ? currentCourseDetails.course_completion_message : ''}
							onChange={(e) => updateCourseDetails({course_completion_message: e})}
						/>
					</div>
					<div className="d-flex w-100 mt-md-3 gap-4">
						<button
							className="cmn-btn btn-outline px-4 mt-3"
							onClick={() => navigate('/course-plan/pricing')}
						>
							Back
						</button>
						<button
							className="cmn-btn px-4 mt-3"
							onClick={() => handleNextTriggered()}
						>
							Save & Publish
						</button>
					</div>
				</>
			)}
		</CoursePlanLayout>
	);
};

export default CourseMessage;
